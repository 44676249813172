import React, { useRef, useState } from "react"
import { useEffect } from "react"
import { server } from "../../server"

export function CodeEdit({
    text,
    declarations,
    language,
    height,
    innerHeight,
    error,
    onEdited,
}: {
    text: string
    language: "typescript" | "json" | "xml"
    declarations: string
    height?: number | string
    innerHeight?: number | string
    error?: { start: { line: number; character: number }; end: { line: number; character: number } }
    onEdited: (text: string) => void
}) {
    const frame = useRef<HTMLIFrameElement | null>(null)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        function handler(e: any) {
            if (e.source !== frame.current?.contentWindow) {
                return
            }
            if ("onEdited" in e.data) {
                onEdited(e.data.onEdited)
            }
            if ("readyForConfig" in e.data) {
                frame.current?.contentWindow?.postMessage(
                    { config: { language, height: innerHeight ?? height } },
                    "*"
                )
            }
            if ("ready" in e.data) {
                setReady(e.data.ready)
            }
        }
        window.addEventListener("message", handler)
        return () => window.removeEventListener("message", handler)
    }, [onEdited])

    useEffect(() => {
        if (ready) {
            frame.current?.contentWindow?.postMessage({ text }, "*")
        }
    }, [text, frame.current?.contentWindow, ready])

    useEffect(() => {
        frame.current?.contentWindow?.postMessage({ declarations }, "*")
    }, [declarations, frame.current?.contentWindow, ready])

    useEffect(() => {
        frame.current?.contentWindow?.postMessage({ error }, "*")
    }, [error, frame.current?.contentWindow, ready])

    return (
        <iframe
            ref={frame}
            src={`${server()}/code-edit`}
            style={{
                width: "100%",
                height: height || 300,
                margin: 0,
                padding: 0,
                border: "1px solid #ddd",
                borderRadius: 4,
            }}
        />
    )
}
