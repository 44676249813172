import { ColorStyles } from "../ui"
import { Checkmark } from "./Checkmark"
import { useWidgetState } from "./WidgetContext"
import { RegisterWidget } from "./WidgetView"

RegisterWidget<Checkmark>("Checkmark", ({ value }) => <CheckmarkWidget {...value} />)
export function CheckmarkWidget({
    value,
    style,
    label,
    widgetKey,
}: {
    widgetKey: string
    value: boolean
    label?: string
    style?: React.CSSProperties
}) {
    const [checked, setChecked] = useWidgetState(widgetKey, "checked", value)

    async function handleToggle() {
        await setChecked(!checked)
    }

    return (
        <div style={{ display: "flex", alignItems: "center", ...style }} key={widgetKey}>
            {label && (
                <label
                    style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 600,
                        marginRight: 16,
                        color: checked ? ColorStyles.primary[700] : ColorStyles.gray[400],
                    }}
                >
                    {label}
                </label>
            )}
            <div style={{ position: "relative" }} onClick={handleToggle}>
                <div
                    style={{
                        appearance: "none", // Remove default checkbox styling
                        width: 24,
                        height: 24,
                        borderRadius: 4,
                        border: `2px solid ${checked ? ColorStyles.primary[700] : ColorStyles.gray[400]}`,
                        backgroundColor: checked ? ColorStyles.primary[700] : "transparent",
                        transition: "all 0.3s",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
                {checked && (
                    <div
                        style={{
                            position: "absolute",
                            top: "6px",
                            left: "6px",
                            width: "12px",
                            height: "8px",
                            borderBottom: "2px solid white",
                            borderLeft: "2px solid white",
                            transform: "rotate(-45deg)",
                        }}
                    />
                )}
            </div>
        </div>
    )
}
