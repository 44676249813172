import React from "react"
import { Image, ImageToUrl } from "../../../../reactor/Types/File"
import { PlaceholderImageUrl } from "../../../../packages/editing/EditableImage"
import { Section } from "../../../../packages/editing/Section"

Section(TwoImagesSection)
/**
 * Displays two images horizontally. On small screens, they wrap to vertically.
 *  @icon columns
 */
function TwoImagesSection(section: { leftImage: Image; rightImage: Image }) {
    return (
        <div className="row">
            <div className="col-lg">
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: 600,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={
                            section.leftImage
                                ? ImageToUrl(section.leftImage)
                                : PlaceholderImageUrl()
                        }
                        style={{
                            objectPosition: "center",
                            objectFit: "contain",
                            maxWidth: "100%",
                            maxHeight: "100%",
                        }}
                    />
                </div>
            </div>
            <div className="col-lg">
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: 600,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                    }}
                >
                    <img
                        src={
                            section.leftImage
                                ? ImageToUrl(section.rightImage)
                                : PlaceholderImageUrl()
                        }
                        style={{
                            objectPosition: "center",
                            objectFit: "contain",
                            maxWidth: "100%",
                            maxHeight: "100%",
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
