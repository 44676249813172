import React from "react"
import { WidgetView } from "../widgets/WidgetView"
import { WidgetContextProvider } from "./WidgetContextProvider"
import { postWidget, postWidgetAction } from "../../studio/client"
import { Busy } from "../../studio/Views/Busy"
import { ColorStyles } from "../ui"

/** Displays a widget available through the `/widgets` API. */
export function WidgetAPIView(props: { name: string; param: string }) {
    return (
        <WidgetContextProvider
            busy={() => <Busy backgroundColor={ColorStyles.gray[25]} />}
            path={["widget", props.name, props.param]}
            fetcher={async (req) => await postWidget(props.name, props.param, req)}
            callAction={async ({ widgetKey, method, args, state }) =>
                await postWidgetAction(props.name, props.param, widgetKey, {
                    widgetKey,
                    method,
                    args,
                    state,
                })
            }
        >
            {(content) => <WidgetView value={content} />}
        </WidgetContextProvider>
    )
}
