import React from "react"
import { KeyFigure } from "./KeyFigure"
import { RegisterWidget, WidgetView } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<KeyFigure>("KeyFigure", ({ value }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", padding: 8, flex: 1 }}>
            <div style={{ fontSize: 18, fontWeight: 500, color: ColorStyles.gray[800] }}>
                {value.label}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 16,
                }}
            >
                <div style={{ fontSize: 36, fontWeight: 600, color: "#1F2937", flex: 1 }}>
                    {value.value}
                </div>
                {value.timeSeries && (
                    <div style={{ marginTop: 16, marginLeft: 16 }}>
                        <LineChart
                            data={value.timeSeries}
                            color={ColorStyles[value.color ?? "green"][500]}
                        />
                    </div>
                )}
                {value.chip && <WidgetView value={value.chip} />}
            </div>
        </div>
    )
})

function LineChart({ data, color }: { data: number[]; color: string }) {
    // Create SVG path data using a Bezier curve
    // "M x1 y1 C x2 y2, x3 y3, x4 y4" command draws a cubic Bezier curve
    const getPathData = (points: { x: number; y: number }[]) => {
        return points
            .map((point, i, a) => {
                if (i === 0) return `M ${point.x} ${point.y}` // Move to first point without drawing

                // Control points are calculated simply for demonstration
                const cps = `${(a[i - 1].x + point.x) / 2},${a[i - 1].y}`
                const cpe = `${(a[i - 1].x + point.x) / 2},${point.y}`
                return `C ${cps}, ${cpe}, ${point.x},${point.y}`
            })
            .join(" ")
    }

    // Convert data points into SVG coordinates
    const svgWidth = 128
    const svgHeight = 64
    const padding = 0 // padding around the chart

    // Find the max value to scale points
    const maxValue = Math.max(...data)

    // Calculate SVG points from data
    const points = data.map((value, index) => ({
        x: padding + ((svgWidth - padding * 2) / (data.length - 1)) * index,
        y: svgHeight - padding - (value / maxValue) * (svgHeight - padding * 2),
    }))

    const pathData = getPathData(points)

    // Define the gradient
    const gradientId = "gradient-" + color

    return (
        <svg width={svgWidth} height={svgHeight}>
            <defs>
                <linearGradient id={gradientId} x1="0%" y1="100%" x2="0%" y2="0%">
                    <stop offset="0%" stopColor={color} stopOpacity="0" />
                    <stop offset="100%" stopColor={color} stopOpacity="0.25" />
                </linearGradient>
            </defs>
            {/* The filled area under the line */}
            <path
                d={`${pathData} L ${svgWidth - padding},${svgHeight - padding} L ${padding},${
                    svgHeight - padding
                } Z`}
                fill={`url(#${gradientId})`}
            />
            {/* The line itself */}
            <path d={pathData} fill="none" stroke={color} strokeWidth="2" />
        </svg>
    )
}
