import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"

export type HeightLockContext = {
    /** Called to indicate that that the content needs the full height of the
     * screen instead of being scrollable. Use the `useFullHeight` hook
     * instead of this. */
    addHeightLock(): void
    removeHeightLock(): void
}

export const HeightLockContext = createContext<HeightLockContext | undefined>(undefined)

/** Sets the editable context in fixed height mode, allowing you to define an interior scrollable region.
 *
 *  If not used, the default in EditableContext is to use the entire page as the scrollable region.
 */
export function useFullHeight() {
    const ctx = useContext(HeightLockContext)
    if (!ctx) throw new Error("useFullHeight must be used within a HeightLockContext")

    const { addHeightLock: addFullHeightLock, removeHeightLock: removeFullHeightLock } = ctx

    useEffect(() => {
        addFullHeightLock()
        return () => removeFullHeightLock()
    }, [addFullHeightLock, removeFullHeightLock])
}

export function useHeightLockProvider() {
    const [heightLockVersion, setHeightLockVersion] = useState({})
    const heightLocks = useRef(0)
    const height = heightLocks.current ? "100%" : undefined

    // This weirdness is to force a re-render when the heightLocks change
    // without invalidating the callbacks.

    const addHeightLock = useCallback(() => {
        setHeightLockVersion({})
        heightLocks.current++
    }, [])

    const removeHeightLock = useCallback(() => {
        setHeightLockVersion({})
        heightLocks.current--
    }, [])

    return {
        height,
        /** A function that renders the context provider with the provided content. */
        heightLockProvider: (content: JSX.Element | undefined) => (
            <HeightLockContext.Provider value={{ addHeightLock, removeHeightLock }}>
                {content}
            </HeightLockContext.Provider>
        ),
    }
}
