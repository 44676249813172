import { ProblemJson } from "../../../packages/ssr/ProblemJson"
import { SSRStatus } from "../../../packages/ssr/SSRStatus"
import { server } from "../../../server"
import { colors } from "./styles"

export function ErrorView({ error }: { error: ProblemJson }) {
    if (error.status) SSRStatus.code = error.status

    return (
        <div
            style={{
                padding: 64,
                backgroundColor: colors.darkGreen,
                color: "white",
                height: "100vh",
            }}
        >
            <h2>
                {error.status} {error.type}
            </h2>
            <div style={{ marginTop: 16 }}>{error.detail}</div>
            <img
                style={{ width: 160, marginTop: 128 }}
                src={`${server()}/static/climatepoint.png`}
            />
        </div>
    )
}
