import { useState } from "react"
import { EditableArray } from "../../../../packages/editing/EditableArray"
import { useEditableContext, useIsEditing } from "../../../../packages/editing/EditableContext"
import { colors } from "../styles"
import { EditableSections } from "../../../../packages/editing/EditableSections"
import { Slide } from "../Slide"
import { GetType } from "../../../../reactor/ReflectionInfo"
import { SetEditableActions } from "../../../../packages/editing/EditableActions"
import { Section } from "../../../../packages/editing/Section"
import { useSelectionContext } from "../../../../packages/editing/SelectionContext"

/**
 * @icon rectangle-landscape
 */
export type Tab = {
    /**
     * @title
     */
    title: string
    /**
     * @expand
     */
    sections: Section[]
}

Section(TabsSection)
/**
 * @zoom 2
 * @icon window
 */
function TabsSection(section: {
    /**
     * @default '[{ "title": "Tab 1", "sections": [{"type": "Placeholder", "id": "%UUID%" }]}, { "title": "Tab 2", "sections": [{"type": "Placeholder", "id": "%UUID%" }]}]'
     * @expand
     */
    tabs: Tab[]
}) {
    const { setSelectedObject } = useSelectionContext()
    const ec = useEditableContext()
    const editing = useIsEditing()
    const [selectedTab, setSelectedTab] = useState(0)
    const currentTab: Tab | undefined = section.tabs[selectedTab]
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    width: "100%",
                    minHeight: 32,
                }}
            >
                <EditableArray arr={section.tabs} itemTypeName="Tab" direction={"row"}>
                    {(tab: Tab, index: number) => (
                        <div
                            onClick={() => {
                                if (editing) {
                                    setSelectedObject(tab, GetType("Tab"))
                                    SetEditableActions(tab, {
                                        deleteThis() {
                                            section.tabs.splice(index, 1)
                                            setSelectedTab(0)
                                            ec.invalidate()
                                        },
                                    })
                                }
                                setSelectedTab(index)
                            }}
                            style={{
                                cursor: "pointer",
                                padding: 8,
                                paddingLeft: selectedTab === index ? 22 : 32,
                                paddingRight: selectedTab === index ? 22 : 32,
                                margin: 32,
                                backgroundColor:
                                    selectedTab === index ? colors.primaryGreen + "32" : undefined,
                                borderRadius: selectedTab === index ? 8 : 0,
                                color:
                                    selectedTab === index ? colors.primaryGreen : colors.foreground,
                                fontWeight: selectedTab === index ? 500 : 400,
                                transition: "all 0.2s",
                            }}
                        >
                            {tab?.title}
                        </div>
                    )}
                </EditableArray>
            </div>
            <div
                key={selectedTab}
                style={{
                    border: editing ? "1px solid #aaa" : undefined,
                    margin: editing ? 8 : undefined,
                    padding: editing ? 16 : undefined,
                }}
            >
                {editing && (
                    <div style={{ fontSize: 12, color: "#aaa", marginBottom: 8 }}>
                        {currentTab?.title}
                    </div>
                )}

                {currentTab && (
                    <Slide direction="fromLeft">
                        <EditableSections sections={currentTab.sections} />
                    </Slide>
                )}
            </div>
        </div>
    )
}
