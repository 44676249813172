import { GetTypePropsWithExtensions } from "../../packages/type-extender/TypeExtender"
import { SubstituteType } from "../ReflectionInfo"
import {
    Discriminate,
    IsAnyType,
    IsArrayType,
    IsObjectType,
    IsUnionType,
    IsUnknownType,
    Property,
    Type,
} from "./Type"

export type TypedVisitor<TReturn> = (
    /** The value at the current `key` being visited. */
    value: unknown,
    /** The type of the `value` at the current `key` */
    type: Type,
    /**
     * The property on the parent object currently being visited, if any.
     *
     * This will be undefined for the root, array elements, and additional
     * properties.
     */
    property?: Property
) => TReturn

/** Reconstructs an object tree while allowing callbacks to replace selected bits of the tree based
 *  on value, type and optionally path information.
 *
 *  This is significantly faster than `Clone()` followed by `ReplaceValuesByType()`
 *
 *  The source object tree is not modified, so it tolerates working on a deep frozen input tree.
 *
 *  This function only copies the parts of the tree that needs to change, and reuses subtrees that
 *  don't need to change. It does NOT return a copy that is safe to modify. For that, use Clone().
 */
export function Reconstruct(
    rootObj: unknown,
    rootType: Type,
    /** A predicate for whether a value needs to be reconstructed. If this function returns true,
     *  the `reconstructor` will be called to produce the value that should replace this one.
     */
    needsReconstruction: TypedVisitor<boolean>,
    /** A function that replaces the provided value. Will only be called if the
     * `needsReconstruction` predicate returns `true`. */
    reconstructor: TypedVisitor<unknown>,
    visitUndefined = false
) {
    const visit: TypedVisitor<unknown> = (value, type, property) => {
        type = SubstituteType(value, type)

        if (needsReconstruction(value, type, property)) return reconstructor(value, type, property)

        if (IsAnyType(type) || IsUnknownType(type)) {
            return value
        }

        if (IsUnionType(type)) {
            const discType = Discriminate(value, type)
            if (type === discType) {
                // Unable to discriminate
                // We cannot traverse further with type safety
                return value
            } else {
                type = discType
                // Check if reconstruction is necessary again now that we know the discriminated
                // type
                if (needsReconstruction(value, type, property))
                    return reconstructor(value, type, property)
            }
        }

        if (value === null) return null

        if (typeof value === "object") {
            if (value instanceof Array) {
                const itemType = IsArrayType(type) ? type.array : "any"

                return value.map((v, i) => visit(v, itemType))
            } else {
                const res: any = {}
                const props = GetTypePropsWithExtensions(type, value)
                for (const prop of props) {
                    const v = (value as any)[prop.name]
                    if (visitUndefined || v !== undefined) {
                        res[prop.name] = visit(v, prop.type, prop)
                    } else {
                        // Preserve explicit undefined values
                        if (prop.name in value) {
                            res[prop.name] = undefined
                        }
                    }
                }
                if (IsObjectType(type) && type.additionalProps) {
                    for (const addProp of Object.keys(value).filter(
                        (p) => !props.some((r) => r.name === p)
                    )) {
                        const v = (value as any)[addProp]
                        if (v !== undefined) {
                            res[addProp] = visit(v, type.additionalProps)
                        }
                    }
                }
                if (IsObjectType(type) && type.alias === "Record" && type.typeArgs?.length === 2) {
                    const valueType = type.typeArgs[1]
                    if (typeof value === "object" && value)
                        for (const key of Object.keys(value)) {
                            if (key in value) res[key] = visit((value as any)[key], valueType)
                        }
                }
                return res
            }
        }
        // Atomic type, safe to return without cloning
        return value
    }

    return visit(rootObj, rootType)
}
