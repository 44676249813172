import { useState } from "react"

/** Allows you to use a class to manage the state of a component, instead of React hooks.
 *
 *  This is useful when the state of a component grows too complex to reason about in regular React
 *  hooks code, and e.g. you have async side effects (such as resource loading) that you want more
 *  precise control over than what `useState` can offer.
 */
export function useStateContainer<T>(
    /** A function that creates the state container. Will be called once for the lifetime of the
     * component. Returns an object that reflects a piece of state. */
    create: (
        /** A callback that can be used to re-render the component imperatively. */
        invalidate: () => void
    ) => T
) {
    const [version, setVersion] = useState({})
    const [state] = useState(() => create(() => setVersion({})))
    return state
}
