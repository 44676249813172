import React, { useEffect, useRef } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import type { GoogleMaps } from "./GoogleMaps"
import { RegisterWidget, WidgetView } from "./WidgetView"

RegisterWidget<GoogleMaps>("GoogleMaps", ({ value }) => <GoogleMapsWidget {...value} />)

let mapsScriptLoaded: Promise<void> | undefined

export function GoogleMapsWidget(props: GoogleMaps) {
    const ref = useRef(null)
    const map = useRef<google.maps.Map | null>(null)

    useEffect(() => {
        const divRef = ref.current
        if (divRef) void createMap(divRef, props)
    }, [ref.current])

    return <div ref={ref} id="map" style={{ height: 800, margin: 16 }} />
}

async function createMap(div: HTMLDivElement, props: GoogleMaps) {
    // Lazily load the google maps SDK
    if (!mapsScriptLoaded) {
        mapsScriptLoaded = new Promise((resolve) => {
            ;(window as any).initMap = resolve
        })

        const script = document.createElement("script")
        script.setAttribute(
            "src",
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyCE5VO2koHMlY7Qi-zTEFDEy7IyX7-47nI&callback=initMap"
        )

        document.body.appendChild(script)
    }

    await mapsScriptLoaded

    const map = new google.maps.Map(div, {
        center: { lat: 59.9139, lng: 10.7522 },
        zoom: 8,
    })

    if (props.markers)
        for (const marker of props.markers) {
            const gmarker = new google.maps.Marker({
                position: {
                    lat: marker.lat,
                    lng: marker.lng,
                },
                map,
            })
            if (marker.popup) {
                gmarker.addListener("click", () => {
                    new google.maps.InfoWindow({
                        content: renderToStaticMarkup(<WidgetView value={marker.popup} />),
                    }).open({ anchor: gmarker, map, shouldFocus: false })
                })
            }
        }
}
