import { CSSProperties, useCallback, useContext, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { lowercaseFirstLetter, prettyCamel } from "../../reactor/Helpers"
import { RButton } from "../../studio/Views/Buttons"
import { FileView } from "../../studio/Views/FileView"
import {
    deleteStudioTableFilter,
    postDocumentRefresh,
    postStudioTableFilter,
    putStudioTableFilter,
} from "../../studio/client"
import { useHover } from "../hooks/useHover"
import { useNavigate } from "../hooks/useNavigate"
import { ClientSideLocalize, Strings, Translations } from "../localization/client-side/Dictionary"
import { Modal, useContextualModal } from "../modal/Modal"
import { ButtonWidget } from "./ButtonWidget"
import { IsWidgetBase } from "./WidgetTypeHelpers"
import { DirectionContext } from "./LayoutWidget"
import type { Table, TableColProp, TableProps } from "./Table"
import { TitleWidget } from "./TitleWidget"
import type { WidgetMap } from "./Widget"
import { WidgetContext, usePerformWidgetAction, useWidgetState } from "./WidgetContext"
import { RegisterWidget, WidgetView } from "./WidgetView"
import { UploadFile } from "../files/UploadFile"
import { ColorName, ColorStyles } from "../ui"
import { useLocalize } from "../localization/client-side/useLocalize"
import { Uuid } from "../../reactor/Types/Primitives/Uuid"
import { FilterEditor } from "./FilterEditor"
import { Icon } from "../../studio/Views/Icon"
import { useDocumentCollection, useDocumentPrimaryKey } from "../../studio/Views/DocumentContext"

RegisterWidget<Table>("Table", ({ value, isRoot }) => <TableWidget {...value} isRoot={isRoot} />)

const headerBg = "rgb(249, 250, 251)"
const headerFg = "rgb(102, 112, 133)"
const hairline = ColorStyles.gray[200]

const buttonStyle = {
    border: `1px solid ${ColorStyles.gray[300]}`,
    fontSize: 14,
    boxShadow: "0px 1px 2px 0px #1018280D",
}

const smallButtonStyle = {
    ...buttonStyle,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 14,
    paddingRight: 14,
}

const getColumnKey = (c: string | TableColProp) => (typeof c === "string" ? c : c.name)

export function TableWidget(
    props: TableProps & {
        isRoot?: boolean
        /** Injects custom JSX elements left of the title button row */
        titleButtons?: JSX.Element

        resolve?: (result: any) => void
    }
) {
    const localize = useLocalize()

    // Drag-and-drop import support
    const performAction = usePerformWidgetAction()
    const { refresh } = useContext(WidgetContext)
    const supportsFileDrop = !!(
        props.methods?.includes("onFileDropped") || props.methods?.includes("onFileUploaded")
    )
    const { modal, showModal } = useContextualModal()

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        if (acceptedFiles.length !== 1) {
            alert("Please drop only one file at a time")
            return
        }
        const widgetKey = props.widgetKey
        if (!widgetKey) {
            alert("Cannot drop file on a widget without a widgetKey")
            return
        }
        const file = acceptedFiles[0]

        if (props.folder) {
            const fileId = await UploadFile(
                props.folder,
                file,
                file.type,
                false,
                "FolderDefault",
                file.name
            )
            try {
                await performAction(widgetKey, "onFileUploaded", [
                    fileId,
                    undefined /*TODO: support _droptarget */,
                ])
                await refresh()
            } catch (e: any) {
                alert(e.detail)
            }
        } else if (props.methods?.includes("onFileDropped")) {
            if (!props.fileDropMethods) {
                alert("Widget has onFileDropped method but no fileDropMethods")
                return
            }
            const reader = new FileReader()
            reader.onload = async function (e) {
                const content = e.target?.result as string
                const fileDropMethod = await Modal((close) => (
                    <div style={{ backgroundColor: "#fff", borderRadius: 8, padding: 32 }}>
                        Select method to use:
                        {props.fileDropMethods?.map((fdm) => (
                            <RButton
                                key={fdm.id}
                                onClick={() => close(fdm.id)}
                                style={{
                                    marginTop: 12,
                                    marginBottom: 12,
                                    minWidth: 400,
                                    maxWidth: 600,
                                    border: `1px solid ${ColorStyles.gray[200]}`,
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ fontWeight: "bold" }}>{fdm.name}</div>
                                    <div>{fdm.description}</div>
                                </div>
                            </RButton>
                        ))}
                        <RButton onClick={() => close(undefined)}>Cancel</RButton>
                    </div>
                ))
                try {
                    await performAction(widgetKey, "onFileDropped", [content, fileDropMethod])
                } catch (error: any) {
                    alert(error.detail)
                }
            }
            reader.readAsText(file)
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true })
    const dropProps: any = supportsFileDrop ? getRootProps() : undefined
    const dropInputProps = supportsFileDrop ? getInputProps() : undefined

    const { rows, extraRows } = props
    const widths = new Map<string, string | number>()

    props.rows.forEach((row) => {
        Object.keys(row).forEach((key) => {
            const widget = row[key]
            if (IsWidgetBase(widget)) {
                if (widget.width !== undefined) {
                    widths.set(key, widget.width)
                }
            }
        })
    })
    const wc = useContext(WidgetContext)

    const nonPanelColumns = props.columns.filter((p) => {
        const key = typeof p === "string" ? p : p.name
        return !rows.some(
            (r) => r[key] && typeof r[key] === "object" && (r as any)[key]?.type === "TablePanel"
        )
    })

    const [orderBy, setOrderBy] = useWidgetState(props.stateKey, "orderBy", "")
    const [orderMode, setOrderMode] = useWidgetState(props.stateKey, "orderMode", "ascending")
    const [filters, setFilters] = useWidgetState<
        { id: Uuid<"StudioTableFilter">; mode: "include" | "exclude" }[]
    >(props.stateKey, "filters", [])

    const removedFilters = filters.filter((f) => !props.filters?.some((x) => x.id === f.id))
    // Remove filters that are no longer available
    useEffect(() => {
        if (removedFilters.length > 0) {
            setFilters(filters.filter((f) => !removedFilters.some((rf) => rf.id === f.id)))
        }
    }, [JSON.stringify([props.filters, filters])])

    const orderByOptions = [...props.extraOrderByColumns, ...props.colProps]

    const orderByPicker = (
        <RButton
            style={{ ...buttonStyle, marginRight: 12 }}
            popup={(close) => (
                <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RButton
                            active={orderMode === "ascending"}
                            onClick={() => {
                                void setOrderMode("ascending")
                                close()
                            }}
                        >
                            {Strings.Ascending}
                        </RButton>
                        <RButton
                            active={orderMode === "descending"}
                            onClick={() => {
                                void setOrderMode("descending")
                                close()
                            }}
                        >
                            {Strings.Descending}
                        </RButton>
                    </div>
                    <div
                        style={{
                            height: 1,
                            backgroundColor: hairline,
                            margin: "0.5rem",
                        }}
                    ></div>
                    {orderByOptions.map((x) => (
                        <RButton
                            active={orderBy === x.name}
                            onClick={() => {
                                if (orderBy === x.name) {
                                    void setOrderBy("")
                                } else {
                                    void setOrderBy(x.name)
                                }
                                close()
                            }}
                        >
                            {ClientSideLocalize(x.displayName)}
                        </RButton>
                    ))}
                </div>
            )}
        >
            {Strings["Order by"] +
                (orderBy
                    ? " " +
                      lowercaseFirstLetter(
                          ClientSideLocalize(
                              orderByOptions.find((x) => x.name === orderBy)?.displayName ?? {
                                  en: prettyCamel(orderBy),
                              }
                          )
                      ) +
                      (orderMode === "ascending"
                          ? ` (${Strings.ascending})`
                          : ` (${Strings.descending})`)
                    : "")}
        </RButton>
    )

    const [pageIndex, setPageIndex] = useWidgetState(props.stateKey, "pageIndex", 0)
    useEffect(() => {
        if (pageIndex >= props.pageCount) void setPageIndex(0)
    }, [props.pageCount, pageIndex])

    const [itemsPerPage, setItemsPerPage] = useWidgetState(
        props.stateKey,
        "itemsPerPage",
        props.defaultItemsPerPage
    )
    const [search, setSearch] = useWidgetState(
        props.stateKey,
        "search",
        undefined as string | undefined
    )

    const itemsPerPagePicker = (
        <RButton
            style={buttonStyle}
            popup={(close) => (
                <input
                    type="number"
                    min={1}
                    max={props.maxItemsPerPage}
                    value={itemsPerPage}
                    onChange={(e) => {
                        void setItemsPerPage(parseInt(e.target.value))
                    }}
                />
            )}
        >
            {itemsPerPage + " " + Strings["per page"]}
        </RButton>
    )

    const collectionName = props.collection

    const filterPicker = collectionName && (
        <RButton
            style={{ ...buttonStyle, marginLeft: 12, fontSize: 14 }}
            active={filters.length > 0}
            popup={(close) => {
                return (
                    <div>
                        {props.filters?.map((f) => {
                            const existing = filters.find((x) => x.id === f.id)
                            const include = existing && existing.mode === "include"
                            const exclude = existing && existing.mode === "exclude"

                            const tickButtonStyle = (
                                active: boolean,
                                color: ColorName = "success"
                            ): CSSProperties => ({
                                width: 32,
                                height: 32,
                                paddingLeft: 5,
                                paddingRight: 5,
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderRadius: 6,
                                fontSize: 14,
                                color: ColorStyles[color][600],
                                background: ColorStyles[color][active ? 400 : 50],
                                border: "1px solid " + ColorStyles[color][300],
                            })

                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingTop: 2,
                                        paddingLeft: 6,
                                        paddingRight: 6,
                                        paddingBottom: 2,
                                        borderRadius: 6,
                                        border: "1px solid " + ColorStyles.gray[300],
                                    }}
                                >
                                    <div style={{ marginRight: 24, fontSize: 14 }}>
                                        {localize(f.description)}
                                    </div>
                                    <button
                                        style={tickButtonStyle(!!include)}
                                        onClick={() => {
                                            if (existing) {
                                                if (existing.mode === "include") {
                                                    setFilters(filters.filter((x) => x.id !== f.id))
                                                } else {
                                                    setFilters(
                                                        filters.map((x) =>
                                                            x.id === f.id
                                                                ? { id: f.id, mode: "include" }
                                                                : x
                                                        )
                                                    )
                                                }
                                            } else {
                                                setFilters([
                                                    ...filters,
                                                    { id: f.id, mode: "include" },
                                                ])
                                            }
                                        }}
                                    >
                                        <Icon icon="ui-plus" />
                                    </button>
                                    <button
                                        style={tickButtonStyle(!!exclude)}
                                        onClick={() => {
                                            if (existing) {
                                                if (existing.mode === "exclude") {
                                                    setFilters(filters.filter((x) => x.id !== f.id))
                                                } else {
                                                    setFilters(
                                                        filters.map((x) =>
                                                            x.id === f.id
                                                                ? { id: f.id, mode: "exclude" }
                                                                : x
                                                        )
                                                    )
                                                }
                                            } else {
                                                setFilters([
                                                    ...filters,
                                                    { id: f.id, mode: "exclude" },
                                                ])
                                            }
                                        }}
                                    >
                                        <Icon icon="ui-minus" />
                                    </button>
                                    <button
                                        style={{
                                            ...tickButtonStyle(false, "error"),
                                            marginLeft: 6,
                                        }}
                                        onClick={async () => {
                                            if (
                                                confirm(
                                                    "Are you sure you want to delete this filter?"
                                                )
                                            ) {
                                                await deleteStudioTableFilter(f.id)
                                                await refresh()
                                            }
                                        }}
                                    >
                                        <Icon icon="ui-trash-01" />
                                    </button>
                                    <button
                                        style={{
                                            ...tickButtonStyle(false, "blue"),
                                            marginLeft: 6,
                                        }}
                                        onClick={async () => {
                                            await Modal((close) => {
                                                return (
                                                    <FilterEditor
                                                        title={localize({
                                                            en: "Edit filter",
                                                            no: "Rediger filter",
                                                            sv: "Redigera filter",
                                                            da: "Rediger filter",
                                                        })}
                                                        id={f.id}
                                                        code={f.filterCode}
                                                        description={f.description}
                                                        collectionName={collectionName}
                                                        onSave={async (desc, code) => {
                                                            await putStudioTableFilter(f.id, {
                                                                description: desc,
                                                                filterCode: code as any,
                                                                private: false,
                                                            })
                                                            await refresh()
                                                            close(undefined)
                                                        }}
                                                        onCancel={() => close(undefined)}
                                                    />
                                                )
                                            })
                                        }}
                                    >
                                        <Icon icon="ui-pencil-01" />
                                    </button>
                                </div>
                            )
                        })}

                        <RButton
                            icon="ui-plus"
                            variant="secondary"
                            style={{ marginTop: 8 }}
                            onClick={async () => {
                                await Modal((close) => {
                                    return (
                                        <FilterEditor
                                            title={localize({
                                                en: "New filter",
                                                no: "Nytt filter",
                                                sv: "Nytt filter",
                                                da: "Nyt filter",
                                            })}
                                            collectionName={collectionName}
                                            onSave={async (desc, code) => {
                                                await postStudioTableFilter({
                                                    collectionName,
                                                    description: desc,
                                                    filterCode: code as any,
                                                })
                                                await refresh()
                                                close(undefined)
                                            }}
                                            onCancel={() => close(undefined)}
                                        />
                                    )
                                })
                            }}
                        >
                            Add
                        </RButton>
                    </div>
                )
            }}
        >
            {filters.length === 0
                ? localize({ en: "No filters", no: "Ingen filter", sv: "Inga filter", da: "Ingen" })
                : ""}
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {filters.map((f) => (
                    <div
                        style={{
                            padding: 2,
                            paddingRight: 6,
                            paddingLeft: 6,
                            borderRadius: 6,
                            color: ColorStyles[f.mode === "include" ? "success" : "error"][600],
                            border:
                                "1px solid " +
                                ColorStyles[f.mode === "include" ? "success" : "error"][300],
                        }}
                    >
                        <span style={{ marginRight: 4 }}>{f.mode === "include" ? "+" : "-"}</span>
                        {localize(
                            props.filters?.find((x) => x.id === f.id)?.description ?? {
                                en: "(unknown filter)",
                            }
                        )}
                    </div>
                ))}
            </div>
        </RButton>
    )

    const searchBox = (
        <div style={{ position: "relative", marginRight: 12 }}>
            <div
                style={{
                    position: "absolute",
                    top: 9,
                    left: 12,
                    color: ColorStyles.gray[500],
                }}
            >
                <SearchIcon />
            </div>
            <input
                type="text"
                placeholder={Strings.Search}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{
                    borderColor: ColorStyles.gray[300],
                    borderRadius: 8,
                    borderWidth: 1,
                    padding: 10,
                    paddingLeft: 40,
                    fontSize: 16,
                    fontWeight: 400,
                    boxShadow: "0px 1px 2px 0px #1018280D",
                    borderStyle: "solid",
                }}
            />
        </div>
    )

    const createNewLink = () => {
        const query = props.partial
            ? Object.keys(props.partial).map(
                  (key) => `${key}=${encodeURIComponent(props.partial[key])}`
              )
            : []
        if (props.templateName) query.push(`_template=${props.templateName}`)
        const queryPostfix = query.length ? "?" + query.join("&") : ""

        if (props.createNewLink) {
            return props.createNewLink + queryPostfix
        }

        return `/studio/${props.collection?.toLowerCase()}/new${queryPostfix}`
    }

    const docCollection = useDocumentCollection()
    const docPrimaryKey = useDocumentPrimaryKey()

    const propButtons = props.buttons?.slice() ?? []
    if (docCollection && props.widgetKey && docPrimaryKey) {
        propButtons.unshift({
            type: "Button",
            style: "print",
            text: "Print",
            link: `/print/${docCollection}/${docPrimaryKey}/${props.widgetKey}`,
        })
    }

    const partialQuery = props.partial
        ? "?" +
          Object.entries(props.partial)
              .map(
                  ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`
              )
              .reduce((a, b) => a + "&" + b, "")
        : ""

    const buttons = (
        <>
            {props.canRefresh && props.collection && (
                <RefreshButton
                    refresh={async () => {
                        if (props.collection) {
                            await postDocumentRefresh(props.collection)
                            await wc?.refresh()
                        }
                    }}
                />
            )}
            {propButtons.map((b) => ButtonWidget(b))}
            {supportsFileDrop && props.hasVisibleUploadButton && (
                <label htmlFor="upload">
                    <RButton icon="ui-file-plus-03" hideChevron={true}></RButton>
                    <input id="upload" {...dropInputProps} />
                </label>
            )}
            {props.collection &&
                (props.hideAddButton !== undefined ? !props.hideAddButton : props.canInsert) && (
                    <RButton
                        link={
                            props.templates || props.studioTemplates ? undefined : createNewLink()
                        }
                        style={{ marginLeft: 16 }}
                        variant="primary"
                        popupPlacement="left-start"
                        popup={
                            props.templates || props.studioTemplates
                                ? (close) => (
                                      <div style={{ padding: 4 }}>
                                          {props.templates?.map((t) => (
                                              <RButton
                                                  icon={t.tags?.icon}
                                                  link={`/studio/${props.collection?.toLowerCase()}/new-from-template/${t.name.toLowerCase()}${partialQuery}`}
                                              >
                                                  {localize({
                                                      en: prettyCamel(t.name),
                                                      ...t.tags?.translation,
                                                  })}
                                              </RButton>
                                          ))}
                                          {props.studioTemplates?.map((t) => (
                                              <RButton
                                                  icon={t.icon}
                                                  link={`/studio/${props.collection?.toLowerCase()}/new?studio-template=${
                                                      t.id
                                                  }${partialQuery}`}
                                              >
                                                  {localize(t.name)}
                                              </RButton>
                                          ))}
                                          <RButton
                                              icon="ui-file-plus-01"
                                              onClick={close}
                                              link={`/studio/${props.collection?.toLowerCase()}/new${partialQuery}`}
                                          >
                                              {Translations.Blank(props.itemNameMetadata)}{" "}
                                              {props.itemNameLocalized}
                                          </RButton>
                                      </div>
                                  )
                                : undefined
                        }
                        icon="ui-plus"
                    >
                        {Translations.New(props.itemNameMetadata)}{" "}
                        {lowercaseFirstLetter(ClientSideLocalize(props.itemNameMetadata))}
                    </RButton>
                )}
        </>
    )

    const title = props.title || (props.collection && prettyCamel(props.collection))
    const subTitle = props.subTitle || props.description

    const titleSection = (
        <div style={{ padding: 24, display: "flex", flexDirection: "row" }}>
            <div style={{ flexDirection: "column", flex: 1 }}>
                <div style={{ fontSize: 18, fontWeight: 500 }}>{title}</div>
                <div
                    style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: ColorStyles.gray[500],
                    }}
                >
                    {subTitle}
                </div>
            </div>
            {props.titleButtons}
            {props.primaryAction && (
                <RButton
                    onClick={props.primaryAction.onClicked}
                    style={{ marginLeft: 16 }}
                    variant="primary"
                    icon={props.primaryAction.icon}
                >
                    {props.primaryAction.text}
                </RButton>
            )}
            {props.hideToolbar && buttons}
            {props.widget && <WidgetView value={props.widget} />}
        </div>
    )

    const hasHeader = true

    const showTitleSection = !props.isRoot && !props.hideTitle

    const onRowClicked =
        props.methods?.includes("onRowClicked") || props.resolve || props.onRowClicked
            ? async (r: WidgetMap) => {
                  if (props.widgetKey) {
                      await performAction(props.widgetKey, "onRowClicked", [r])
                  }
                  if (props.resolve) props.resolve(r)
                  else if (props.onRowClicked) props.onRowClicked(r)
              }
            : undefined
    const gridStyle = props.gridTemplateColumns
        ? {
              display: "grid",
              gridTemplateColumns: props.gridTemplateColumns,
          }
        : undefined

    const body = (
        <div
            {...dropProps}
            style={{
                background: "#fff",
                borderColor: hairline,
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 8,
                margin: 16,
                boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                position: "relative",
            }}
        >
            {showTitleSection && titleSection}
            {!props.hideToolbar && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        borderTop: showTitleSection ? "1px solid" : undefined,
                        borderColor: hairline,
                        padding: 16,
                    }}
                >
                    {searchBox}
                    {orderByPicker}
                    {itemsPerPagePicker}
                    {filterPicker}
                    <div style={{ flex: 1 }} />

                    {!props.isRoot && buttons}
                </div>
            )}
            {props.filterError && (
                <div
                    style={{
                        padding: 16,
                        backgroundColor: ColorStyles.error[100],
                        color: ColorStyles.error[600],
                        borderRadius: 8,
                    }}
                >
                    <b>There was a problem with applying the filters: </b>
                    {props.filterError}
                </div>
            )}
            {modal}
            {dropInputProps ? <input {...dropInputProps} /> : undefined}
            {isDragActive ? (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        backgroundColor: "#dddb",
                        borderRadius: 8,
                        display: "flex",

                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <i
                        className="fas fa-file-import"
                        style={{ fontSize: 48, color: "#444", marginBottom: 32 }}
                    />
                    <div>Drop file here to start import</div>
                </div>
            ) : undefined}
            <table
                style={{
                    pageBreakInside: "auto",
                    width: "100%",
                    borderCollapse: "separate",
                    borderRadius: 8,
                    borderSpacing: 0,
                }}
            >
                <thead
                    style={{
                        backgroundColor: headerBg,
                    }}
                >
                    <tr style={gridStyle}>
                        {nonPanelColumns.map((c, i) => (
                            <th
                                key={typeof c === "string" ? c : c.name}
                                style={{
                                    padding: 8,
                                    paddingLeft: i === 0 ? 24 : 8,
                                    borderTopLeftRadius: !hasHeader && i === 0 ? 8 : 0,
                                    borderTopRightRadius:
                                        !hasHeader && i === props.columns.length - 1 ? 8 : 0,
                                    borderTop: "1px solid",
                                    borderTopColor: hairline,
                                    borderBottom: "1px solid",
                                    borderBottomColor: hairline,
                                    tableLayout:
                                        widths.get(getColumnKey(c)) !== undefined
                                            ? "fixed"
                                            : undefined,
                                    width: widths.get(getColumnKey(c)),
                                    gridColumn: i + 1,
                                    fontSize: 12,
                                    color: headerFg,
                                    fontWeight: 400,
                                }}
                            >
                                {typeof c === "string" ? c : localize(c.displayName)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, i) => (
                        <RowView
                            key={row._primaryKey?.toString() ?? "row-" + i}
                            index={i}
                            row={row}
                            collection={props.collection}
                            columns={props.columns}
                            table={props}
                            isTotal={
                                !!(
                                    props.lastRowIsTotal &&
                                    i === rows.length + (extraRows?.length ?? 0) - 1
                                )
                            }
                            gridStyle={gridStyle}
                            onRowClicked={onRowClicked}
                        />
                    ))}
                    {extraRows?.map((row, i) => (
                        <RowView
                            key={row._primaryKey?.toString() ?? "row-" + i}
                            index={i + rows.length}
                            row={row}
                            collection={props.collection}
                            columns={props.columns}
                            table={props}
                            isTotal={
                                !!(
                                    props.lastRowIsTotal &&
                                    i === rows.length + (extraRows?.length ?? 0) - 1
                                )
                            }
                            gridStyle={gridStyle}
                            onRowClicked={onRowClicked}
                        />
                    ))}
                </tbody>
            </table>
            {!props.hidePagingBar && (
                <div
                    style={{
                        borderTop: "1px solid " + hairline,
                        padding: 11,
                        paddingLeft: 24,
                        paddingRight: 24,
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <RButton
                        disabled={pageIndex <= 0}
                        onClick={() => {
                            if (pageIndex > 0) void setPageIndex(pageIndex - 1)
                        }}
                        style={smallButtonStyle}
                    >
                        {Strings.Previous}
                    </RButton>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                            fontWeight: 400,
                            color: ColorStyles.gray[700],
                        }}
                    >
                        {props.totalCount === 0 ? (
                            search ? (
                                <>
                                    {Translations.NoItemMatchesTheSearch(props.itemNameLocalized)}
                                    <code style={{ marginLeft: 6, marginTop: 4 }}>"{search}"</code>
                                </>
                            ) : (
                                <>{Translations.NoItems(props.itemNameLocalized)}</>
                            )
                        ) : (
                            <>
                                {Translations.ShowingXtoYofZ(
                                    props.pageIndex * itemsPerPage + 1,
                                    props.rows.length === 1
                                        ? undefined
                                        : props.pageIndex * itemsPerPage + props.rows.length,
                                    props.totalCount
                                )}
                                {search ? (
                                    <div style={{ marginLeft: 6 }}>
                                        {Strings.matching} <code>"{search}"</code>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </>
                        )}
                    </div>
                    <RButton
                        disabled={pageIndex >= props.pageCount - 1}
                        onClick={() => {
                            if (pageIndex < props.pageCount - 1) void setPageIndex(pageIndex + 1)
                        }}
                        style={smallButtonStyle}
                    >
                        {Strings.Next}
                    </RButton>
                </div>
            )}
        </div>
    )

    if (props.isRoot) {
        return (
            <div style={{ marginLeft: 8, marginRight: 8 }}>
                <TitleWidget
                    title={title || "(untitled)"}
                    subTitle={subTitle}
                    buttons={buttons}
                    sticky={true}
                    widget={props.widget}
                />
                {
                    /** Displays an upload file box above the Files table specifically.
                     *
                     * This is obviously not the cleanest way to implement
                     * this, but the FileDropMethod feature only supports
                     * text-based file formats. This was a quick way that
                     * leverages the FileView component we already have.   */
                    props.collection === "FileRecords" && (
                        <div style={{ padding: 16, paddingBottom: 0 }}>
                            <FileView
                                buttons={[]}
                                icon="file"
                                obj={{}}
                                property={{
                                    name: "uploadFile",
                                    optional: true,
                                    type: { mimeType: "application/octet-stream" },
                                }}
                            />
                        </div>
                    )
                }
                {body}
            </div>
        )
    }

    return body
}

function RowView({
    index,
    row,
    collection,
    columns,
    table,
    isTotal,
    onRowClicked,
    gridStyle,
}: {
    index: number
    row: WidgetMap
    collection?: string
    columns: string[] | TableColProp[]
    table: TableProps
    isTotal: boolean
    onRowClicked?: (result: any) => void
    gridStyle?: CSSProperties
}) {
    const { hover, hoverProps } = useHover()
    const navigate = useNavigate()

    const panelColumns = columns.filter((c) => (row as any)[getColumnKey(c)]?.type === "TablePanel")
    const regularColumns = columns.filter(
        (c) => (row as any)[getColumnKey(c)]?.type !== "TablePanel"
    )

    return (
        <DirectionContext.Provider value="row">
            <tr
                {...hoverProps}
                style={{
                    backgroundColor: !isTotal && hover ? "#f8f8f8" : undefined,
                    cursor: "pointer",
                    pageBreakInside: "avoid",
                    pageBreakAfter: "auto",
                    ...gridStyle,
                }}
                key={row._primaryKey?.toString()}
                onClick={() => {
                    if (onRowClicked) {
                        onRowClicked(row)
                    } else if (row._link) {
                        navigate(`${row._link}`)
                    } else if (row._link_new_tab) {
                        window.open(`${row._link_new_tab}`)
                    } else if (collection) {
                        navigate(`/studio/${collection.toLowerCase()}/${row._primaryKey}`)
                    }
                }}
            >
                {regularColumns.map((c, ci) => (
                    <td
                        key={getColumnKey(c)}
                        style={{
                            pageBreakInside: "avoid",
                            pageBreakAfter: panelColumns.length > 0 ? "avoid" : "auto",
                            padding: 16,
                            paddingLeft: ci === 0 ? 24 : 8,
                            borderTopWidth: index > 0 ? 1 : 0,
                            borderTopStyle: "solid",
                            borderColor: hairline,
                            borderRight: table.columnBorders ? "1px solid " + hairline : undefined,
                            gridColumn: ci + 1,
                        }}
                    >
                        <WidgetView
                            value={row[getColumnKey(c)]}
                            isRoot={false}
                            tableCell={{ row: index, column: ci }}
                        />
                    </td>
                ))}
            </tr>
            {panelColumns.map((p, pi) => (
                <tr key={pi} style={{ pageBreakInside: "avoid" }}>
                    <td
                        style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}
                        colSpan={columns.length}
                    >
                        <WidgetView value={(row[getColumnKey(p)] as any).content} isRoot={false} />
                    </td>
                </tr>
            ))}
        </DirectionContext.Provider>
    )
}

function SearchIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                stroke="#667085"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function RefreshButton(props: { refresh: () => Promise<void> }) {
    const [refreshing, setRefreshing] = useState(false)

    async function refresh() {
        if (refreshing) return
        setRefreshing(true)

        try {
            await props.refresh()
        } catch (e: any) {
            if ("detail" in e) alert(e.detail)
            else alert(JSON.stringify(e))
        } finally {
            setRefreshing(false)
        }
    }

    return (
        <RButton
            onClick={refresh}
            disabled={refreshing}
            tooltip="Fetches the latest data from the backend"
            tooltipPlacement="bottom"
            popupPlacement="left"
            style={{
                ...buttonStyle,
                marginLeft: 16,
                width: refreshing ? 160 : 115,
                fontSize: 14,
                fontWeight: 500,
                opacity: 1,
                transition: "all 0.235s ease",
                color: ColorStyles.gray[700],
            }}
        >
            <i
                className={`fas fa-sync ${refreshing ? "spinning" : ""}`}
                style={{
                    transition: "all 0.3s ease",
                    color: refreshing ? ColorStyles["green-light"][500] : undefined,
                }}
            />
            <div style={{ marginLeft: 12 }}>{refreshing ? "Refreshing..." : "Refresh"}</div>
        </RButton>
    )
}
