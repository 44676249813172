import React from "react"
import { RegisterWidget } from "./WidgetView"
import { WorldMap } from "./WorldMap"
import { ColorStyles } from "../ui"

RegisterWidget<WorldMap>("WorldMap", ({ value }: { value: WorldMap }) => {
    const width = 360 / 2
    const height = 180 / 2
    const dots: boolean[][] = []
    for (let y = 0; y < height; y++) {
        dots[y] = []
        for (let x = 0; x < width; x++) {
            dots[y][x] = isLand((y / height) * 180, (x / width) * 360)
        }
    }

    const maxWeight = Math.max(...(value.pointsOfInterest?.map((p) => p.weight ?? 1) ?? [1]))

    return (
        <svg viewBox={`-1 -1 ${width + 2} ${height + 2}`} style={{ width: "100%", height: "100%" }}>
            {dots.map((row, y) =>
                row.map(
                    (dot, x) =>
                        dot && (
                            <circle
                                key={`${x},${y}`}
                                cx={x + 1}
                                cy={y + 1}
                                r={0.32}
                                fill={ColorStyles.gray[300]}
                            />
                        )
                )
            )}
            {value.pointsOfInterest?.map((p, i) => {
                const { x, y } = latLonToXY(p.lat, p.lon, width, height)

                const r = Math.sqrt(1 + ((p.weight ?? 1) * 3) / maxWeight)

                return (
                    <g key={p.label}>
                        <circle cx={x} cy={y} r={r} fill={ColorStyles.primary[700]} />
                        <circle
                            cx={x}
                            cy={y}
                            r={r * 2}
                            fill={ColorStyles.primary[700]}
                            opacity={0.25}
                        />
                        <circle
                            cx={x}
                            cy={y}
                            r={r * 3}
                            fill={ColorStyles.primary[700]}
                            opacity={0.125}
                        />
                    </g>
                )
            })}
        </svg>
    )
})

function latLonToXY(
    latitude: number,
    longitude: number,
    mapWidth: number,
    mapHeight: number
): { x: number; y: number } {
    // Constants
    const centralLongitude = 20 // Central longitude of Norway
    const mapMinLongitude = centralLongitude - 170 // Assuming Norway is at the center
    const mapMaxLongitude = centralLongitude + 180
    const mapMinLatitude = -50 // Min latitude for equirectangular projection
    const mapMaxLatitude = 80 // Max latitude for equirectangular projection

    // Normalizing Longitude and Latitude
    const normalizedLongitude = (longitude - mapMinLongitude) / (mapMaxLongitude - mapMinLongitude)
    const normalizedLatitude = (latitude - mapMinLatitude) / (mapMaxLatitude - mapMinLatitude)

    // Convert to Pixel Coordinates
    const x = normalizedLongitude * mapWidth
    const y = (1 - normalizedLatitude) * mapHeight // Inverting Y to have north up

    return { x, y }
}

function isLand(lat: number, lon: number) {
    const x = Math.floor((lon / 360) * MAP_WIDTH)
    const y = Math.floor((lat / 180) * MAP_HEIGHT)

    const scanline = worldMapRLE[y]
    if (!scanline) {
        return false
    }
    let land = false
    let i = 0
    for (const segment of scanline) {
        i += segment
        if (i >= x) {
            break
        }

        land = !land
    }

    return land
}

const worldMapRLE: number[][] = [
    [260, 21, 447],
    [206, 26, 20, 33, 443],
    [195, 35, 2, 53, 6, 7, 430],
    [186, 2, 2, 36, 5, 53, 1, 12, 431],
    [184, 6, 1, 29, 4, 70, 147, 6, 281],
    [180, 13, 2, 22, 5, 69, 48, 7, 1, 4, 90, 9, 278],
    [180, 14, 1, 17, 11, 63, 44, 5, 1, 3, 2, 10, 91, 1, 1, 0],
    [165, 6, 9, 14, 1, 13, 9, 69, 44, 13, 4, 2, 98, 8, 1, 5, 267],
    [153, 4, 7, 8, 2, 4, 3, 6, 2, 18, 4, 72, 48, 3, 1, 10, 111, 8, 264],
    [150, 5, 14, 2, 3, 4, 9, 2, 2, 11, 9, 75, 47, 7, 3, 5, 380],
    [143, 2, 5, 4, 21, 1, 10, 14, 10, 2, 2, 2, 1, 68, 48, 6, 5, 4, 113, 6, 261],
    [137, 7, 28, 4, 6, 16, 12, 72, 1, 4, 48, 3, 72, 4, 47, 10, 257],
    [131, 6, 4, 5, 4, 4, 5, 3, 3, 4, 3, 8, 5, 5, 4, 1, 16, 72, 123, 8, 46, 21, 247],
    [133, 2, 5, 8, 2, 6, 3, 9, 8, 4, 7, 4, 33, 60, 116, 10, 40, 32, 35, 7, 1, 2, 201],
    [137, 5, 3, 9, 8, 5, 1, 5, 2, 18, 33, 58, 114, 6, 40, 39, 34, 12, 3, 3, 193],
    [140, 7, 21, 4, 2, 18, 34, 58, 113, 7, 39, 39, 39, 2, 1, 1, 2, 1, 7, 4, 189],
    [123, 7, 2, 2, 6, 4, 39, 1, 43, 56, 114, 5, 42, 36, 248],
    [122, 13, 14, 2, 7, 6, 3, 7, 6, 2, 45, 54, 114, 6, 36, 2, 4, 36, 4, 10, 235],
    [121, 13, 14, 3, 6, 6, 2, 7, 3, 5, 3, 11, 33, 47, 2, 4, 115, 5, 36, 60, 6, 7, 219],
    [
        119, 11, 2, 7, 3, 1, 2, 4, 4, 10, 1, 6, 4, 5, 2, 6, 2, 5, 33, 47, 120, 5, 21, 4, 5, 1, 7,
        75, 19, 1, 196,
    ],
    [
        116, 10, 1, 16, 1, 4, 5, 9, 2, 2, 6, 5, 1, 17, 31, 49, 119, 5, 20, 6, 4, 1, 4, 3, 3, 73, 17,
        15, 183,
    ],
    [
        116, 8, 1, 23, 7, 5, 3, 2, 6, 6, 1, 18, 28, 53, 116, 6, 20, 7, 2, 3, 1, 8, 1, 73, 6, 1, 10,
        17, 180,
    ],
    [
        64, 1, 52, 2, 1, 1, 4, 22, 9, 1, 4, 4, 6, 28, 26, 2, 2, 43, 1, 5, 115, 1, 2, 5, 17, 8, 2,
        89, 3, 30, 178,
    ],
    [
        58, 12, 55, 22, 12, 6, 5, 31, 25, 1, 2, 42, 3, 4, 73, 2, 2, 5, 37, 6, 15, 9, 3, 89, 1, 46,
        162,
    ],
    [55, 20, 32, 2, 24, 15, 10, 7, 6, 32, 22, 44, 2, 1, 3, 2, 70, 14, 57, 9, 3, 137, 161],
    [
        51, 33, 16, 4, 1, 3, 3, 1, 12, 25, 9, 7, 9, 11, 2, 16, 21, 4, 1, 47, 65, 19, 46, 2, 11, 10,
        1, 139, 16, 7, 136,
    ],
    [
        48, 39, 5, 5, 1, 18, 8, 23, 6, 3, 3, 5, 9, 6, 9, 16, 20, 4, 1, 44, 67, 27, 23, 2, 17, 7, 4,
        9, 2, 153, 4, 11, 129,
    ],
    [
        41, 79, 2, 3, 2, 10, 3, 6, 4, 6, 3, 6, 1, 2, 5, 6, 11, 13, 26, 42, 65, 2, 2, 31, 19, 3, 13,
        2, 1, 11, 3, 7, 6, 150, 3, 15, 124,
    ],
    [39, 86, 3, 2, 21, 4, 3, 10, 4, 7, 11, 11, 24, 42, 68, 39, 11, 1, 9, 28, 1, 7, 2, 175, 120],
    [
        39, 86, 11, 7, 6, 5, 1, 12, 3, 8, 7, 3, 3, 13, 20, 35, 1, 1, 74, 42, 7, 3, 6, 38, 3, 4, 1,
        174, 115,
    ],
    [40, 84, 1, 7, 3, 32, 2, 8, 7, 4, 3, 15, 17, 34, 79, 43, 5, 3, 5, 39, 3, 182, 112],
    [40, 93, 1, 43, 13, 18, 14, 34, 79, 44, 5, 3, 4, 39, 4, 185, 109],
    [40, 134, 15, 19, 14, 32, 80, 34, 2, 9, 6, 43, 5, 194, 101],
    [
        33, 5, 2, 130, 17, 12, 1, 7, 14, 31, 20, 2, 5, 1, 3, 3, 47, 36, 6, 3, 2, 3, 2, 228, 1, 14,
        100,
    ],
    [28, 140, 19, 11, 3, 5, 16, 28, 21, 4, 2, 10, 44, 17, 5, 16, 10, 234, 2, 15, 98],
    [28, 132, 1, 7, 11, 19, 5, 1, 18, 21, 30, 15, 43, 16, 6, 17, 8, 238, 2, 12, 98],
    [28, 134, 2, 6, 9, 20, 23, 20, 29, 16, 43, 17, 6, 17, 3, 3, 3, 237, 7, 7, 98],
    [35, 126, 2, 8, 8, 1, 2, 2, 1, 14, 23, 20, 31, 13, 44, 17, 5, 19, 3, 238, 14, 4, 98],
    [35, 124, 2, 6, 2, 4, 13, 14, 22, 19, 31, 11, 46, 18, 3, 267, 111],
    [33, 122, 8, 3, 4, 2, 15, 12, 24, 18, 35, 4, 47, 16, 6, 270, 109],
    [26, 128, 13, 1, 19, 7, 2, 3, 24, 15, 86, 18, 6, 272, 108],
    [24, 128, 13, 3, 21, 5, 29, 14, 86, 18, 7, 247, 1, 27, 105],
    [22, 127, 15, 3, 3, 2, 2, 9, 8, 4, 29, 12, 85, 20, 7, 247, 2, 19, 2, 3, 107],
    [21, 126, 23, 1, 2, 11, 40, 12, 84, 20, 8, 240, 2, 6, 2, 17, 113],
    [19, 127, 27, 13, 39, 10, 85, 20, 8, 238, 5, 5, 3, 15, 114],
    [17, 21, 3, 103, 28, 15, 38, 2, 1, 7, 85, 21, 7, 238, 7, 2, 3, 16, 114],
    [16, 21, 3, 8, 8, 87, 29, 15, 42, 5, 86, 23, 6, 10, 2, 225, 12, 9, 3, 4, 114],
    [20, 16, 3, 7, 12, 84, 30, 14, 8, 2, 35, 1, 88, 24, 9, 3, 6, 224, 12, 5, 2, 2, 121],
    [19, 16, 4, 1, 20, 80, 31, 15, 7, 3, 124, 24, 16, 216, 4, 8, 10, 4, 126],
    [19, 15, 27, 78, 31, 15, 7, 4, 124, 8, 1, 14, 10, 213, 15, 7, 9, 6, 125],
    [18, 3, 2, 10, 28, 80, 27, 18, 3, 7, 107, 1, 16, 7, 3, 10, 13, 212, 18, 2, 12, 6, 125],
    [23, 7, 31, 80, 27, 29, 103, 3, 18, 3, 7, 9, 14, 211, 32, 7, 124],
    [22, 5, 34, 80, 28, 28, 102, 4, 28, 9, 14, 210, 32, 11, 121],
    [21, 4, 36, 79, 29, 28, 101, 8, 22, 1, 3, 8, 9, 3, 2, 210, 32, 11, 121],
    [18, 4, 39, 83, 25, 28, 101, 7, 20, 4, 3, 8, 9, 215, 32, 12, 120],
    [15, 5, 41, 84, 23, 28, 102, 6, 20, 5, 3, 7, 9, 216, 32, 15, 117],
    [13, 5, 43, 87, 20, 28, 102, 6, 20, 5, 4, 5, 10, 215, 34, 14, 117],
    [11, 5, 45, 88, 18, 29, 102, 2, 1, 5, 18, 4, 6, 2, 12, 215, 34, 12, 119],
    [62, 88, 15, 34, 101, 6, 19, 5, 18, 164, 1, 49, 36, 14, 116],
    [64, 91, 6, 39, 93, 5, 2, 6, 19, 3, 19, 166, 1, 48, 37, 13, 116],
    [64, 91, 5, 41, 92, 6, 4, 5, 18, 2, 5, 1, 7, 3, 2, 166, 2, 52, 34, 12, 116],
    [64, 91, 5, 42, 88, 8, 5, 6, 17, 4, 1, 4, 3, 174, 2, 53, 2, 2, 6, 1, 23, 9, 118],
    [63, 91, 6, 42, 88, 7, 6, 6, 14, 189, 2, 59, 4, 3, 23, 8, 117],
    [63, 90, 7, 43, 88, 6, 3, 9, 10, 193, 2, 67, 23, 8, 116],
    [63, 90, 6, 44, 88, 6, 3, 11, 8, 192, 2, 68, 24, 6, 117],
    [64, 89, 6, 44, 87, 7, 3, 12, 5, 193, 2, 66, 1, 3, 23, 6, 117],
    [64, 90, 4, 44, 87, 5, 5, 12, 6, 192, 2, 67, 2, 3, 23, 5, 117],
    [64, 91, 1, 44, 90, 2, 8, 10, 6, 192, 3, 67, 3, 3, 23, 4, 117],
    [64, 133, 2, 2, 100, 10, 2, 266, 3, 4, 23, 2, 117],
    [61, 134, 3, 3, 98, 10, 3, 268, 3, 4, 22, 1, 118],
    [62, 131, 4, 3, 98, 3, 10, 270, 2, 5, 21, 1, 118],
    [62, 4, 1, 113, 4, 2, 10, 4, 110, 271, 3, 4, 140],
    [63, 4, 1, 109, 8, 3, 7, 6, 104, 1, 2, 274, 3, 4, 139],
    [64, 3, 1, 107, 4, 3, 4, 2, 7, 9, 101, 277, 3, 3, 1, 1, 138],
    [65, 2, 1, 105, 3, 6, 11, 11, 96, 3, 2, 277, 4, 2, 140],
    [63, 3, 1, 105, 2, 8, 11, 11, 95, 283, 4, 2, 140],
    [63, 115, 14, 12, 96, 282, 5, 2, 139],
    [63, 118, 13, 1, 3, 3, 1, 2, 98, 280, 6, 2, 138],
    [62, 118, 18, 2, 1, 3, 100, 278, 6, 4, 136],
    [62, 118, 7, 1, 14, 2, 100, 77, 2, 25, 5, 43, 1, 2, 1, 4, 1, 117, 7, 3, 136],
    [62, 118, 6, 2, 117, 64, 2, 8, 4, 24, 7, 41, 2, 3, 4, 118, 7, 2, 1, 1, 135],
    [61, 120, 4, 4, 117, 62, 6, 4, 5, 21, 10, 12, 2, 27, 1, 126, 24, 1, 121],
    [61, 125, 120, 26, 3, 33, 5, 7, 1, 22, 11, 11, 3, 27, 1, 126, 9, 1, 136],
    [60, 116, 2, 7, 120, 28, 2, 1, 1, 30, 7, 28, 8, 5, 1, 9, 3, 154, 9, 3, 9, 1, 124],
    [58, 115, 3, 7, 122, 28, 4, 29, 9, 2, 6, 19, 8, 15, 4, 152, 10, 4, 133],
    [58, 114, 3, 4, 126, 28, 5, 28, 18, 19, 6, 17, 3, 152, 11, 4, 5, 1, 126],
    [57, 111, 6, 3, 128, 19, 4, 6, 5, 26, 20, 19, 6, 171, 12, 8, 127],
    [56, 110, 9, 1, 115, 8, 5, 19, 6, 6, 5, 25, 23, 16, 7, 169, 13, 8, 127],
    [56, 109, 124, 25, 5, 2, 8, 7, 5, 23, 26, 14, 8, 162, 1, 5, 11, 11, 126],
    [55, 109, 125, 25, 12, 1, 3, 6, 8, 20, 27, 14, 8, 161, 17, 8, 129],
    [55, 109, 126, 24, 11, 2, 4, 6, 8, 19, 29, 13, 8, 159, 19, 1, 4, 2, 129],
    [54, 110, 125, 25, 11, 2, 5, 8, 7, 18, 10, 5, 13, 14, 7, 1, 2, 155, 21, 2, 133],
    [53, 108, 2, 1, 125, 24, 20, 8, 6, 20, 6, 9, 11, 15, 6, 2, 2, 155, 20, 1, 134],
    [53, 105, 131, 22, 14, 2, 8, 8, 4, 39, 5, 17, 10, 155, 23, 1, 131],
    [52, 104, 133, 20, 15, 3, 10, 8, 2, 10, 2, 4, 4, 43, 6, 139, 3, 15, 21, 4, 130],
    [52, 104, 133, 19, 16, 3, 11, 4, 2, 1, 2, 7, 1, 1, 6, 48, 6, 139, 3, 13, 24, 4, 129],
    [52, 103, 133, 19, 6, 1, 10, 3, 12, 3, 6, 6, 7, 48, 8, 137, 4, 5, 2, 5, 25, 5, 128],
    [52, 102, 134, 18, 6, 2, 11, 2, 13, 3, 5, 7, 7, 47, 8, 136, 5, 3, 5, 5, 25, 4, 128],
    [51, 102, 134, 20, 18, 2, 13, 3, 7, 6, 6, 47, 9, 132, 9, 2, 5, 6, 24, 5, 127],
    [51, 101, 136, 19, 33, 2, 9, 7, 5, 46, 9, 133, 15, 7, 23, 5, 127],
    [51, 97, 1, 2, 138, 17, 33, 2, 12, 5, 5, 46, 10, 135, 13, 7, 22, 5, 127],
    [51, 97, 1, 2, 138, 17, 27, 6, 12, 5, 2, 1, 4, 47, 9, 136, 3, 2, 9, 7, 20, 4, 128],
    [52, 96, 140, 17, 29, 5, 13, 3, 9, 47, 8, 136, 2, 5, 8, 7, 19, 5, 127],
    [52, 96, 142, 2, 1, 10, 14, 11, 8, 3, 13, 3, 9, 49, 7, 143, 7, 8, 14, 1, 2, 6, 127],
    [53, 96, 144, 5, 1, 3, 7, 21, 7, 2, 15, 1, 12, 4, 2, 43, 2, 143, 10, 7, 14, 9, 127],
    [53, 95, 146, 2, 11, 23, 38, 3, 3, 4, 4, 178, 12, 6, 14, 9, 127],
    [52, 96, 146, 2, 9, 25, 52, 176, 14, 7, 13, 10, 126],
    [52, 96, 146, 2, 7, 27, 52, 176, 14, 7, 7, 16, 126],
    [52, 95, 146, 37, 27, 5, 13, 4, 3, 177, 13, 7, 6, 15, 1, 1, 126],
    [53, 92, 147, 38, 45, 2, 5, 178, 13, 6, 6, 15, 128],
    [54, 89, 148, 39, 52, 179, 22, 5, 1, 1, 2, 3, 133],
    [55, 87, 146, 41, 53, 180, 21, 2, 3, 3, 1, 3, 133],
    [57, 83, 147, 43, 52, 180, 20, 3, 2, 4, 2, 2, 133],
    [57, 81, 149, 46, 48, 182, 18, 5, 1, 2, 139],
    [57, 80, 150, 49, 14, 4, 27, 182, 19, 5, 141],
    [58, 78, 150, 53, 10, 8, 24, 184, 18, 4, 141],
    [58, 77, 151, 54, 9, 8, 23, 186, 18, 3, 141],
    [58, 4, 1, 71, 152, 55, 8, 15, 8, 4, 4, 187, 17, 3, 141],
    [58, 4, 3, 68, 152, 58, 6, 19, 2, 197, 18, 1, 142],
    [58, 4, 3, 68, 152, 60, 4, 218, 161],
    [58, 3, 4, 54, 2, 11, 153, 282, 161],
    [59, 2, 3, 50, 8, 4, 1, 5, 152, 126, 4, 153, 161],
    [59, 2, 3, 50, 13, 5, 152, 92, 1, 33, 5, 153, 160],
    [60, 2, 3, 38, 6, 5, 14, 4, 151, 127, 6, 152, 160],
    [60, 3, 2, 35, 28, 4, 150, 128, 6, 152, 160],
    [61, 3, 2, 32, 30, 4, 148, 131, 6, 151, 160],
    [60, 4, 3, 30, 31, 5, 145, 102, 2, 30, 5, 151, 160],
    [58, 6, 3, 29, 32, 5, 144, 102, 4, 30, 7, 147, 161],
    [59, 5, 4, 28, 32, 5, 144, 103, 3, 30, 8, 146, 161],
    [60, 5, 4, 27, 32, 5, 143, 104, 4, 30, 9, 4, 2, 137, 162],
    [62, 3, 4, 26, 33, 5, 142, 106, 4, 30, 15, 136, 162],
    [63, 2, 4, 26, 34, 3, 143, 107, 3, 30, 12, 2, 1, 135, 163],
    [63, 2, 5, 25, 34, 3, 141, 109, 4, 30, 1, 1, 9, 2, 2, 134, 163],
    [63, 3, 5, 24, 177, 111, 3, 32, 8, 3, 8, 5, 2, 3, 4, 114, 163],
    [63, 3, 5, 23, 178, 111, 4, 31, 7, 4, 23, 113, 3, 3, 157],
    [63, 3, 6, 22, 178, 112, 4, 30, 6, 6, 22, 112, 4, 3, 157],
    [64, 3, 6, 20, 179, 112, 5, 42, 22, 110, 5, 3, 157],
    [65, 2, 7, 19, 179, 112, 6, 43, 22, 106, 7, 3, 157],
    [65, 2, 7, 19, 179, 113, 5, 45, 21, 104, 8, 3, 157],
    [66, 1, 8, 18, 30, 8, 140, 115, 5, 45, 23, 100, 9, 3, 157],
    [75, 18, 29, 4, 1, 5, 138, 116, 5, 46, 20, 1, 1, 45, 2, 50, 13, 2, 157],
    [75, 18, 28, 3, 4, 8, 133, 118, 4, 46, 20, 7, 1, 39, 2, 47, 16, 1, 158],
    [75, 18, 31, 1, 6, 6, 131, 119, 4, 45, 22, 6, 1, 32, 2, 2, 6, 36, 1, 8, 176],
    [75, 18, 17, 6, 18, 4, 130, 120, 3, 44, 24, 5, 1, 31, 11, 35, 3, 3, 180],
    [75, 18, 15, 8, 19, 5, 128, 120, 4, 43, 24, 3, 3, 31, 12, 33, 5, 2, 180],
    [75, 19, 14, 7, 21, 6, 126, 120, 5, 41, 31, 31, 12, 32, 6, 2, 180],
    [74, 20, 14, 6, 22, 7, 126, 119, 6, 39, 32, 31, 14, 29, 189],
    [75, 19, 13, 7, 22, 1, 8, 6, 118, 120, 6, 38, 32, 30, 16, 28, 6, 4, 179],
    [75, 20, 12, 7, 32, 7, 117, 119, 7, 37, 32, 28, 19, 27, 6, 4, 179],
    [76, 19, 10, 8, 33, 9, 115, 119, 7, 37, 33, 26, 20, 27, 6, 4, 179],
    [78, 20, 3, 12, 29, 3, 1, 10, 3, 1, 110, 120, 7, 33, 36, 25, 22, 26, 6, 3, 180],
    [79, 19, 1, 13, 31, 7, 8, 3, 109, 121, 7, 32, 36, 25, 22, 27, 31, 4, 153],
    [82, 30, 36, 1, 120, 122, 7, 31, 38, 23, 24, 27, 30, 4, 153],
    [83, 29, 157, 123, 7, 28, 40, 21, 26, 28, 29, 4, 153],
    [85, 26, 158, 124, 7, 27, 40, 21, 26, 5, 1, 23, 28, 5, 152],
    [87, 24, 158, 124, 7, 25, 42, 20, 27, 5, 2, 23, 27, 5, 152],
    [89, 21, 159, 125, 6, 22, 46, 17, 29, 4, 3, 24, 25, 5, 153],
    [91, 4, 4, 11, 1, 8, 150, 125, 6, 21, 47, 16, 31, 2, 5, 24, 24, 4, 154],
    [100, 21, 147, 127, 5, 21, 48, 14, 39, 24, 25, 3, 154],
    [101, 21, 145, 129, 5, 15, 54, 13, 39, 25, 24, 4, 153],
    [102, 20, 145, 130, 4, 14, 55, 13, 39, 25, 25, 3, 153],
    [103, 19, 145, 130, 4, 13, 56, 13, 40, 25, 25, 2, 1, 2, 150],
    [105, 16, 147, 131, 3, 10, 59, 13, 39, 25, 25, 2, 1, 4, 148],
    [107, 14, 147, 132, 2, 7, 62, 13, 39, 5, 1, 19, 25, 1, 4, 2, 148],
    [111, 9, 148, 133, 2, 4, 64, 13, 40, 3, 2, 19, 25, 2, 4, 2, 147],
    [112, 8, 148, 134, 69, 13, 40, 3, 3, 18, 26, 1, 153],
    [113, 7, 26, 2, 122, 132, 70, 12, 40, 4, 4, 16, 31, 5, 144],
    [114, 1, 1, 4, 25, 3, 123, 131, 14, 3, 54, 10, 42, 3, 6, 14, 28, 1, 5, 2, 144],
    [114, 1, 2, 3, 23, 3, 4, 2, 119, 132, 9, 8, 53, 10, 42, 2, 7, 14, 28, 3, 3, 2, 144],
    [115, 5, 19, 7, 2, 6, 117, 133, 2, 14, 54, 9, 41, 2, 8, 13, 30, 2, 2, 3, 144],
    [115, 5, 18, 16, 3, 1, 6, 1, 108, 147, 54, 9, 41, 2, 10, 9, 25, 2, 5, 4, 1, 2, 144],
    [115, 6, 17, 8, 1, 13, 2, 4, 108, 146, 55, 8, 42, 1, 12, 5, 27, 1, 7, 3, 2, 1, 144],
    [116, 5, 16, 9, 1, 21, 106, 146, 55, 7, 42, 2, 13, 4, 26, 1, 8, 4, 146],
    [118, 4, 6, 3, 6, 9, 2, 22, 105, 144, 57, 5, 3, 1, 39, 2, 13, 3, 26, 1, 9, 2, 5, 1, 142],
    [119, 5, 3, 6, 3, 34, 105, 144, 57, 5, 3, 2, 38, 4, 11, 2, 26, 2, 14, 3, 142],
    [119, 9, 2, 4, 1, 35, 106, 142, 58, 4, 3, 3, 38, 5, 10, 1, 27, 1, 14, 5, 141],
    [121, 6, 4, 42, 103, 142, 59, 2, 4, 3, 38, 5, 50, 8, 141],
    [124, 3, 4, 43, 103, 140, 66, 4, 39, 3, 49, 9, 141],
    [125, 3, 3, 44, 102, 140, 66, 5, 39, 2, 48, 1, 1, 1, 2, 6, 140],
    [126, 1, 5, 43, 103, 138, 67, 5, 39, 3, 47, 1, 4, 6, 140],
    [133, 43, 103, 137, 67, 4, 41, 4, 33, 2, 15, 3, 1, 1, 141],
    [133, 44, 104, 31, 2, 102, 68, 3, 42, 4, 32, 3, 14, 4, 142],
    [133, 51, 97, 26, 8, 100, 114, 5, 30, 5, 14, 3, 142],
    [133, 55, 94, 24, 10, 99, 103, 1, 10, 6, 28, 8, 157],
    [133, 56, 93, 10, 8, 5, 11, 98, 104, 6, 5, 7, 27, 8, 157],
    [133, 57, 93, 8, 10, 1, 15, 96, 105, 7, 5, 6, 25, 9, 158],
    [133, 58, 94, 3, 30, 2, 5, 87, 107, 6, 5, 6, 24, 10, 158],
    [133, 58, 135, 85, 109, 6, 4, 6, 23, 9, 160],
    [133, 59, 135, 84, 110, 6, 4, 5, 22, 10, 160],
    [133, 59, 135, 84, 111, 6, 3, 5, 21, 11, 160],
    [132, 60, 135, 83, 113, 6, 3, 5, 17, 15, 159],
    [131, 62, 134, 82, 114, 7, 4, 4, 16, 15, 23, 1, 135],
    [130, 64, 132, 82, 117, 7, 3, 4, 11, 1, 3, 15, 22, 1, 136],
    [129, 65, 132, 81, 119, 7, 3, 3, 10, 21, 20, 2, 136],
    [129, 65, 132, 79, 118, 1, 2, 9, 14, 22, 4, 2, 6, 2, 5, 3, 135],
    [127, 67, 132, 78, 119, 1, 3, 9, 12, 21, 5, 10, 6, 2, 136],
    [127, 67, 132, 77, 124, 9, 13, 19, 5, 1, 1, 1, 4, 3, 7, 3, 135],
    [126, 70, 129, 53, 3, 21, 125, 10, 12, 19, 5, 1, 154],
    [126, 71, 128, 51, 6, 19, 127, 9, 12, 19, 5, 1, 16, 2, 5, 1, 3, 1, 126],
    [125, 72, 2, 2, 124, 51, 6, 18, 129, 8, 13, 18, 4, 2, 4, 3, 9, 2, 7, 6, 123],
    [125, 79, 122, 50, 5, 19, 125, 2, 3, 9, 11, 17, 5, 3, 1, 5, 16, 8, 123],
    [125, 81, 120, 50, 5, 18, 127, 1, 3, 9, 2, 1, 9, 15, 6, 6, 2, 1, 9, 1, 8, 6, 7, 2, 114],
    [125, 81, 121, 49, 4, 18, 133, 12, 8, 15, 6, 5, 9, 1, 8, 1, 4, 5, 7, 4, 17, 1, 94],
    [126, 81, 120, 49, 2, 1, 2, 16, 134, 12, 8, 15, 5, 7, 24, 1, 1, 1, 5, 8, 110],
    [127, 85, 115, 70, 134, 13, 2, 1, 4, 14, 6, 7, 23, 5, 3, 13, 106],
    [
        127, 91, 110, 67, 134, 1, 2, 10, 4, 1, 8, 3, 1, 6, 6, 3, 1, 4, 8, 2, 2, 6, 4, 6, 1, 15, 21,
        1, 83,
    ],
    [126, 94, 109, 66, 138, 9, 19, 4, 7, 2, 1, 4, 8, 2, 7, 2, 4, 24, 19, 1, 82],
    [125, 96, 109, 65, 139, 8, 19, 2, 9, 2, 2, 3, 23, 1, 2, 23, 18, 1, 81],
    [124, 98, 109, 63, 140, 8, 30, 2, 3, 3, 26, 24, 14, 1, 1, 1, 81],
    [125, 98, 108, 63, 141, 7, 30, 2, 3, 3, 30, 21, 13, 1, 83],
    [125, 102, 104, 40, 1, 21, 143, 6, 30, 2, 4, 2, 32, 19, 11, 3, 83],
    [125, 102, 105, 39, 1, 21, 144, 5, 30, 2, 5, 1, 33, 18, 8, 5, 84],
    [125, 102, 106, 38, 1, 20, 146, 1, 66, 1, 7, 21, 3, 6, 7, 2, 76],
    [126, 102, 105, 38, 1, 21, 148, 6, 57, 2, 8, 21, 4, 2, 10, 2, 75],
    [127, 101, 105, 38, 2, 20, 148, 7, 4, 2, 50, 1, 9, 21, 17, 1, 75],
    [128, 100, 105, 39, 2, 20, 148, 17, 55, 19, 22, 1, 72],
    [128, 100, 106, 39, 1, 20, 149, 14, 41, 1, 14, 21, 94],
    [129, 99, 106, 39, 1, 20, 155, 11, 26, 2, 24, 14, 2, 7, 24, 1, 68],
    [130, 98, 106, 40, 1, 19, 158, 1, 1, 9, 1, 1, 2, 3, 9, 1, 32, 3, 2, 8, 6, 5, 19, 2, 3, 1, 67],
    [130, 98, 106, 60, 167, 2, 1, 7, 1, 6, 6, 4, 30, 7, 6, 5, 27, 1, 64],
    [131, 96, 107, 60, 189, 3, 33, 5, 8, 6, 7, 1, 17, 1, 64],
    [131, 96, 107, 61, 176, 2, 8, 3, 49, 5, 23, 2, 65],
    [132, 94, 108, 47, 1, 14, 176, 2, 6, 3, 50, 6, 2, 1, 19, 2, 65],
    [132, 93, 110, 61, 184, 2, 54, 4, 88],
    [133, 92, 110, 61, 183, 1, 85, 1, 62],
    [133, 90, 112, 61, 225, 2, 105],
    [134, 89, 112, 47, 1, 13, 199, 2, 2, 2, 20, 2, 105],
    [134, 88, 113, 46, 2, 13, 199, 2, 3, 3, 18, 2, 105],
    [135, 87, 112, 47, 2, 13, 18, 2, 180, 2, 1, 10, 11, 3, 105],
    [135, 86, 112, 48, 2, 13, 18, 2, 179, 14, 11, 4, 104],
    [136, 84, 113, 49, 1, 13, 17, 3, 178, 14, 11, 5, 104],
    [137, 83, 113, 49, 1, 13, 15, 6, 177, 13, 12, 5, 104],
    [137, 82, 113, 50, 1, 13, 15, 6, 176, 14, 12, 5, 104],
    [137, 83, 112, 64, 15, 6, 168, 3, 4, 14, 13, 5, 104],
    [138, 82, 112, 64, 14, 7, 166, 6, 3, 14, 13, 7, 102],
    [139, 81, 112, 64, 13, 9, 164, 24, 13, 8, 47, 1, 53],
    [140, 80, 112, 64, 12, 10, 163, 26, 11, 9, 47, 1, 53],
    [141, 79, 112, 63, 11, 12, 163, 27, 10, 9, 101],
    [143, 77, 111, 63, 10, 12, 164, 29, 9, 9, 48, 1, 52],
    [144, 76, 111, 62, 10, 13, 162, 33, 6, 10, 73, 2, 26],
    [146, 74, 111, 61, 11, 13, 159, 37, 5, 10, 101],
    [149, 71, 111, 59, 13, 12, 160, 38, 3, 12, 70, 1, 29],
    [150, 70, 111, 58, 14, 12, 160, 53, 48, 1, 19, 3, 29],
    [151, 68, 113, 55, 15, 13, 159, 53, 69, 3, 29],
    [151, 68, 114, 53, 17, 12, 158, 55, 100],
    [152, 67, 114, 53, 17, 11, 159, 55, 100],
    [152, 67, 114, 50, 20, 11, 158, 57, 99],
    [152, 67, 115, 49, 20, 11, 156, 61, 97],
    [152, 66, 116, 49, 20, 11, 152, 66, 96],
    [152, 66, 116, 49, 19, 11, 151, 68, 34, 1, 61],
    [152, 65, 117, 50, 17, 12, 147, 72, 34, 2, 60],
    [153, 64, 118, 49, 16, 13, 146, 74, 34, 2, 59],
    [153, 64, 118, 49, 16, 12, 145, 76, 35, 2, 58],
    [153, 63, 120, 48, 16, 12, 142, 79, 36, 2, 57],
    [153, 62, 122, 47, 16, 11, 143, 81, 93],
    [152, 63, 122, 47, 16, 11, 143, 81, 93],
    [152, 56, 129, 47, 17, 10, 143, 81, 93],
    [152, 55, 130, 47, 17, 9, 143, 83, 92],
    [152, 54, 132, 45, 18, 9, 143, 84, 91],
    [153, 51, 134, 44, 20, 8, 143, 84, 91],
    [153, 49, 136, 43, 21, 7, 144, 84, 91],
    [153, 48, 137, 40, 26, 3, 146, 85, 90],
    [153, 48, 138, 39, 175, 85, 90],
    [152, 49, 138, 39, 175, 85, 90],
    [152, 49, 138, 39, 174, 86, 90],
    [153, 48, 138, 39, 174, 86, 90],
    [153, 48, 138, 39, 174, 85, 91],
    [153, 49, 137, 38, 175, 85, 91],
    [153, 49, 138, 37, 175, 85, 91],
    [153, 49, 139, 35, 177, 84, 91],
    [152, 49, 141, 34, 177, 84, 91],
    [152, 48, 143, 31, 179, 83, 92],
    [153, 46, 144, 30, 180, 83, 92],
    [153, 46, 144, 30, 180, 82, 93],
    [153, 46, 144, 29, 181, 81, 94],
    [153, 46, 145, 27, 182, 81, 94],
    [154, 44, 146, 27, 182, 80, 95],
    [154, 44, 147, 25, 183, 33, 1, 46, 95],
    [154, 43, 148, 24, 184, 27, 8, 44, 96],
    [155, 41, 149, 23, 185, 21, 17, 40, 97],
    [155, 40, 149, 22, 186, 21, 18, 39, 98],
    [155, 40, 149, 20, 188, 18, 22, 36, 100],
    [155, 39, 151, 18, 188, 18, 23, 5, 1, 29, 101],
    [155, 39, 151, 17, 188, 10, 32, 3, 2, 29, 102],
    [155, 38, 153, 9, 2, 3, 190, 9, 33, 2, 3, 29, 102],
    [155, 30, 2, 6, 358, 6, 35, 2, 2, 1, 1, 27, 103],
    [155, 32, 408, 1, 2, 26, 47, 2, 55],
    [154, 33, 410, 26, 48, 2, 55],
    [154, 33, 407, 2, 3, 23, 49, 2, 55],
    [154, 35, 410, 22, 50, 2, 55],
    [154, 35, 410, 22, 50, 1, 1, 1, 54],
    [154, 35, 409, 22, 51, 3, 54],
    [154, 34, 411, 20, 52, 3, 54],
    [154, 34, 411, 16, 55, 8, 50],
    [155, 32, 413, 7, 1, 5, 56, 8, 51],
    [155, 31, 417, 2, 4, 2, 57, 8, 52],
    [156, 23, 487, 9, 53],
    [156, 23, 487, 6, 56],
    [156, 23, 424, 1, 63, 5, 56],
    [156, 23, 488, 4, 57],
    [156, 24, 424, 1, 55, 2, 4, 3, 59],
    [156, 18, 2, 3, 424, 3, 1, 4, 48, 5, 1, 3, 60],
    [157, 18, 428, 7, 48, 5, 2, 1, 62],
    [157, 1, 1, 16, 428, 7, 46, 6, 66],
    [157, 1, 2, 18, 425, 6, 46, 6, 67],
    [157, 2, 1, 18, 424, 6, 46, 6, 68],
    [160, 17, 425, 5, 45, 6, 70],
    [160, 16, 427, 2, 44, 8, 71],
    [160, 17, 470, 9, 21, 1, 50],
    [158, 1, 2, 16, 468, 8, 75],
    [159, 1, 1, 16, 466, 8, 77],
    [159, 16, 467, 8, 78],
    [159, 15, 466, 9, 79],
    [159, 15, 465, 8, 81],
    [158, 16, 467, 4, 83],
    [158, 17, 553],
    [161, 17, 550],
    [161, 18, 549],
    [161, 18, 549],
    [161, 18, 549],
    [161, 16, 551],
    [162, 15, 551],
    [162, 15, 551],
    [163, 14, 551],
    [163, 13, 552],
    [164, 12, 552],
    [165, 11, 552],
    [166, 11, 551],
    [167, 11, 550],
    [167, 12, 549],
    [168, 12, 548],
    [170, 10, 548],
    [171, 10, 547],
    [173, 10, 545],
    [175, 10, 543],
    [177, 10, 541],
    [181, 4, 543],
]

// Constants for the bitmap dimensions
const MAP_WIDTH = worldMapRLE[0].reduce((a, b) => a + b, 0)
const MAP_HEIGHT = worldMapRLE.length
