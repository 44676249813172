import React from "react"
import type { Progress } from "./Progress"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles } from "../ui"
import { ImageToCSS } from "../../reactor/Types/File"

RegisterWidget<Progress>("Progress", ({ value, tableCell }) => {
    const min = value.min !== undefined ? value.min : 0
    const max = value.max !== undefined ? value.max : 100
    const percent = Math.min(100, Math.max(0, (100 * (value.value - min)) / (max - min)))

    return (
        <div
            style={{
                margin: 8,
                marginLeft: tableCell ? 8 : 16,
                marginRight: tableCell ? 8 : 16,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontWeight: 500,
                fontSize: 14,
            }}
        >
            {value.thumbnail !== undefined || value.thumbnailUrl !== undefined ? (
                <div
                    style={{
                        width: 24,
                        height: 24,
                        backgroundImage: value.thumbnailUrl
                            ? `url('${value.thumbnailUrl.valueOf()}')`
                            : value.thumbnail
                              ? ImageToCSS(value.thumbnail)
                              : undefined,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: 12,
                        marginRight: 16,
                    }}
                />
            ) : undefined}

            <div style={{ flex: 1 }}>
                {value.label !== undefined ? (
                    <div style={{ marginBottom: 4 }}>{value.label}</div>
                ) : undefined}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: ColorStyles.gray[200],
                            width: "100%",
                            height: 8,
                            borderRadius: 4,
                        }}
                    >
                        <div
                            style={{
                                width: `${percent}%`,
                                height: "100%",
                                backgroundColor: value.color ?? ColorStyles.primary[700],
                                borderRadius: 4,
                            }}
                        />
                    </div>
                    {value.valueLabel !== undefined ? (
                        <div style={{ marginLeft: 8 }}>{value.valueLabel}</div>
                    ) : undefined}
                </div>
            </div>
        </div>
    )
})
