import React from "react"
import type { Empty } from "./Empty"
import { RegisterWidget, WidgetView } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<Empty>("Empty", ({ value, isRoot }) => (
    <div
        style={{
            fontSize: 14,
            fontWeight: 400,
            opacity: 0.5,
            ...(value.variant === "Error"
                ? {
                      opacity: 1,
                      backgroundColor: ColorStyles.error[100],
                      color: ColorStyles.error[600],
                      padding: 8,
                  }
                : undefined),
        }}
    >
        <WidgetView value={value.widget} isRoot={isRoot} />
    </div>
))
