import { Chart, Plugin } from "chart.js"

export function DoughnutChartWiskerLabels(color: string): Plugin<any> {
    return {
        id: "myPlugin",
        beforeDraw(chart: Chart<any>) {
            const {
                ctx,
                chartArea: { width, height, top, left },
            } = chart

            chart.data.datasets.forEach((dataset, i) => {
                const totalValue = dataset.data.reduce((a: number, b: any) => a + b, 0)
                const labels = chart.data.labels as string[]
                const meta = chart.getDatasetMeta(i)
                meta.data.forEach((datapoint, n) => {
                    const { x, y } = datapoint.tooltipPosition()

                    const proportion = (dataset.data[n] as number) / totalValue
                    if (proportion < 0.05) return

                    const cx = left + width / 2
                    const cy = top + height / 2

                    let rx = x - cx
                    let ry = y - cy
                    const r = Math.sqrt(rx * rx + ry * ry)
                    rx /= r
                    ry /= r

                    const l = x < cx
                    const dx = x > cx ? 1 : -1

                    const radialStart = 20
                    const radialEnd = 40
                    const horizontalExt = 20
                    const textPad = 5

                    ctx.fillStyle = color
                    ctx.lineWidth = 2
                    ctx.font = "16px Jost"

                    ctx.beginPath()
                    //ctx.ellipse(left + width / 2, top + height / 2, 2, 2, 0, 0, 2 * Math.PI)
                    ctx.moveTo(x + rx * radialStart, y + ry * radialStart)
                    ctx.lineTo(x + rx * radialEnd, y + ry * radialEnd)
                    const tx = x + rx * radialEnd + dx * horizontalExt
                    const ty = y + ry * radialEnd
                    ctx.lineTo(tx, ty)

                    fillTextWordWrapped(ctx, labels[n], tx, ty, l, textPad)

                    ctx.stroke()
                })
            })
        },
    }
}

function fillTextWordWrapped(
    ctx: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    /** Whether to render text to the left of the point. If false, it will
     * render to the right of the point. */
    left: boolean,
    /** Padding between the point and the text */
    textPad: number
) {
    const lineSpacing = 4
    const maxWidth = 50
    const words = text.split(" ")
    const lines: string[] = []
    let lineLength = 0
    let boxHeight = 0
    let maxLineLength = 0
    for (let i = 0; i < words.length; i++) {
        const word = words[i]
        const l = ctx.measureText(word)
        if (l.width > maxLineLength) maxLineLength = l.width

        if (lineLength + l.width > maxWidth) {
            lines.push(words.slice(0, i).join(" "))
            boxHeight += l.actualBoundingBoxAscent + lineSpacing
            words.splice(0, i)
            lineLength = 0
        } else {
            lineLength += l.width
        }
    }
    const lastLine = words.join(" ")
    const l = ctx.measureText(lastLine)
    if (l.width > maxLineLength) maxLineLength = l.width
    boxHeight += l.actualBoundingBoxAscent + lineSpacing

    lines.push(words.join(" "))

    let ypos = y - boxHeight / 2 - lineSpacing + 1
    for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        const l = ctx.measureText(line)
        ypos += l.actualBoundingBoxAscent + lineSpacing
        ctx.fillText(line, x - (left ? l.width + textPad : -textPad), ypos)
    }
}
