import React, { useState } from "react"
import { RegisterWidget } from "./WidgetView"
import { RButton } from "../../studio/Views/Buttons"
import type { CopyButton } from "./CopyButton"

RegisterWidget<CopyButton>("CopyButton", ({ value }) => {
    const [copied, setCopied] = useState(false)

    return (
        <RButton
            variant="secondary"
            icon={copied ? "thumbs-up" : "copy"}
            onClick={() => {
                void navigator.clipboard.writeText(value.value)
                setCopied(true)
                setTimeout(() => setCopied(false), 2000)
            }}
            style={{ margin: 0 }}
        >
            {copied ? "Copied!" : value.label}
        </RButton>
    )
})
