import moment from "moment"
import React from "react"
import { useParam } from "../../../packages/ssr/useParam"
import { Uuid } from "../../../reactor/Types/Primitives/Uuid"
import { useForm } from "./client"
import { ValueDisplay } from "./FormValueDisplay"
import { HeaderSection } from "./sections/Header"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"

export function FormReport() {
    const formId = useParam("formId") as any as Uuid<"Form">
    const { data: report } = useForm(formId ?? null)

    return (
        <div>
            <HeaderSection
                size="compact"
                logoText="ClimatePoint"
                title="Our contribution to the "
                image={report?.account.image as any}
            />
            <div className="container">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        marginTop: 32,
                        marginBottom: 32,
                    }}
                >
                    <img
                        src={report?.account.image?.valueOf()}
                        style={{ maxWidth: 64, maxHeight: 64, objectFit: "contain" }}
                    />
                    <h2 style={{ marginLeft: 32, flex: 1 }}>{report?.account.name}</h2>
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <h3 style={{ flex: 1 }}>{report?.template.name}</h3>
                    <div
                        style={{
                            fontSize: 12,
                            display: "flex",
                            alignItems: "flex-end",
                            flexDirection: "column",
                        }}
                    >
                        <div>Form provided by {report?.template.provider.name}</div>
                        <div>Created {moment(report?.createdAt).format("DD MMMM YYYY")}</div>
                        <div>
                            Last modified {moment(report?.lastModified).format("DD MMMM YYYY")}
                        </div>
                    </div>
                </div>
                <p>{report?.template.subTitle}</p>
                <MarkdownView value={report?.template.description} />
                <div style={{ marginTop: 32 }}>
                    {report?.template.fields.map((f) => (
                        <ValueDisplay obj={report.data[f.name]} f={f} />
                    ))}
                </div>
            </div>
        </div>
    )
}
