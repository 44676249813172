import { OpaqueString, RangedInteger } from "../Opaque"

/** The Month of the year as integers, 1 is january, 12 is december */
export type Month = RangedInteger<"Month", 1, 12>
export function Month(oneIndexedMonthNumber: number): Month {
    if (oneIndexedMonthNumber < 1) throw new Error("Month cannot be less than 1")
    if (oneIndexedMonthNumber > 12) throw new Error("Month cannot be greater than 12")
    return oneIndexedMonthNumber as any
}

/**
 * A month in a year
 * @example 2021-01
 * @example 2021-12
 */
export type YearMonth = OpaqueString<"YearMonth">
export function YearMonth(year: number, month: Month | number): YearMonth {
    return `${pad(year, 4)}-${pad(month as number)}` as any
}
/**
 * Returns the current month on this machine.
 */
YearMonth.thisMonth = function () {
    const now = new Date()
    return YearMonth(now.getFullYear(), Month(now.getMonth() + 1))
}

YearMonth.fromDateString = function (dateString: string): YearMonth {
    const [year, month] = dateString.split("-").map((v) => parseInt(v, 10))
    const scheduledAt = YearMonth(year, month)
    return scheduledAt
}

/**
 * The Day of the month as integers, 1 is the first day of the month, 31 is the last day of the month
 * @min 1
 * @max 31
 */
export type DayOfMonth = RangedInteger<"DayOfMonth", 1, 31>
export function DayOfMonth(num: number | string): DayOfMonth {
    if (typeof num === "string") num = Number.parseInt(num)
    if (num < 1) throw new Error("First day is 1")
    if (num > 31) throw new Error("Last day is 31")
    return num as any
}

/**
 * A date in a month in a year
 * @example 2021-01-22
 * @example 2021-12-01
 */
export type YearMonthDate = OpaqueString<"YearMonthDate">
export function YearMonthDate(
    year: number,
    month: Month | number,
    day: DayOfMonth | number
): YearMonthDate {
    return `${pad(year, 4)}-${pad(month as number)}-${pad(day as number)}` as any
}

YearMonthDate.fromDateString = function (dateString: string) {
    const [year, month, day] = dateString.split("-").map((v) => parseInt(v, 10))
    const scheduledAt = YearMonthDate(year, month, day)
    return scheduledAt
}

function pad(n: number, minDigits = 2) {
    return `${n}`.padStart(minDigits, "0")
}
