import { useContext, useState } from "react"
import { ObjectPropView } from "./ObjectView"
import { StringView } from "./StringView"
import { RefView } from "./RefView"
import { BooleanView } from "./BooleanView"
import { UnionView } from "./UnionView"
import { PropRow } from "./PropRow"
import { ToolButton } from "./ToolButton"
import { useFindSessionMethods } from "./SessionContext"
import { RButton } from "./Buttons"
import { LocalizedView } from "./LocalizedView"
import { FileView } from "./FileView"
import { ColorView } from "./ColorView"
import { ListPropView } from "./ListView"
import { TypeScriptView } from "./TypeScriptView"
import { CollapseContext } from "./ListContext"
import { WidgetView } from "../../packages/widgets/WidgetView"
import { useObjectPath } from "./ObjectContext"
import {
    AreTypesAssignable,
    GetTypeAlias,
    IsAnyType,
    IsArrayType,
    IsBooleanType,
    IsIntersectionType,
    IsNumberOrUndefinedType,
    IsNumberType,
    IsObjectType,
    IsStringLiteral,
    IsStringType,
    IsTypeOrUndefined,
    IsUnionType,
    MatchesType,
    Property,
    TypeToString,
    UndefinedType,
    UnionType,
} from "../../reactor/Types/Type"
import { GetFileTypeMimeTypes } from "../../reactor/Types/File"
import { Strings } from "../../packages/localization/client-side/Dictionary"
import { RecordView } from "./RecordView"
import { useEditableContext } from "../../packages/editing/EditableContext"
import { GetType } from "../../reactor"
import { useDocumentWidgets } from "./DocumentContext"

export type PropViewMode = "root" | "card" | "inline" | "card-no-header"

export function PropView<T>({
    obj,
    label,
    mode,
    buttons,
    isEmbedded,
    property,
    bubbleButtons,
}: {
    obj: any
    property: Property
    mode: PropViewMode
    label?: string
    section?: string
    buttons: ToolButton[]
    isEmbedded?: boolean
    /** If provided, the buttons intended for this prop is bubbled up to the
     * parent. This is to avoid multiple button sets for the same logical
     * property. */
    bubbleButtons?: (b: ToolButton[]) => void
}) {
    const name = property.name
    const type = property.type
    const optional = property.optional
    const value = obj[name]
    const shallowLocked = property.tags?.shallowLocked

    const ec = useEditableContext()
    const widgets = useDocumentWidgets()

    const optionalAndUndefined =
        (optional || AreTypesAssignable(type, UndefinedType)) && value !== undefined
    const noClearButton = !buttons.some((x) => x.text === "Clear")

    if (optionalAndUndefined && noClearButton && !shallowLocked) {
        buttons.unshift({
            text: "Clear",
            onClick() {
                obj[name] = undefined
                ec.invalidate()
            },
        })
    }
    if (bubbleButtons) {
        bubbleButtons(buttons)
        buttons = []
    }

    const match = MatchesType(value, type) === true
    const compactState = useState(match ? true : undefined)
    let compact = compactState[0]
    const setCompact = compactState[1]

    // If we started as expanded because match was false, but it later became
    // true, lets compact by default
    if (match && compact === undefined) {
        setCompact(true)
        compact = true
    }

    const alias = GetTypeAlias(type)
    const collapse = useContext(CollapseContext)
    const path = useObjectPath()

    useFindSessionMethods()(type, name).forEach((m) => {
        buttons.unshift({
            primary: true,
            onClick() {
                m.call()
                collapse?.collapse()
            },
            variant: "secondary",
            text: m.description,
        })
    })

    /** Whether to use widget representation, if available */
    const [widgetMode, setWidgetMode] = useState(true)

    function findWidget() {
        if (typeof property.type === "object") {
            const widget = property.type.tags?.widget

            if (widget) {
                let w = widgets
                for (const p of path) {
                    if (!w) break
                    w = w[p] as any
                }
                w = w && (w[property.name] as any)
                w = w && (w[widget] as any)
                return w
            }
        }
    }

    const typeProp = property.tags?.typeField
    if (typeProp) {
        const typeName = obj[typeProp]
        const type = GetType(typeName)

        return (
            <ObjectPropView
                obj={obj}
                property={{ name: property.name, type, tags: property.tags }}
                buttons={buttons}
                label={label}
                isEmbedded={true}
            />
        )
    }

    const widget = findWidget()

    if (widget && widgetMode)
        return (
            <div style={{ position: "relative", margin: 16 }}>
                <WidgetView value={widget} obj={obj} property={property} />

                <RButton
                    onClick={() => setWidgetMode(false)}
                    variant="secondary"
                    icon="edit"
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                    }}
                >
                    {Strings.Edit}
                </RButton>
            </div>
        )

    if (widget) {
        const text = Strings["Done editing"]
        if (!buttons.some((b) => b.text === text)) {
            buttons.push({
                text,
                onClick() {
                    setWidgetMode(true)
                },
            })
        }
    }

    if (alias && PropView.componentOverride.has(alias)) {
        const Comp = PropView.componentOverride.get(alias)

        if (Comp) {
            return (
                <Comp
                    obj={obj}
                    prop={name}
                    label={label}
                    buttons={buttons}
                    description={property.description}
                />
            )
        }
    }
    if (alias === "Record") {
        return <RecordView obj={obj} property={property} label={label} buttons={buttons} />
    }

    if (alias === "TypeScript") {
        return <TypeScriptView obj={obj} label={label} property={property} />
    }
    // Don't show color picker if choosing from a predefined set of colors.
    // TODO: Implement support for color picker with a predefined set of colors.
    if (alias === "Color" && !IsUnionType(type)) {
        return (
            <ColorView
                label={label}
                obj={obj}
                property={property}
                buttons={buttons}
                isEmbedded={isEmbedded}
            />
        )
    }

    if (alias === "Localized") {
        return (
            <LocalizedView
                obj={obj}
                label={label}
                property={property}
                buttons={buttons}
                isEmbedded={isEmbedded}
                mode={mode}
            />
        )
    }
    if (GetFileTypeMimeTypes(type).length > 0) {
        return (
            <FileView
                obj={obj}
                label={label}
                property={property}
                buttons={buttons}
                icon="image"
                isEmbedded={isEmbedded}
            />
        )
    }

    if (typeof property.type === "object" && property.type.reference && !IsArrayType(type)) {
        return (
            <RefView
                buttons={buttons}
                obj={obj}
                property={property}
                label={label}
                isEmbedded={isEmbedded}
            />
        )
    }

    const primary = buttons.find((x) => x.primary)

    if (IsStringType(type) && primary && typeof value === "string" && property.isReadonly) {
        return (
            <PropRow
                isReadonly={true}
                label={label}
                buttons={buttons}
                description={property.description}
            >
                <RButton
                    variant="link"
                    onClick={primary.onClick}
                    chevron={
                        primary.expanded === undefined
                            ? undefined
                            : primary.expanded
                              ? "chevron-up"
                              : "chevron-down"
                    }
                >
                    {value}
                </RButton>
            </PropRow>
        )
    }

    if (IsStringLiteral(type) && property.optional) {
        return (
            <UnionView
                mode={mode}
                obj={obj}
                property={{ ...property, type: UnionType(type, UndefinedType) }}
                label={label}
                buttons={buttons}
                isEmbedded={isEmbedded}
            />
        )
    }

    const scalarOrUndefined =
        (optional && IsStringType(type)) ||
        (optional && IsNumberType(type)) ||
        IsTypeOrUndefined(type, IsStringType) ||
        IsNumberOrUndefinedType(type)
    if (scalarOrUndefined) {
        return (
            <StringView
                obj={obj}
                property={property}
                label={label}
                buttons={buttons}
                isEmbedded={isEmbedded}
            />
        )
    } else if (IsStringType(type) || IsNumberType(type)) {
        return (
            <StringView
                obj={obj}
                property={property}
                label={label}
                buttons={buttons}
                isEmbedded={isEmbedded}
            />
        )
    } else if (IsUnionType(type)) {
        return (
            <UnionView
                mode={mode}
                obj={obj}
                property={property}
                label={label}
                buttons={buttons}
                isEmbedded={isEmbedded}
            />
        )
    } else if (IsBooleanType(type)) {
        return <BooleanView obj={obj} property={property} label={label} buttons={buttons} />
    } else if (IsArrayType(type)) {
        return (
            <ListPropView
                property={property}
                obj={obj}
                buttons={buttons}
                label={label}
                isRoot={false}
            />
        )
    } else if (IsObjectType(type) || IsIntersectionType(type)) {
        return (
            <ObjectPropView
                property={property}
                obj={obj}
                label={label}
                buttons={buttons}
                isEmbedded={!!isEmbedded}
            />
        )
    } else if (IsAnyType(type)) {
        return <TypeScriptView mode="json-stringify" property={property} label={label} obj={obj} />
    }
    return <>Unrecognized type: {TypeToString(type)}</>
}

PropView.componentOverride = new Map<string, (obj: any) => JSX.Element>()
