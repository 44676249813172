import { ChartConfiguration, Plugin } from "chart.js"
import React, { useContext, useRef } from "react"
import { ImageToUrl } from "../../../../reactor/Types/File"
import { AccountContext } from "../AccountPage"
import { colors } from "../styles"
import { ChartBase, GetChartDataset } from "./ChartBase"
import { ChartCommon } from "./ChartCommon"
import { Section } from "../../../../packages/editing/Section"

Section(BarChart)
/**
 * @zoom 2.5
 * @icon chart-bar
 */
function BarChart(section: ChartCommon) {
    const account = useContext(AccountContext)
    const { values, unit } = GetChartDataset(section, account)
    const imageRefs = new Array(25).fill(0).map((v) => useRef<HTMLImageElement>(null))
    const valueRef = useRef(values)
    Object.assign(valueRef.current, values)

    const icons: Plugin = {
        id: "iconPlugin",
        beforeDraw(chart) {
            const { ctx } = chart
            const v = valueRef.current

            chart.data.datasets.forEach((dataset, i) => {
                const meta = chart.getDatasetMeta(i)
                meta.data.forEach((datapoint, n) => {
                    const { x, y } = datapoint.tooltipPosition()

                    ctx.fillStyle = colors.foreground
                    ctx.lineWidth = 2
                    ctx.font = "16px Jost"
                    const icon = v[n].icon
                    if (icon) {
                        const img = imageRefs[n].current
                        if (img) ctx.drawImage(img, x - 32, y - 64 - 8)
                    } else {
                        ctx.fillStyle = v[n].customColor ?? colors[v[n].brandColor]
                        const w = ctx.measureText(v[n].label).width
                        ctx.fillText(v[n].label, x - w / 2, y - 16)
                    }
                })
            })
        },
    }

    const config: ChartConfiguration<"bar"> = {
        type: "bar",
        data: {
            labels: values.map((v) => v.label),
            datasets: [
                {
                    data: values.map((v) => v.value),
                    backgroundColor: values.map((v) => v.customColor ?? colors[v.brandColor]),
                    hoverOffset: 4,
                    radius: 100,
                } as any,
            ],
        },
        options: {
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label(item) {
                            return `${item.label}: ${item.formattedValue} ${unit || ""}`
                        },
                    },
                },
            },
            layout: {
                padding: {
                    top: values.some((v) => !!v.icon) ? 64 : 0,
                },
            },
            scales: {
                x: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                },
                y: {
                    display: false,
                    grid: {
                        display: false,
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                },
            },
            aspectRatio: 1.5,
        },
        plugins: [icons],
    }

    return (
        <div style={{ position: "relative" }}>
            {values.length < 9 &&
                values.map((v, i) => (
                    <img
                        ref={imageRefs[i]}
                        style={{ display: "none", width: 64 }}
                        src={ImageToUrl(v.icon as any, { width: 64 })}
                    />
                ))}

            <ChartBase config={config} section={{ ...section, values }} />
        </div>
    )
}
