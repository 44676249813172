import React, { useCallback, useRef } from "react"
import Webcam, { WebcamProps } from "react-webcam"
import { RButton } from "./Buttons"
import { DateString } from "../../reactor/Types/Primitives"

export const cameraWidth = 640
export const cameraHeight = 360

export const videoConstraints = {
    width: cameraWidth,
    height: cameraHeight,
    facingMode: "environment",
}

type SupportedImageType = "image/png" | "image/webp" | "image/jpeg" | undefined

export type CameraProps = {
    /** React setState function */
    setImage?: (newState: string) => void
    /** Function for uploading the image file */
    uploadFile?: (file: File) => Promise<void>
    /** Name of image file without extension */
    fileName?: string
    /** Image filetype */
    imageType?: SupportedImageType
    /** Overridable react-webcam properties */
    webcamProps?: Partial<WebcamProps>
}

export function Camera({ setImage, uploadFile, fileName, imageType, webcamProps }: CameraProps) {
    if (imageType === undefined) imageType = "image/png"
    const fileEnding = imageType ? imageType.split("/")[1] : "png"
    const webcamRef = useRef<Webcam>(null)

    const capture = useCallback(async () => {
        const image = webcamRef.current?.getScreenshot()

        if (!image) return

        if (setImage && image !== null) setImage(image)

        if (uploadFile && image !== null) {
            const timeNow = DateString.toLocal(DateString(), "minute")
            if (fileName !== undefined) fileName = `${fileName}.${fileEnding}`
            else fileName = `image_captured_${timeNow}.${fileEnding}`
            const b64Data = image.split(",")[1]
            const blob = b64toBlob(b64Data, imageType)
            const file = new File([blob], fileName, { type: imageType })
            await uploadFile(file)
        }
    }, [])

    return (
        <>
            <Webcam
                ref={webcamRef}
                audio={false}
                height={cameraHeight}
                screenshotFormat={imageType}
                width="100%"
                videoConstraints={videoConstraints}
                style={{ objectPosition: "left" }}
                {...webcamProps}
            />

            <RButton variant="primary" style={{ width: 200, marginTop: 16 }} onClick={capture}>
                Capture photo
            </RButton>
        </>
    )
}

// Source: https://stackoverflow.com/a/16245768
function b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
}
