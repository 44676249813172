import { useEffect, useState } from "react"

/** Returns whether the provided element intersects the current viewport. */
export function useViewportIntersects(
    element: HTMLElement | null,
    /** Whether it should only flip to true once, and stay true.
     *
     *  If false, it will flip to false when the element is no longer visible.
     */
    once = false
) {
    const [intersects, setIntersects] = useState(false)
    useEffect(() => {
        if (element) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIntersects(true)
                    } else {
                        if (!once) {
                            setIntersects(entry.isIntersecting)
                        }
                    }
                })
            })
            observer.observe(element)
            return () => {
                observer.disconnect()
            }
        }
    }, [element])

    return intersects
}
