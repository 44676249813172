import React from "react"
import type { Marked, MarkedProps } from "./Marked"
import { RegisterWidget } from "./WidgetView"

RegisterWidget<Marked>("Marked", ({ value }) => <MarkedWidget {...value} />)

function regExpEscape(literal_string: string) {
    // eslint-disable-next-line no-useless-escape
    return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, "\\$&")
}

export function MarkedWidget({ text, mark }: MarkedProps) {
    return (
        <div
            style={{ fontSize: 14, padding: 3 }}
            dangerouslySetInnerHTML={{
                __html: text.replace(
                    new RegExp(regExpEscape(mark), "i"),
                    (match) => `<mark style="margin: 0; padding: 0;">${match}</mark>`
                ),
            }}
        />
    )
}
