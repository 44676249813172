import React, { useContext } from "react"
import { Row, Col } from "react-bootstrap"
import { EditableText } from "../../../../packages/editing/EditableText"
import { ImageAndTextSection } from "./ImageAndText"
import { colors } from "../styles"
import { AccountContext } from "../AccountPage"
import { Markdown } from "../../../../reactor/Types/Primitives/Markdown"
import { Section } from "../../../../packages/editing/Section"

const percentWithTwoDigits = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})

Section(AccountInfo)
function AccountInfo(section: {}) {
    const solution = useContext(AccountContext)
    if (!solution) return <></>

    return (
        <>
            {ImageAndTextSection({
                get body(): Markdown {
                    return solution.description ?? Markdown("")
                },
                set body(v) {
                    solution.description = v
                },
                get header() {
                    return solution.name
                },
                set header(v) {
                    solution.name = v
                },
                image: solution.image || solution.thumbnail || ("" as any),
                imagePosition: "left",

                info: (
                    <div>
                        <div>
                            {solution.flag} {solution.address}, {solution.country}
                        </div>
                        <a href={solution.website?.toString()}>{solution.website}</a>
                    </div>
                ),
            })}

            {solution.funds?.map((fund, i) => (
                <ImageAndTextSection
                    key={fund.id.valueOf()}
                    body={Markdown(
                        `${solution.name} is part of the ${fund.name} portfolio. We are proud to say it makes up the following:`
                    )}
                    header={fund.name}
                    image={fund.image || fund.thumbnail || ("" as any)}
                    imagePosition={i % 2 ? "left" : "right"}
                    callToAction={{
                        link: "/funds/" + (fund.slug || fund.id),
                        text: "Explore the portfolio",
                    }}
                    info={
                        <Row style={{ marginBottom: 48 }}>
                            <Col>
                                <div
                                    style={{
                                        color: colors.darkGreen,
                                        fontSize: 36,
                                        fontWeight: "bold",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <EditableText
                                        obj={fund}
                                        prop="percentOfFund"
                                        defaultText="1.22"
                                        numberFormat={percentWithTwoDigits}
                                    />
                                    %
                                </div>
                                <span style={{ fontWeight: 200 }}>share of </span>
                                {fund.name}
                            </Col>
                            <Col style={{ marginLeft: 48 }}>
                                <div
                                    style={{
                                        color: colors.darkGreen,
                                        fontSize: 36,
                                        fontWeight: "bold",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <EditableText
                                        obj={fund}
                                        prop="percentOfTotalInvestments"
                                        defaultText="0.42"
                                        numberFormat={percentWithTwoDigits}
                                    />
                                    %
                                </div>
                                <span style={{ fontWeight: 200 }}>share of </span>
                                ClimatePoint investments
                            </Col>
                        </Row>
                    }
                />
            ))}
        </>
    )
}
