import React from "react"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles } from "../ui"
import { Link } from "./Link"
import { Icon } from "../../studio/Views/Icon"

RegisterWidget<Link>("Link", ({ value }) => <LinkWidget {...value} />)

export function LinkWidget({ text, url, openInNewTab, icon = "ui-arrow-up-right" }: Link) {
    return (
        <a
            href={url}
            target={openInNewTab ? "_blank" : undefined}
            rel="noreferrer"
            style={{
                color: ColorStyles.primary[600],
            }}
        >
            {text} <Icon icon={icon} width={20} height={20} style={{ marginTop: -4 }} />
        </a>
    )
}
