import { useBoundState } from "../../packages/editing/useBoundState"
import { useEditableContext } from "../../packages/editing/EditableContext"
import { useStudioLocale } from "../../packages/localization/client-side/useStudioLocale"
import { LocaleKey } from "../../packages/localization/Locale"
import { IsLocalized } from "../../packages/localization/Localized"
import { ColorStyles } from "../../packages/ui"
import { CreateDefault } from "../../reactor"
import { prettyCamel } from "../../reactor/Helpers"
import {
    GetTypeAlias,
    GetTypeProps,
    IsArrayType,
    IsStringType,
    Property,
} from "../../reactor/Types/Type"
import { RButton } from "./Buttons"

export function GridView({ property, obj }: { property: Property; obj: any }) {
    const [value, setValue] = useBoundState(obj, property.name)
    const ec = useEditableContext()

    if (!(value instanceof Array)) {
        return <div>Expected array, got {JSON.stringify(value)}</div>
    }
    const arrayType = property.type
    if (!IsArrayType(arrayType)) {
        return <div>Expected array type, got {JSON.stringify(arrayType)}</div>
    }
    const itemType = arrayType.array

    const columns = GetTypeProps(itemType).filter((prop) => {
        if (GetTypeAlias(prop.type) === "Uuid") return false

        const type = IsLocalized(prop.type) ?? prop.type
        if (IsStringType(type)) return true
        return false
    })

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(" + columns.length + ", 1fr) auto",
                border: "1px solid " + ColorStyles.gray[200],
                borderRadius: 8,
            }}
        >
            {columns.map((column) => (
                <div
                    key={column.name}
                    style={{
                        borderBottom: "1px solid " + ColorStyles.gray[200],
                        padding: 8,
                        backgroundColor: ColorStyles.gray[100],
                    }}
                >
                    {prettyCamel(column.name, true)}
                </div>
            ))}

            <div
                key="deletebutton-placeholder"
                style={{
                    borderBottom: "1px solid " + ColorStyles.gray[200],
                    padding: 8,
                    backgroundColor: ColorStyles.gray[100],
                }}
            />

            {value.map((row: any) => (
                <>
                    {columns.map((column) => (
                        <div
                            key={column.name}
                            style={{
                                borderBottom: "1px solid " + ColorStyles.gray[200],
                                paddingRight: 2,
                            }}
                        >
                            <Cell obj={row} property={column} />
                        </div>
                    ))}
                    <div
                        key="deletebutton"
                        style={{
                            borderBottom: "1px solid " + ColorStyles.gray[200],
                        }}
                    >
                        <RButton
                            icon="ui-minus"
                            variant="secondary"
                            onClick={() => {
                                const index = value.indexOf(row)
                                value.splice(index, 1)
                                ec?.invalidate()
                            }}
                        />
                    </div>
                </>
            ))}
            <RButton
                style={{ gridColumn: "span " + (columns.length + 1) }}
                icon="ui-plus"
                variant="secondary"
                onClick={() => {
                    value.push(CreateDefault(itemType))
                    setValue([...value])
                }}
            >
                Add
            </RButton>
        </div>
    )
}

function Cell({ property, obj }: { property: Property; obj: any }) {
    const locale = useStudioLocale()
    const ec = useEditableContext()

    function getLocale(v: any) {
        if (locale.valueOf() in v) return locale
        if ("en" in v) return LocaleKey.en
        return Object.keys(v)[0] as any as LocaleKey
    }

    function getValue() {
        const v = obj[property.name]

        if (IsLocalized(property.type)) {
            return v[getLocale(v).valueOf()]
        }

        return v
    }
    function setValue(newValue: any) {
        if (IsLocalized(property.type)) {
            const v = obj[property.name]
            v[getLocale(v).valueOf()] = newValue
            return
        }

        obj[property.name] = newValue
        ec.invalidate()
    }

    return (
        <input
            type="text"
            style={{ padding: 8, width: "100%", boxSizing: "border-box" }}
            value={getValue()}
            onChange={(e) => {
                setValue(e.target.value)
            }}
        />
    )
}
