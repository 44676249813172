import { Image, ImageToCSS, Markdown, Uuid } from "../../../../reactor"
import { useContext, useState } from "react"
import { colors, headerStyle } from "../styles"
import { useClickableCard } from "../useClickableCard"
import { Grid } from "../Grid"
import { SolutionPopup, useSolutionPopup } from "../SolutionPopup"
import { GetSolutionsSolutionsDto, useSolutions } from "../client"
import { EditableImage } from "../../../../packages/editing/EditableImage"
import { EditableText } from "../../../../packages/editing/EditableText"
import { useNavigate } from "../../../../packages/hooks/useNavigate"
import { useIsEditing } from "../../../../packages/editing/EditableContext"
import { Modal } from "../../../../packages/modal/Modal"
import { useHover } from "../../../../packages/hooks/useHover"
import { useQueryString } from "../../../../reactor/Web"
import { useAppearAnimation } from "../../../../packages/appear-animation/useAppear"
import { AccountContext } from "../AccountPage"
import { Section } from "../../../../packages/editing/Section"
import { ImageAndTextSection } from "./ImageAndText"

Section(SolutionsSection)
function SolutionsSection(section: { title: string }) {
    const { ref, style } = useAppearAnimation()
    const [fundFilter, setFundFilter] = useState<Uuid<"Fund"> | undefined>(undefined)
    const [sdgFilter, setSDGFilter] = useQueryString("sdg")
    const [nameSearch, setNameSearch] = useState<string | undefined>(undefined)
    const [sortBy, setSortBy] = useState<"Name" | "Size of placement" | "Country">("Name")
    const account = useContext(AccountContext)

    const { data } = useSolutions(
        fundFilter,
        sdgFilter ? Uuid<"FrameworkElement">(sdgFilter) : undefined,
        nameSearch,
        account?.id,
        sortBy
    )

    return (
        <div className="container" ref={ref} style={{ ...style, marginTop: 32, minHeight: 720 }}>
            {data?.sdg && data?.sdg.image && data?.sdg?.shortDescription && (
                <ImageAndTextSection
                    isSdg={true}
                    image={data.sdg.image as any}
                    header={data.sdg.name}
                    body={data.sdg.shortDescription as any}
                    imagePosition={"right"}
                />
            )}

            <EditableText
                obj={section}
                prop="title"
                style={{
                    ...headerStyle,
                    marginBottom: 16,
                    width: "100%",
                }}
            />
            <div
                style={{
                    marginBottom: 32,
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}
            >
                <input
                    type="text"
                    placeholder="Search by name"
                    value={nameSearch}
                    onChange={(e) => setNameSearch(e.target.value)}
                    style={{
                        padding: 8,
                        borderRadius: 8,
                        marginRight: 8,
                        border: "1px solid #ddd",
                    }}
                />
                <FilterButton
                    text={
                        fundFilter
                            ? data?.funds.find((f) => f.id === fundFilter)?.name ?? "?"
                            : "Filter by fund"
                    }
                    bg={fundFilter ? colors.darkGreen : colors.lightGreen}
                    fg={fundFilter ? "white" : colors.green}
                    icon={fundFilter ? "times" : "plus"}
                    onClick={() => {
                        if (fundFilter) setFundFilter(undefined)
                        else if (data) void FilterModal(data.funds, setFundFilter)
                    }}
                />
                <FilterButton
                    text={
                        sdgFilter
                            ? data?.frameworkElements.find((f) => f.id.valueOf() === sdgFilter)
                                  ?.name ?? "?"
                            : "Filter by SDG"
                    }
                    bg={sdgFilter ? colors.darkGreen : colors.lightGreen}
                    fg={sdgFilter ? "white" : colors.green}
                    icon={sdgFilter ? "times" : "plus"}
                    onClick={() => {
                        if (sdgFilter) setSDGFilter(undefined)
                        else if (data)
                            void FilterModal(data.frameworkElements, (s) =>
                                setSDGFilter(s.valueOf())
                            )
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: 16,
                    }}
                >
                    Sort by:
                    <select
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value as any)}
                        style={{
                            border: "none",
                            backgroundColor: colors.lightGreen,
                            padding: 8,
                            marginLeft: 8,
                            borderRadius: 8,
                        }}
                    >
                        <option value="Name">Name</option>
                        <option value="Size of placement">Size of placement</option>
                        <option value="Country">Country</option>
                    </select>
                </div>

                <div style={{ marginLeft: 16 }}>{`${
                    data?.solutions?.length || 0
                } solutions found`}</div>
            </div>

            <Grid>
                {data?.solutions?.length === 0 ? "No solutions matching your filter" : undefined}
                {data?.solutions?.map((s, i) => (
                    <SolutionView
                        key={s.id.toString()}
                        solution={s}
                        index={i % 3}
                        maxBodyLength={200}
                        link={"/accounts/" + (s.slug || s.id)}
                        obj={{
                            get image() {
                                return s.image
                            },
                            set image(img) {
                                s.image = img
                            },
                            get title() {
                                return s.name
                            },
                            set title(t) {
                                s.name = t
                            },
                            get body() {
                                return s.shortDescription || s.description
                            },
                            set body(b) {
                                if (s.shortDescription) {
                                    s.shortDescription = b
                                } else {
                                    s.description = b
                                }
                            },
                        }}
                    />
                ))}
            </Grid>
        </div>
    )
}

function FilterModal<T extends string>(
    items: { id: Uuid<T>; name: string; thumbnail?: string | Image }[],
    onSelect: (id: Uuid<T>) => void
) {
    return Modal((close) => {
        return (
            <div
                style={{
                    backgroundColor: "white",
                    padding: 8,
                    borderRadius: 8,
                    maxHeight: 400,
                    width: 500,
                    overflowY: "scroll",
                }}
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <FilterButton text="Cancel" onClick={() => close(undefined)} icon="times" />
                </div>
                {items.map((f) => (
                    <FilterButton
                        onClick={() => {
                            onSelect(f.id)
                            close(f.id)
                        }}
                        bg="white"
                        thumbnail={f.thumbnail?.valueOf()}
                        text={f.name}
                    />
                ))}
            </div>
        )
    })
}

function FilterButton(props: {
    text: string
    icon?: string
    thumbnail?: string
    bg?: string
    fg?: string
    onClick: () => void
}) {
    const { hover, hoverProps } = useHover()
    return (
        <div
            {...hoverProps}
            onClick={props.onClick}
            style={{
                backgroundColor: hover ? "#ddd" : props.bg ?? colors.lightGreen,
                padding: 8,
                borderRadius: 8,
                paddingLeft: 16,
                paddingRight: 16,
                color: props.fg ?? colors.green,
                fontWeight: 500,
                marginRight: 16,
                cursor: "pointer",
                margin: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            {props.icon && <i className={`fas fa-${props.icon}`} style={{ width: 32 }} />}
            {props.thumbnail && (
                <div
                    style={{
                        width: 32,
                        height: 32,
                        borderRadius: 32,
                        marginRight: 12,
                        backgroundImage: ImageToCSS(props.thumbnail),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                />
            )}
            {props.text}
        </div>
    )
}

export function SolutionView({
    link,
    index,
    obj,
    width,
    maxBodyLength,
    transform,
    solution,
}: {
    link?: string
    index: number
    width?: number
    maxBodyLength?: number
    transform?: string
    solution?: GetSolutionsSolutionsDto
    obj: { image?: string | Image; title: string; body?: Markdown }
}) {
    const { ref, style } = useAppearAnimation({
        delayMs: 100 + index * 100,
        durationMs: 300,
        transform: transform || "translateX(-50px)",
    })
    const navigate = useNavigate()
    const { props, hover, setHover } = useClickableCard(style)
    const { ref: popupRef, open: openPopup } = useSolutionPopup()
    const editing = useIsEditing()

    return (
        <div
            {...props}
            ref={ref}
            onClick={() => {
                if (solution) {
                    if (!editing) openPopup()
                    setHover(false)
                } else if (link) {
                    navigate(link)
                }
            }}
            style={{
                minWidth: 320,
                display: "flex",
                overflow: "hidden",
                flexDirection: "column",
                borderRadius: 16,
                color: colors.foreground,
                marginBottom: 48,
                fontSize: 20,
                position: "relative",
                ...props.style,
            }}
        >
            {solution && (
                <SolutionPopup
                    ref={popupRef}
                    solutionId={solution.id}
                    onClose={() => setHover(false)}
                />
            )}
            <EditableImage
                obj={obj}
                prop="image"
                options={{ width }}
                style={{
                    borderRadius: 16,
                    backgroundSize: "cover",
                    filter: hover
                        ? "saturate(105%) brightness(105%)"
                        : "saturate(95%) brightness(95%)",
                    transition: "all 0.3s ease",
                    minHeight: 220,
                    backgroundPosition: "center",
                }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    paddingTop: 16,
                }}
            >
                <EditableText
                    obj={obj}
                    prop="title"
                    style={{
                        fontSize: 24,
                        marginBottom: 8,
                        fontWeight: "bold",
                        transition: "all 0.08s ease",
                        color: hover ? colors.mediumGreen : colors.foreground,
                    }}
                />
                <div
                    style={{
                        flex: 1,
                        fontSize: 20,
                    }}
                >
                    <EditableText
                        obj={obj}
                        prop="body"
                        isMarkdown={true}
                        maxLength={maxBodyLength}
                    />
                </div>
            </div>
        </div>
    )
}
