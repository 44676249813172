import { useContext, createContext } from "react"
import { ClassDesc, Method, AreTypesAssignable, Type, prettyCamel } from "../../reactor"
import type { ClassMethodArgument } from "../API/Session"
import { useFindParentObjectPath, useObjectPath } from "./ObjectContext"

export const SessionContext = createContext<{
    desc: ClassDesc
    callMethod(methodName: string, args: ClassMethodArgument[]): Promise<any>
} | null>(null)

export type ClassMethod = Method & { description: string; call(): void }

export function useFindSessionMethods() {
    const cl = useContext(SessionContext)
    const findParentObject = useFindParentObjectPath()
    const pathBase = useObjectPath()

    return (type: Type, ...props: (string | number)[]) => {
        const path = [...pathBase, ...props]
        const methods: ClassMethod[] = []
        if (cl) {
            cl.desc.methods.forEach((m) => {
                let selfUsed = false
                let allArgsMatched = true
                const context: (string | number)[] = []
                const args = m.args.map((a) => {
                    if (AreTypesAssignable(a.type, type)) {
                        selfUsed = true
                        return { path }
                    } else {
                        const parentPath = findParentObject(a.type)

                        if (parentPath) {
                            context.push(parentPath.join("."))
                            return { path: parentPath }
                        }
                    }
                    allArgsMatched = false
                })

                if (selfUsed && allArgsMatched && args.length) {
                    methods.push({
                        ...m,
                        call() {
                            // tslint:disable-next-line:no-floating-promises
                            void cl.callMethod(m.name, args as ClassMethodArgument[])
                        },
                        description:
                            prettyCamel(m.name) +
                            (context.length ? " (" + context.join(", ") + ")" : ""),
                    })
                }
            })
        }
        return methods
    }
}
