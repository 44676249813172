import React from "react"
import { headerStyle } from "./styles"
import { Avatar } from "../../../studio/Views/Avatar"
import { Slide } from "./Slide"
import moment from "moment"
import { useParam } from "../../../packages/ssr/useParam"
import { useArticle } from "./client"
import { EditableImage } from "../../../packages/editing/EditableImage"
import { EditableText } from "../../../packages/editing/EditableText"
import { Footer } from "./sections/Footer"
import { NewsSectionView } from "./sections/News"
import { HeaderSection } from "./sections/Header"

export function ArticlePage() {
    const slug = useParam("slug")
    const { data: news } = useArticle(slug)
    if (!news) return <></>

    return (
        <Slide style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <HeaderSection size="compact" />

            <div
                className="container"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 815,
                    justifyContent: "center",
                    marginBottom: 64,
                }}
            >
                <EditableImage
                    obj={news}
                    prop="image"
                    options={{ width: 815 }}
                    style={{
                        marginTop: 64,
                        height: 375,
                        maxWidth: 815,
                        borderRadius: 16,
                        backgroundSize: "cover",
                    }}
                />
                <EditableText
                    obj={news}
                    prop="title"
                    style={{
                        ...headerStyle,
                        textAlign: "left",
                        marginLeft: 0,
                        marginBottom: 16,
                        marginTop: 32,
                    }}
                />
                <EditableText
                    obj={news}
                    prop="ingress"
                    style={{ fontWeight: "bold", fontSize: 20 }}
                />
                <div style={{ display: "flex", justifyContent: "center", marginTop: 64 }}>
                    <div style={{ maxWidth: 640 }}>
                        {news.author && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 16,
                                }}
                            >
                                <div style={{ marginRight: 8 }}>By </div>
                                <Avatar user={news.author as any} size={32} />
                                <div
                                    style={{
                                        marginLeft: 8,
                                        color: news.author?.avatar?.color,
                                    }}
                                >
                                    {news.author.givenName && news.author.familyName
                                        ? news.author.givenName + " " + news.author.familyName
                                        : news.author.email}
                                </div>
                            </div>
                        )}
                        <div style={{ marginBottom: 32, fontWeight: 700, color: "#444" }}>
                            {news.publishedDate &&
                                moment(news.publishedDate.toString()).format(
                                    "dddd MMM Do YYYY hh:mm"
                                )}
                        </div>
                        <EditableText
                            obj={news}
                            prop="body"
                            isMarkdown={true}
                            style={{ fontWeight: 300, fontSize: 20 }}
                        />
                    </div>
                </div>
            </div>
            <NewsSectionView
                filter={(n) => n.id !== news.id}
                section={{ title: "Other Articles" }}
            />
            <Footer />
        </Slide>
    )
}
