import React, { useEffect, useState } from "react"
import { EditableText } from "../../../packages/editing/EditableText"
import { useParam } from "../../../packages/ssr/useParam"
import { WidgetView } from "../../../packages/widgets/WidgetView"
import { Uuid } from "../../../reactor/Types/Primitives/Uuid"
import {
    GetFrameworkReportEfforts1Dto,
    GetFrameworkReportFrameworkElements1Dto,
    useFrameworkReport,
} from "./client"
import { ValueDisplay } from "./FormValueDisplay"
import { impactPriorityColor, impactScoreColor } from "./OfferingReportView"
import { HeaderSection } from "./sections/Header"
import { EditableObject } from "../../../packages/editing/EditableObject"
import { Footer } from "./sections/Footer"
import { WidgetAPIView } from "../../../packages/widgets/WidgetAPIView"

export function FrameworkReport() {
    const accountId = useParam("accountId") as any as Uuid<"Account">
    const frameworkId = useParam("frameworkId") as any as Uuid<"Framework">
    const [elementId, setElementId] = useState<Uuid<"FrameworkElement"> | null>(null)
    const { data: report } = useFrameworkReport(
        accountId.valueOf() ?? null,
        frameworkId ?? null,
        elementId || undefined
    )

    useEffect(() => {
        if (!elementId && report?.frameworkElements?.length) {
            setElementId(report.frameworkElements[0].id)
        }
    }, [report])

    const selectedElement = report?.frameworkElements?.find((fe) => fe.id === elementId)

    return (
        <div>
            <HeaderSection
                size="full"
                logoText={report?.account.name}
                title={"Our contribution to the " + report?.framework.name}
                image={report?.account.image as any}
            />
            <div className="container">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        marginTop: 64,
                        marginBottom: 32,
                    }}
                >
                    <img
                        src={report?.framework.thumbnail?.valueOf()}
                        style={{ maxWidth: 64, maxHeight: 64, objectFit: "contain" }}
                    />
                    <h1 style={{ marginLeft: 32 }}>{report?.framework.name}</h1>
                </div>

                <p style={{ marginBottom: 16, padding: 16 }}>{report?.framework.description}</p>

                {report && (
                    <div style={{ margin: 8, padding: 16, borderRadius: 8 }}>
                        {report.frameworkExtraFields?.map((f) => (
                            <ValueDisplay obj={report.frameworkExtraData[f.name]} f={f} />
                        ))}
                    </div>
                )}

                {(report?.frameworkElements?.length || 0) > 1 && (
                    <select
                        value={elementId?.valueOf()}
                        onChange={(e) =>
                            setElementId(e.target.value as any as Uuid<"FrameworkElement">)
                        }
                        style={{ padding: 8, borderRadius: 8, fontSize: 20 }}
                    >
                        {report?.frameworkElements?.map((fe) => (
                            <option value={fe.id.valueOf()}>{fe.name}</option>
                        ))}
                    </select>
                )}

                {(report?.frameworkElements?.length || 0) > 1 && selectedElement && (
                    <>
                        <div
                            style={{
                                backgroundColor: (selectedElement.color ?? "#aaaaaa") + "22",
                                padding: 32,
                                marginTop: 16,
                                borderRadius: 8,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={selectedElement.thumbnail?.valueOf()}
                                style={{ maxWidth: 128, borderRadius: 8 }}
                            />
                            <div
                                style={{
                                    marginLeft: 32,
                                    fontSize: 24,
                                    color: selectedElement.color,
                                }}
                            >
                                <div style={{ fontWeight: "bold" }}>{selectedElement.name}</div>
                                <div>{selectedElement.shortDescription}</div>
                            </div>
                        </div>
                        <div style={{ padding: 16, marginTop: 16 }}>
                            {selectedElement.description}
                        </div>
                    </>
                )}
                {(report?.frameworkElements?.length || 0) > 0 && (
                    <>
                        <div style={{ marginBottom: 32 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: 32,
                                }}
                            >
                                <h2>Alignment Overview</h2>
                                <h5>{selectedElement?.name}</h5>
                            </div>
                            {report?.alignmentOverview && (
                                <WidgetView
                                    value={[
                                        [
                                            report.alignmentOverview.financials,
                                            report.alignmentOverview.turnover,
                                        ],
                                        [
                                            report.alignmentOverview.capEx,
                                            report.alignmentOverview.opEx,
                                        ],
                                    ]}
                                />
                            )}
                        </div>
                        <div
                            style={{
                                padding: 16,
                                marginTop: 32,

                                display: "flex",
                                flexDirection: "row",

                                fontSize: 20,
                                borderBottom: "1px solid #ccc",
                                marginBottom: 16,
                                fontWeight: "bold",
                            }}
                        >
                            <div style={{ width: 32 }}>#</div>
                            <div style={{ flex: 2, fontWeight: "bold" }}>Contribution</div>
                            <div style={{ flex: 1 }}>Scope</div>
                            <div style={{ flex: 1 }}>Score</div>
                            <div style={{ flex: 1 }}>Priority</div>
                            <div style={{ width: 24 }} />
                        </div>
                    </>
                )}

                {selectedElement &&
                    report?.efforts?.map((effort, index) => (
                        <EffortBox
                            number={index + 1}
                            effort={effort}
                            selectedElement={selectedElement}
                        />
                    ))}

                {selectedElement && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: 64,
                                marginBottom: 32,
                            }}
                        >
                            <h2>Intensity</h2>
                            <h5>Impact and financial ratios</h5>
                        </div>
                        <div>
                            <WidgetAPIView
                                name="AccountSFDRChart"
                                param={`${accountId}:${selectedElement.id}`}
                            />
                        </div>
                    </>
                )}

                <div style={{ height: 128 }} />
            </div>
            <Footer />
        </div>
    )
}

function EffortBox({
    number,
    effort,
    selectedElement,
}: {
    number: number
    effort: GetFrameworkReportEfforts1Dto
    selectedElement: GetFrameworkReportFrameworkElements1Dto
}) {
    const [expanded, setExpanded] = useState(false)
    const element = effort.frameworkElements.find((fe) => fe.id === selectedElement.id)

    const parentScope = effort?.parentScope ?? "None"
    const childScope = effort?.childScope ?? "None"

    const impactPriority = element?.impactPriority ?? "None"
    const impactScore = element?.impactScore ?? "None"

    const expandable =
        effort.description ||
        impactScore !== "None" ||
        impactPriority !== "None" ||
        !!element?.recommendation

    const impactScoreWidget = (
        <div
            style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    width: 9,
                    height: 9,
                    borderRadius: 6,
                    backgroundColor: impactScoreColor(impactScore),
                    marginRight: 8,
                }}
            />{" "}
            {impactScore}
        </div>
    )
    const impactPriorityWidget = (
        <div
            style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    width: 9,
                    height: 9,
                    borderRadius: 6,
                    backgroundColor: impactPriorityColor(impactPriority),
                    marginRight: 8,
                }}
            />{" "}
            {impactPriority}
        </div>
    )

    return (
        <EditableObject
            obj={element ?? {}}
            typeName="GetFrameworkReportEfforts1FrameworkElementsDto"
            putType={["PutFrameworkReportRequestBodyDto", "efforts", 0, "frameworkElements", 0]}
            actions={{}}
        >
            <div
                onClick={() => setExpanded(!expanded)}
                style={{
                    padding: 16,
                    marginBottom: 16,
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: 8,
                    fontSize: 20,
                    cursor: expandable ? "pointer" : undefined,
                }}
            >
                <div style={{ width: 32 }}>{number}</div>
                <div style={{ flex: 2, fontWeight: "bold" }}>{effort.name}</div>
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div>{effort.type}</div>
                    {(parentScope !== "None" || childScope !== "None") && (
                        <div style={{ fontSize: 12 }}>
                            {parentScope} {childScope}
                        </div>
                    )}
                </div>
                {impactScoreWidget}
                {impactPriorityWidget}

                <i
                    className={
                        expandable
                            ? `fas ${expanded ? "fa-chevron-up" : "fa-chevron-down"}`
                            : undefined
                    }
                    style={{ width: 24, alignSelf: "center" }}
                />
            </div>
            {expanded && (
                <>
                    <EditableText
                        style={{ margin: 16 }}
                        obj={effort}
                        prop="description"
                        isMarkdown={true}
                    />
                    {impactScore !== "None" && (
                        <div
                            style={{
                                margin: 8,
                                padding: 16,
                                borderRadius: 8,
                                backgroundColor: impactScoreColor(impactScore) + "22",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ marginRight: 8, fontWeight: "bold" }}>Score:</div>{" "}
                                {impactScoreWidget}
                            </div>
                            {element && (
                                <EditableText
                                    obj={element}
                                    prop="impactScoreComment"
                                    isMarkdown={true}
                                    placeholder="(impact score comment)"
                                    style={{ marginTop: 16 }}
                                />
                            )}
                        </div>
                    )}
                    {impactPriority !== "None" && (
                        <div
                            style={{
                                margin: 8,
                                padding: 16,
                                borderRadius: 8,
                                backgroundColor: impactPriorityColor(impactPriority) + "22",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ marginRight: 8, fontWeight: "bold" }}>Priority:</div>{" "}
                                {impactPriorityWidget}
                            </div>
                            {element && (
                                <EditableText
                                    obj={element}
                                    prop="impactPriorityComment"
                                    isMarkdown={true}
                                    placeholder="(impact priority comment)"
                                    style={{ marginTop: 16 }}
                                />
                            )}
                        </div>
                    )}
                    {element?.recommendation && (
                        <div
                            style={{
                                margin: 8,
                                padding: 16,
                            }}
                        >
                            <div style={{ fontWeight: "bold" }}>Recommendation</div>
                            {element && (
                                <EditableText
                                    obj={element}
                                    prop="recommendation"
                                    isMarkdown={true}
                                    placeholder="(impact priority comment)"
                                />
                            )}
                        </div>
                    )}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <WidgetView value={effort?.financialsChart} />
                        <WidgetView value={effort?.alignmentChart} />
                    </div>
                    <div style={{ margin: 8, padding: 16, borderRadius: 8 }}>
                        {element?.extendedFields.map((f) => (
                            <ValueDisplay obj={element.data[f.name]} f={f} />
                        ))}
                    </div>
                </>
            )}
        </EditableObject>
    )
}
