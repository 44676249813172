import { css } from "@emotion/react"
import { EditableArray } from "../../../../packages/editing/EditableArray"
import { EditableObject } from "../../../../packages/editing/EditableObject"
import { Section } from "../../../../packages/editing/Section"
import { Image, ImageToUrl, Markdown } from "../../../../reactor"
import { responsiveCss } from "../styles"
import { EditableText } from "../../../../packages/editing/EditableText"

Section(ImageAndTextGrid)
function ImageAndTextGrid(section: {
    heading?: Markdown
    /**
     * @expand
     */
    items: GridItem[]
}) {
    return (
        <section
            css={css(
                {
                    width: "100%",
                    padding: 24,
                    maxWidth: 1296,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    alignSelf: "center",
                },
                responsiveCss("min", "md", { padding: "20px 48px" })
            )}
        >
            {section.heading ? <h1>{section.heading}</h1> : null}
            <div
                css={css(
                    {
                        display: "grid",
                        margin: "0 auto",
                        width: "100%",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: 24,
                        maxWidth: 280 * section.items.length,
                        gridTemplateColumns: `repeat(${Math.min(
                            2,
                            section.items.length
                        )}, minmax(0, 1fr))`,
                    },
                    responsiveCss("min", "sm", {
                        gridTemplateColumns: `repeat(${Math.min(
                            3,
                            section.items.length
                        )}, minmax(0, 1fr))`,
                    }),
                    responsiveCss("min", "md", {
                        gridTemplateColumns: `repeat(${Math.min(
                            4,
                            section.items.length
                        )}, minmax(0, 1fr))`,
                        gap: 32,
                    }),
                    !!section.heading && { marginTop: 32 }
                )}
            >
                <EditableArray
                    direction="row"
                    arr={section.items}
                    itemTypeName="GridItem"
                    itemStyle={(i) => ({})}
                >
                    {(item) => {
                        return (
                            <EditableObject obj={item} typeName="GridItem" actions={{}}>
                                <GridItemView item={item} />
                            </EditableObject>
                        )
                    }}
                </EditableArray>
            </div>
        </section>
    )
}

type GridItem = {
    image?: Image
    text?: Markdown
}

function GridItemView({ item }: { item: GridItem }) {
    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                width: "100%",
                fontSize: 18,
            })}
        >
            <div
                css={css(
                    {
                        borderRadius: 24,
                        overflow: "hidden",
                        marginBottom: 12,
                        height: 240,
                    },
                    responsiveCss("min", "lg", { height: 280 })
                )}
            >
                {item.image ? (
                    <img
                        css={css({
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        })}
                        src={ImageToUrl(item.image)}
                    />
                ) : (
                    <div
                        css={css({
                            backgroundColor: "#ccc",
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        })}
                    >
                        <i className="fa fa-image"></i>
                    </div>
                )}
            </div>
            {item.text ? <EditableText isMarkdown obj={item} prop="text" /> : null}
        </div>
    )
}
