import React from "react"
import { Slide } from "./Slide"
import { usePage } from "./client"
import { useParams } from "react-router-dom"
import { Footer } from "./sections/Footer"
import { EditableSections } from "../../../packages/editing/EditableSections"

export function PageView() {
    const slug = useParams().slug ?? ""
    const { data: page } = usePage(slug)

    if (!page) return <></>

    return (
        <Slide key={slug} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <EditableSections sections={page.sections} />
            <Footer key="footer" />
        </Slide>
    )
}
