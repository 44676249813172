import React, { useState } from "react"
import { useHover } from "../hooks/useHover"
import { Toggle } from "./Toggle"
import { usePerformWidgetAction } from "./WidgetContext"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<Toggle>("Toggle", ({ value }) => <ToggleWidget {...value} />)

export function ToggleWidget({
    value,
    onChanged,
    style,
    label,
    widgetKey,
}: {
    label?: string
    value: boolean
    onChanged: (newValue: boolean) => void
    style?: React.CSSProperties
    widgetKey?: string
}) {
    const performAction = usePerformWidgetAction()
    const { hover, hoverProps } = useHover()
    const [busy, setBusy] = useState(false)

    const color = !value
        ? hover
            ? ColorStyles.primary[900]
            : ColorStyles.gray[100]
        : hover
          ? ColorStyles.primary[900]
          : ColorStyles.primary[700]

    return (
        <div
            className="hide-in-print"
            {...hoverProps}
            onClick={async (e) => {
                e.stopPropagation()

                try {
                    if (onChanged instanceof Function) {
                        onChanged(!value)
                    } else if (widgetKey) {
                        setBusy(true)
                        await performAction(widgetKey, "onChanged", [!value], () => setBusy(false))
                    } else {
                        console.log("No action specified")
                    }
                } catch (error: any) {
                    alert("detail" in error ? error.detail : JSON.stringify(error))
                } finally {
                    setBusy(false)
                }
            }}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: hover ? color : undefined,
                ...style,
            }}
        >
            {label && <span style={{ fontSize: 11, marginRight: "1rem" }}>{label}</span>}
            <div>
                <div
                    style={{
                        width: 44,
                        maxWidth: 44,
                        maxHeight: 24,
                        borderRadius: 12,
                        boxSizing: "border-box",
                        padding: 2,
                        backgroundColor: color,
                    }}
                >
                    <div
                        style={{
                            marginLeft: value ? 20 : 0,
                            width: 20,
                            height: 20,
                            borderRadius: 10,
                            boxSizing: "border-box",
                            backgroundColor: "white",
                            boxShadow: "0px 1px 2px 0px #1018280F",
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
