import { useCallback, useEffect, useState } from "react"
import { css } from "@emotion/react"
import { colors, resetStyles, responsiveCss } from "../styles"
import { Link } from "react-router-dom"
import { EditableObject } from "../../../../packages/editing/EditableObject"
import { EditableImage } from "../../../../packages/editing/EditableImage"
import { EditableText } from "../../../../packages/editing/EditableText"
import { EditableArray } from "../../../../packages/editing/EditableArray"
import { useIsEditing } from "../../../../packages/editing/EditableContext"
import { useMenuContext } from "../MenuContext"
import { Image } from "../../../../reactor/Types/File"
import { Section } from "../../../../packages/editing/Section"

Section("HeaderSection", HeaderSection)
export function HeaderSection(section: {
    image?: Image
    size: "full" | "compact"
    logoText?: string
    title?: string

    /**
     * How much the image should scroll in percent relative to the rest of the page, as the page
     * is scrolled. Adjust this and test in different screen sizes to ensure the image always
     * covers the entire section.
     *
     * Note that right now this does not work when logged in and the studio top bar is visible,
     * so to test, open the site in an incognito window and refresh.
     */
    parallaxAmount?: number

    /**
     * If set, the logo will displayed large and in the middle.
     * Otherwise, the logo will be displayed in the top-left corner
     */
    isHome?: boolean
}) {
    const menu = useMenuContext()
    const editing = useIsEditing()
    const [menuOpen, setMenuOpen] = useState(false)
    const toggleMenu = useCallback(() => {
        setMenuOpen(!menuOpen)
    }, [menuOpen])

    const margin = section.size === "full" ? 32 : 22

    const [bgImageOffset, setBgImageOffset] = useState(0)

    const handleScrollEvent = useCallback(() => {
        if (typeof section.parallaxAmount === "number") {
            const offset =
                (document.documentElement.scrollTop * (100 - section.parallaxAmount)) / 100
            setBgImageOffset(offset)
        }
    }, [setBgImageOffset, section.parallaxAmount])

    useEffect(() => {
        if (typeof section.parallaxAmount === "number") {
            document.addEventListener("scroll", handleScrollEvent)
            document.addEventListener("touchmove", handleScrollEvent)

            return () => {
                document.removeEventListener("scroll", handleScrollEvent)
                document.removeEventListener("touchmove", handleScrollEvent)
            }
        }
    }, [section.parallaxAmount])

    if (!menu) return <></>

    return (
        <EditableImage
            obj={section}
            options={{ width: 1920, format: "jpeg" }}
            prop="image"
            style={{
                height: section.size === "full" ? 500 : 90,
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: `50% calc(50% + ${bgImageOffset}px)`,
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: 200,
                    position: "absolute",
                    bottom: 0,
                    background:
                        section.size === "full"
                            ? `linear-gradient(0deg, ${colors.darkGreen} 0%, ${
                                  colors.darkGreen + "00"
                              } 100%)`
                            : colors.darkGreen,
                }}
            />
            {!section.isHome && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "absolute",
                        top: margin,
                        left: margin,
                        color: "white",
                        fontSize: 36,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <EditableImage
                        obj={menu}
                        prop="logo"
                        link="/"
                        style={{
                            width: 48,
                            height: 48,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            marginRight: 24,
                        }}
                        options={{
                            height: 48,
                        }}
                    />
                    {section.size === "compact" && (
                        <EditableText obj={section} prop="logoText" link="/" />
                    )}
                </div>
            )}
            {menu && (
                <>
                    <div
                        css={css(
                            { position: "absolute", top: 0, left: 0, right: 0 },
                            responsiveCss("max", "xs", { height: "100vh" })
                        )}
                    >
                        <div
                            css={css(
                                { position: "absolute", top: 24, right: 24 },
                                responsiveCss("min", "sm", { display: "none" })
                            )}
                        >
                            <button
                                aria-label="Open menu"
                                onClick={toggleMenu}
                                css={css([
                                    resetStyles("button"),
                                    {
                                        cursor: "pointer",
                                    },
                                ])}
                            >
                                <i
                                    className="fas fa-bars"
                                    css={css({ fontSize: 32, color: "#fff" })}
                                />
                            </button>
                        </div>
                        <div
                            css={css(
                                {
                                    display: "flex",
                                    flexDirection: "column",
                                    fontWeight: "normal",
                                    fontSize: 20,
                                    color: "#fff",
                                    a: {
                                        color: "#fff",
                                    },
                                    overflow: "hidden",
                                    zIndex: 100,
                                    position: "relative",
                                },
                                responsiveCss("max", "xs", {
                                    width: "100%",
                                    maxHeight: menuOpen ? "100vh" : 0,
                                    opacity: menuOpen ? 10000 : 0,
                                    backgroundColor: colors.darkGreen,
                                    transition: "max-height 0.5s ease",
                                    boxShadow: "0 16px 8px rgba(0,0,0,.2)",
                                    padding: "18px 14px",
                                }),
                                responsiveCss("min", "sm", {
                                    position: "absolute",
                                    flexDirection: "row",
                                    textShadow: "3px 3px 4px #0008",
                                    top: margin,
                                    right: margin,
                                })
                            )}
                        >
                            <button
                                aria-label="Close menu"
                                onClick={toggleMenu}
                                css={css([
                                    resetStyles("button"),
                                    {
                                        cursor: "pointer",
                                        zIndex: 2,
                                        position: "absolute",
                                        top: 24,
                                        right: 24,
                                        color: "#fff",
                                    },
                                    responsiveCss("min", "sm", { display: "none" }),
                                ])}
                            >
                                <i
                                    className="fas fa-times"
                                    css={css({
                                        fontSize: 32,
                                    })}
                                />
                            </button>
                            <EditableArray arr={menu.items} direction="row" itemTypeName="MenuItem">
                                {(item) => {
                                    let text = (
                                        <EditableText
                                            obj={item}
                                            prop="title"
                                            style={{ padding: 10, cursor: "pointer" }}
                                        />
                                    )
                                    if (item.link && !editing) {
                                        text = (
                                            <a
                                                onClick={(e) => {
                                                    if (item.link?.startsWith("https://")) {
                                                        window.open(item.link, "_blank")
                                                        e.preventDefault()

                                                        return false
                                                    }
                                                }}
                                                style={{ textDecoration: "none" }}
                                                href={item.link}
                                            >
                                                {text}
                                            </a>
                                        )
                                    }
                                    return (
                                        <EditableObject obj={item} typeName="MenuItem" actions={{}}>
                                            {text}
                                        </EditableObject>
                                    )
                                }}
                            </EditableArray>
                            <Link
                                to="/studio"
                                style={{
                                    cursor: "pointer",
                                    padding: 10,
                                    textDecoration: "none",
                                }}
                            >
                                Log in
                            </Link>
                        </div>
                        {/** Element to take up the rest of the screen to hide menu if user clicks outside the menu */}
                        <div
                            css={css({ height: "100%", zIndex: 50, position: "relative" })}
                            onClick={toggleMenu}
                        ></div>
                    </div>
                </>
            )}

            <div style={{ zIndex: 1, marginTop: section.isHome ? undefined : 200 }}>
                {section.isHome && (
                    <EditableImage
                        obj={menu}
                        prop="logo"
                        style={{
                            backgroundSize: "contain",
                            height: "min(33vw, 160px)",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                        options={{
                            height: 160,
                        }}
                    />
                )}
                {section.size === "full" && (
                    <EditableText
                        link="/"
                        obj={section}
                        prop="logoText"
                        defaultText="ClimatePoint"
                        style={{
                            marginTop: 24,
                            color: "white",
                            cursor: "pointer",
                            fontSize: 55,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    />
                )}
                {section.size === "full" && (
                    <EditableText
                        obj={section}
                        prop="title"
                        style={{
                            color: colors.mediumGreen,
                            fontSize: 32,
                            textAlign: "center",
                        }}
                    />
                )}
            </div>
        </EditableImage>
    )
}
