import React from "react"
import { StickyHeader } from "../../studio/Views/StickyHeader"
import { UntitledUI } from "../untitled-ui"
import { BreadcrumbsWidget } from "./BreadcrumbsWidget"
import { ChipWidget } from "./ChipWidget"
import { Title, TitleProps } from "./Title"
import { RegisterWidget, WidgetView } from "./WidgetView"
import { Icon } from "../../studio/Views/Icon"
import { useImageWithAuth } from "../files/useImageWithAuth"

RegisterWidget<Title>("Title", ({ value }) => <TitleWidget {...value} />)

export function TitleWidget(props: TitleProps) {
    const imageBase64 = useImageWithAuth(props.imageUrl)
    const color = props.color ?? UntitledUI.randomSecondaryColor(props.title)

    const heroAvatar = props.heroImageUrl
        ? {
              marginLeft: 8,
              marginTop: -48,
              width: 164,
              height: 164,
          }
        : undefined

    const avatar = (props.imageUrl || props.icon) && (
        <div
            id={heroAvatar ? "studio-title-hero-avatar" : "studio-title-avatar"}
            className="studio-title-widget-avatar"
            style={{
                width: 84,
                height: 84,
                marginRight: 24,
                borderRadius: 82,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px -4px 6px -2px #10182810, 0px 8px 16px -4px #10182814",
                border: "4px solid white",

                color: color[800],
                fontSize: 32,
                lineHeight: 2.5,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: color[100],
                backgroundImage: imageBase64
                    ? `url(${imageBase64})`
                    : props.imageUrl
                      ? `url(${props.imageUrl})`
                      : undefined,

                ...heroAvatar,
            }}
        >
            {!props.imageUrl && props.icon && (
                <Icon icon={props.icon} color={color[800]} size={32} />
            )}
        </div>
    )

    const buttons = (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 16,
                marginRight: 16,
            }}
        >
            {props.buttons}
            {props.widget && <WidgetView value={props.widget} />}
        </div>
    )

    const body = (
        <>
            {props.breadcrumbs && (
                <BreadcrumbsWidget type="Breadcrumbs" crumbs={props.breadcrumbs} />
            )}
            <div
                className="studio-title-widget-body"
                style={{
                    paddingLeft: 16,
                    paddingBottom: 16,
                    paddingTop: heroAvatar || props.level === 2 ? 0 : 16,
                    display: "flex",
                }}
            >
                {avatar}

                <div
                    id={props.heroImageUrl ? "studio-title-hero" : "studio-title"}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            fontSize: props.level === 2 ? 18 : 30,
                            fontWeight: 500,
                            lineHeight: props.level === 2 ? "20px" : "38px",
                            marginTop: props.level === 2 ? 8 : 4,
                            marginBottom: 4,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        {props.title}
                        <div style={{ width: 16 }} />
                        {props.chips
                            ? (props.chips instanceof Array ? props.chips : [props.chips]).map(
                                  (chip) => <ChipWidget value={chip} />
                              )
                            : undefined}
                    </div>

                    <div
                        style={{
                            fontSize: props.level === 2 ? 14 : 16,
                            fontWeight: 400,
                            color: UntitledUI.colorStyles.gray[500],
                        }}
                    >
                        {props.subTitle}
                    </div>
                </div>
                {buttons}
            </div>
        </>
    )

    if (props.heroImageUrl) {
        return (
            <div style={{ display: "flex", flexDirection: "column", marginBottom: 32 }}>
                <div
                    id="studio-title-hero-image"
                    style={{
                        backgroundImage: `url(${props.heroImageUrl})`,
                    }}
                >
                    <div style={{ flex: 1 }} />
                </div>
                {body}
            </div>
        )
    }

    if (props.sticky) {
        return <StickyHeader>{body}</StickyHeader>
    }
    return body
}
