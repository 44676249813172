import React from "react"
import { colors } from "../styles"
import { Button } from "../Button"
import { Popup, usePopup } from "../Popup"
import { useNavigate } from "../../../../packages/hooks/useNavigate"
import { EditableImage } from "../../../../packages/editing/EditableImage"
import { EditableText } from "../../../../packages/editing/EditableText"
import { ContactMe } from "../ContactMe"
import { useQueryString } from "../../../../reactor/Web"
import { useAppearAnimation } from "../../../../packages/appear-animation/useAppear"
import { Markdown } from "../../../../reactor/Types/Primitives/Markdown"
import { Image } from "../../../../reactor/Types/File"
import { Section } from "../../../../packages/editing/Section"

Section("ImageAndTextSection", ImageAndTextSection)
export function ImageAndTextSection(section: {
    /**
     * @title
     * @default "Header goes here"
     */
    header: string
    /**
     * @default "Body goes here"
     */
    body: Markdown
    imagePosition: "left" | "right"
    image: Image

    callToAction?: {
        text: string
        /** Where to link (internally on this site).
         *
         *  To link to an external site, make sure the link starts with `https://`.
         */
        link: string

        /** If set to true, clicking this call to action initiates the sign in/sign up flow,
         * returning to the `link` when sign in is complete. The link must be whitelisted as a valid
         * callback URL with the authentication provider to work. */
        signIn?: boolean
    }
    /** If specified, a call to action button is displayed that pops up a modal showing the
     * specified link. */
    popup?: { text: string; link: string }

    info?: any
    isSdg?: boolean
}) {
    const { ref, style } = useAppearAnimation()

    const navigate = useNavigate()
    const { ref: popupRef, open: openPopup } = usePopup()

    // Hack hack hack to hide the section if an SDG is in focus on the solutions page
    // Not a good solution but fixing it properly is a lot of work
    const [sdgFilter] = useQueryString("sdg")
    if (!section.isSdg && sdgFilter) return <></>

    const image = (
        <EditableImage
            className="p-3"
            obj={section}
            options={{ height: 800 }}
            prop="image"
            style={{
                flex: 1,
                minHeight: 500,
                minWidth: 300,
                maxWidth: 500,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: colors.white,
                color: colors.foreground,
                borderRadius: 24,
                fontSize: 20,
                position: "relative",
            }}
        />
    )

    const text = (
        <div
            className="p-3"
            style={{
                marginBottom: 32,
                marginTop: 32,
                maxWidth: 500,
            }}
        >
            <EditableText
                obj={section}
                prop="header"
                style={{
                    fontSize: 36,
                    fontWeight: "bold",
                    marginBottom: 16,
                    color: colors.darkGreen,
                }}
            />
            <div
                style={{
                    flex: 1,
                    marginBottom: 32,
                }}
            >
                <EditableText
                    obj={section}
                    prop="body"
                    isMarkdown={true}
                    style={{ color: colors.foreground, fontSize: 20 }}
                />
            </div>
            {section.info}
            {section.callToAction && section.callToAction.text && (
                <Button
                    text={section.callToAction.text}
                    onClick={() => {
                        if (section.callToAction) {
                            if (section.callToAction.signIn) {
                                // TODO: login(`${server()}${section.callToAction.link}`)
                            } else {
                                const link = section.callToAction.link
                                if (link.startsWith("https://")) {
                                    window.open(link, "_blank")
                                } else {
                                    navigate(section.callToAction?.link)
                                }
                            }
                        }
                    }}
                />
            )}
            {section.popup && (
                <>
                    <Popup ref={popupRef}>
                        <ContactMe />
                    </Popup>
                    <Button
                        text={section.popup.text}
                        onClick={() => {
                            if (section.popup?.link) {
                                openPopup()
                            }
                        }}
                    />
                </>
            )}
        </div>
    )

    return (
        <div
            ref={ref}
            className="container"
            style={{
                ...style,
                paddingTop: 64,
                paddingBottom: 64,
            }}
        >
            <div className="row gx-4">
                <div className="col-sm">{section.imagePosition === "left" ? image : text}</div>
                <div className="col-sm">{section.imagePosition === "left" ? text : image}</div>
            </div>
        </div>
    )
}
