import moment from "moment"
import React, { useRef, useState } from "react"
import { Calendar } from "./Calendar"
import { RegisterWidget, WidgetView } from "./WidgetView"
import { ColorStyles } from "../ui"
import { LocalDate } from "../../reactor/Types/Primitives/LocalDateTime"
import { Overlay } from "react-bootstrap"
import { useHover } from "../hooks/useHover"
import { usePerformWidgetAction } from "./WidgetContext"

RegisterWidget<Calendar>("Calendar", ({ value }) => <CalendarWidget value={value} />)

/**
 * Displays a `Calendar` widget.
 *
 * See documentation for `Calendar` for more information.
 */
export function CalendarWidget({ value: props }: { value: Calendar }) {
    const [month, setMonth] = useState({
        month: props.date ? parseInt(props.date.split("-")[1]) - 1 : new Date().getMonth(),
        year: props.date ? parseInt(props.date.split("-")[0]) : new Date().getFullYear(),
    })

    const performAction = usePerformWidgetAction()

    async function select(start?: LocalDate, end?: LocalDate) {
        if (props.methods?.includes("onDateChanged")) {
            if (!props.widgetKey) alert("No widget key - cannot call method")
            else await performAction(props.widgetKey, "onDateChanged", [start, end])
        }
    }

    const startOfMonth = moment(month).startOf("month")
    const endOfMonth = moment(month).endOf("month").subtract(1, "hour")
    const startWeek = startOfMonth.week()
    const endWeek = endOfMonth.week() < startWeek ? 53 : endOfMonth.week()

    const weeks: number[] = []
    for (let i = startWeek; i <= endWeek; i++) {
        weeks.push(i)
    }

    const monthName = moment(month).locale("nb").format("MMMM")
    const gridTemplateColumns = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"

    return (
        <div style={{ maxWidth: 500 }}>
            <div
                style={{
                    display: "flex",
                    width: "100%",

                    fontSize: 16,
                    fontWeight: "bold",
                    padding: 8,
                }}
            >
                <i
                    className="fas fa-chevron-left"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        const newMonth = moment(month).subtract(1, "month")
                        setMonth({ month: newMonth.month(), year: newMonth.year() })
                    }}
                />
                <div style={{ flex: 1, textAlign: "center" }}>
                    {monthName} {month.year}
                </div>
                <i
                    className="fas fa-chevron-right"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        const newMonth = moment(month).add(1, "month")
                        setMonth({ month: newMonth.month(), year: newMonth.year() })
                    }}
                />
            </div>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns,
                }}
            >
                {["Week", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
                    <div style={{ display: "flex", justifyContent: "center", padding: 8 }}>
                        {day}
                    </div>
                ))}
            </div>
            {weeks.map((week) => {
                const datesOfWeek: moment.Moment[] = []
                const startOfWeek = moment()
                    .year(month.year)
                    .week(week.valueOf())
                    .startOf("isoWeek")
                const endOfWeek = moment().year(month.year).week(week.valueOf()).endOf("isoWeek")

                for (let i = moment(startOfWeek); i.isBefore(endOfWeek); i.add(1, "day")) {
                    datesOfWeek.push(moment(i))
                }

                return (
                    <div>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns,
                            }}
                        >
                            <div
                                key={week}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {week === 53 ? 1 : week}
                            </div>
                            {datesOfWeek.map((d) => (
                                <DateButton d={d} calendar={props} select={select} />
                            ))}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

function DateButton({
    d,
    calendar,
    select,
}: {
    d: moment.Moment
    calendar: Calendar
    select: (start?: LocalDate, end?: LocalDate) => Promise<void>
}) {
    const { hover, hoverProps } = useHover()
    const elementTarget = useRef(null)
    const isDate = calendar.date ? moment(calendar.date.valueOf()).isSame(d, "day") : false
    const isEndDate = calendar.endDate ? moment(calendar.endDate.valueOf()).isSame(d, "day") : false

    const isInBetweenDate =
        calendar.date && calendar.endDate
            ? moment(d).isBetween(calendar.date.valueOf(), calendar.endDate.valueOf(), "day")
            : false

    const localDate = LocalDate(d.format("YYYY-MM-DD"))

    const isTooEarly =
        calendar.firstAvailableDate !== undefined &&
        moment(localDate.valueOf()).isBefore(calendar.firstAvailableDate.valueOf())

    const isTooLate =
        calendar.lastAvailableDate !== undefined &&
        moment(localDate.valueOf()).isAfter(calendar.lastAvailableDate.valueOf())

    const isOutsideRange = isTooEarly || isTooLate

    const isOccupied = calendar.unavailableDates?.includes(localDate)

    const isUnavailable = isOccupied || isOutsideRange

    const tooltip = calendar.datesWithPopups?.find((x) => x.date === localDate)?.popup

    return (
        <>
            {tooltip && (
                <Overlay target={elementTarget} show={hover} placement={"bottom"}>
                    {(p) => (
                        <div
                            {...p}
                            style={{
                                ...p.style,
                                backgroundColor: "white",
                                borderColor: "white",
                                boxShadow: "0 0 8px 0 rgba(0,0,0,0.2)",
                                padding: 8,
                            }}
                        >
                            <WidgetView value={tooltip} />
                        </div>
                    )}
                </Overlay>
            )}
            <div
                ref={elementTarget}
                {...hoverProps}
                key={localDate.valueOf()}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    userSelect: "none",
                }}
            >
                <div
                    onClick={() => {
                        const date = LocalDate(moment(d).format("YYYY-MM-DD"))

                        if (calendar.selectionMode === undefined) return
                        if (isOutsideRange) return
                        if (isOccupied) return
                        if (calendar.selectionMode === "single") {
                            void select(date)
                            return
                        }

                        // ranage/multi-select mode
                        if (!calendar.date) {
                            void select(date)
                        } else if (!calendar.endDate)
                            void select(
                                LocalDate(
                                    moment
                                        .min(moment(calendar.date.valueOf()), d)
                                        .format("YYYY-MM-DD")
                                ),
                                LocalDate(
                                    moment
                                        .max(moment(calendar.date.valueOf()), d)
                                        .format("YYYY-MM-DD")
                                )
                            )
                        else {
                            void select(date)
                        }
                    }}
                    style={{
                        padding: 8,
                        borderRadius: 100,
                        width: 32,
                        height: 32,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 4,
                        color: isUnavailable
                            ? ColorStyles.error[800]
                            : isDate || isEndDate || isUnavailable
                              ? "white"
                              : "black",

                        transform: tooltip && hover ? "scale(1.2)" : undefined,
                        transition: "transform 0.2s ease-in-out",

                        backgroundColor: isDate
                            ? ColorStyles.primary[800]
                            : isEndDate
                              ? ColorStyles.primary[800]
                              : isOutsideRange
                                ? ColorStyles.gray[25]
                                : isInBetweenDate
                                  ? isUnavailable
                                      ? ColorStyles.error[500]
                                      : ColorStyles.primary[500]
                                  : isUnavailable
                                    ? ColorStyles.error[200]
                                    : ColorStyles.gray[100],
                    }}
                >
                    {d.date()}
                </div>
            </div>
        </>
    )
}
