/** NODE ONLY - Returns the call stack of functions that called into the current code path. */
export function CallSites() {
    const _prepareStackTrace = Error.prepareStackTrace
    Error.prepareStackTrace = (_, stack) => stack
    const stack = new Error().stack?.slice(1)
    Error.prepareStackTrace = _prepareStackTrace
    return stack as any as NodeJS.CallSite[]
}

import fspath from "path"

/** Translates a file name obtained e.g. from a `CallSites` stack frame into the corresponding
 *  TypeScript source file in the original source code.
 *
 *  The returned path will be relative to the root of the project.
 *
 *  This makes it possible to compare source paths obtained when running in `ts-node` (e.g. when
 *  generating reflection info at build time) with paths obtained when running in `node` (e.g. at
 *  production time). This is typically used to associate reflection info to functions whitelisted
 *  at runtime.
 */
export function ResolveTypeScriptSourceFile(
    /** The file name as returned by `CallSites()` */
    sourcePath: string
): string {
    sourcePath = fspath.relative(".", sourcePath)

    // TODO: This is a hack to get the file name of the function's corresponding TS
    // file. Ideally there should be no knowledge of the `build/` folder here.
    if (sourcePath.startsWith("build/")) sourcePath = sourcePath.substring(6)

    if (sourcePath?.endsWith(".js"))
        sourcePath = sourcePath.substring(0, sourcePath.length - 3) + ".ts"

    return sourcePath
}

/**
 * Determines the model name the function was called from.
 */
export function ResolveModelName() {
    const stack = CallSites()
    for (const sf of stack) {
        const fn = sf.getFileName()
        if (fn?.includes("/models/")) {
            const modelName = fn.split("/models/")[1].split("/")[0]
            return modelName
        }
    }
}
