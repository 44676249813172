import React, {
    ElementRef,
    ForwardedRef,
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from "react"
import { useDelay } from "../../../reactor/Web"
import { CloseX, colors } from "./styles"

type Popup = {
    open(): void
}

export function usePopup() {
    const ref = useRef<ElementRef<typeof Popup>>(null)
    return {
        ref,
        open() {
            if (ref.current) {
                ref.current.open()
            }
        },
    }
}

export const Popup = forwardRef(
    (props: { children: React.ReactNode }, ref: ForwardedRef<Popup>) => {
        const [open, setOpen] = useState(false)

        const [direction, setDirection] = useState<"hiding" | "appearing">("appearing")
        const selectedDelay = useDelay(open, 200)
        const selectedLongDelay = useDelay(open, 500)

        useImperativeHandle(ref, () => ({
            open() {
                setDirection("appearing")
                setOpen(true)
            },
        }))

        if (!(open || selectedLongDelay)) return <></>

        function close(e: { stopPropagation(): void }) {
            setDirection("hiding")
            setOpen(false)
            e.stopPropagation()
        }

        return (
            <div
                onClick={close}
                style={{
                    cursor: "auto",
                    display: open || selectedLongDelay ? "flex" : "none",
                    flexDirection: "row",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: open ? "#0008" : undefined,
                    transition: "all 0.2 ease",
                    zIndex: 100,
                    justifyContent: "center",
                    perspective: "800px",
                }}
            >
                <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        backgroundColor: "white",
                        boxShadow: "0 3px 20px 0 rgb(0 0 0 / 50%)",

                        transform:
                            selectedDelay && open
                                ? "scale(1) rotateX(0deg)"
                                : direction === "appearing"
                                  ? "scale(0.4) rotateX(40deg)"
                                  : "scale(0.4) rotateX(-40deg)",
                        opacity: selectedDelay && open ? 1 : 0,
                        transition: "all 0.4s ease",
                        margin: 16,
                        minWidth: "40%",
                        maxWidth: 1024,
                        maxHeight: 700,
                        height: "100%",
                        borderRadius: 8,
                        alignSelf: "center",
                        display: "flex",
                        padding: 8,
                    }}
                >
                    <div
                        onClick={close}
                        style={{
                            position: "absolute",
                            cursor: "pointer",
                            right: 12,
                            top: 8,
                            padding: 8,
                            fontSize: 32,
                            color: colors.darkGreen,
                        }}
                    >
                        <CloseX />
                    </div>
                    {props.children}
                </div>
            </div>
        )
    }
)
