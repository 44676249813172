import React, { useEffect } from "react"
import type { CircleChart } from "./CircleChart"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles, ColorStyle } from "../ui"
import { TitleWidget } from "./TitleWidget"

RegisterWidget<CircleChart>("CircleChart", ({ value }) => <CircleChartView {...value} />)

const fmt = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
})

export function CircleChartView({ values, labels, ...props }: CircleChart) {
    const [ref, setRef] = React.useState<SVGElement | null>(null)
    const [size, setSize] = React.useState<{ width: number; height: number } | undefined>()
    const [selected, setSelected] = React.useState<number | undefined>(
        values.length === 1 ? 0 : undefined
    )

    const colors = props.colors ?? values.map((v) => "primary")

    const availableShades: (keyof ColorStyle)[] = (Object.keys(ColorStyles.primary) as any)
        .slice(2)
        .reverse()

    const colorShades = colors.map((c, i) => {
        const index = Math.ceil((i / values.length) * availableShades.length)
        const shade = availableShades[index]
        return ColorStyles[c][shade]
    })

    useEffect(() => {
        if (ref) {
            const { width, height } = ref.getBoundingClientRect()
            setSize({ width, height })
        }
    }, [ref])
    const radius = Math.max(10, Math.min(size?.width ?? 0, size?.height ?? 0) / 2 - 50)
    const cx = (size?.width ?? 0) / 2
    const cy = (size?.height ?? 0) / 2

    const total = values.reduce((acc, v) => acc + v, 0)
    const arcs = values.map((v) => (v / total) * 360)
    const startDeg = arcs.map((v, i) => arcs.slice(0, i).reduce((acc, v) => acc + v, 0))
    let endDeg = arcs.map((v, i) => arcs.slice(0, i + 1).reduce((acc, v) => acc + v, 0))
    if (endDeg[0] === 360) endDeg = [359.999]

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div style={{ width: "100%" }}>
                <TitleWidget level={2} title={props.title ?? ""} subTitle={props.subTitle} />
            </div>
            <svg
                ref={setRef}
                onMouseLeave={() => (values.length > 1 ? setSelected(undefined) : undefined)}
                viewBox={`0 0 ${size?.width ?? 100} ${size?.height ?? 100}`}
                style={{
                    width: "57.5%",
                }}
            >
                {startDeg.map((start, i) => (
                    <Arc
                        fromDeg={start}
                        toDeg={endDeg[i]}
                        radius={radius}
                        cx={cx}
                        cy={cy}
                        stroke={colorShades[i]}
                        selected={selected === i}
                        select={() => setSelected(i)}
                    />
                ))}

                {selected !== undefined && (
                    <text
                        x={cx}
                        y={cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize={32}
                        fontWeight={500}
                    >
                        {fmt.format((values[selected] / total) * 100)} %
                    </text>
                )}
            </svg>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginTop: 32,
                    marginLeft: 32,
                    marginRight: 32,
                    marginBottom: 16,
                }}
            >
                {labels?.map((label, i) => (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",

                            opacity: selected === undefined || selected === i ? 1 : 0.5,
                            transition: "opacity 0.2s",
                        }}
                    >
                        <div
                            style={{
                                width: 12,
                                height: 12,
                                borderRadius: 6,
                                marginRight: 12,
                                marginLeft: 16,
                                backgroundColor: colorShades[i],
                            }}
                        />
                        {label}
                    </div>
                ))}
            </div>
        </div>
    )
}

function polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    }
}

function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
    const start = polarToCartesian(x, y, radius, endAngle)
    const end = polarToCartesian(x, y, radius, startAngle)
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"
    const d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
        " "
    )
    return d
}

function Arc({
    fromDeg,
    toDeg,
    radius = 50,
    cx = 60,
    cy = 60,
    stroke,
    selected,
    select,
}: {
    fromDeg: number
    toDeg: number
    radius?: number
    cx?: number
    cy?: number
    stroke: string
    selected: boolean
    select: () => void
}) {
    return (
        <path
            onMouseEnter={select}
            d={describeArc(cx, cy, radius, fromDeg, toDeg)}
            fill="none"
            stroke={stroke}
            style={{
                transform: selected ? "scale(1.05)" : undefined,
                transition: "transform 0.2s",
                transformOrigin: "center",
            }}
            strokeWidth={radius / 2.5}
        />
    )
}
