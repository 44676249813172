import React from "react"
import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import type { Widget } from "."
import { server } from "../../server"
import type { AsyncWidget } from "./AsyncWidget"
import { RegisterWidget, WidgetView } from "./WidgetView"

RegisterWidget("AsyncWidget", AsyncWidgetView)

/** Displays a asynchronously fetched widget. */
export function AsyncWidgetView({ value }: { value: AsyncWidget }) {
    const [content, setContent] = useState<Widget | undefined>(undefined)

    useEffect(() => {
        void fetch(`${server()}/api/async-widget/${value.secret}`)
            .then((res) => res.json())
            .then((res) => setContent(res.content))
    }, [value.secret])

    if (content === undefined) return <Spinner animation="border" />

    return <WidgetView value={content} />
}
