import { useState } from "react"
import { useViewportIntersects } from "./useViewportIntersect"
import { useDelay } from "../../reactor/Web"

/** Animates an element when it appears in the viewport for the first time.
 *
 *  Returns a ref to attach to the element, and a style object to apply to the element.
 */
export function useAppearAnimation(options?: {
    delayMs?: number
    durationMs: number
    transform?: string
    /** Whether to only animate the first time the elmement enters the
     * viewport,or every time the element enters the viewport.
     *
     * Defaults to true.
     */
    once?: boolean
}) {
    const [ref, setRef] = useState<HTMLDivElement | null>(null)
    const visible = useViewportIntersects(ref, options?.once ?? true)
    const { delayMs = 0, durationMs = 350, transform = "translateY(-50px)" } = options || {}
    const visibleNow = useDelay(visible, delayMs)
    return {
        ref: setRef,
        style: {
            transform: visibleNow ? undefined : transform,
            opacity: visibleNow ? undefined : 0,
            transition: `all ${durationMs / 1000}s ease-out`,
        },
    }
}
