import React from "react"
import { createContext, useContext } from "react"
import { IsWidgetBase } from "./WidgetTypeHelpers"
import type { Widget } from "./Widget"
import { RegisterWidget, WidgetView } from "./WidgetView"
import { Layout } from "./Layout"

export const DirectionContext = createContext<"row" | "column">("column")

/** Dashboard layout is made using arrays.
 *
 *  By default, layout is column-wise flexbox, and it alternates between row and column flexing with
 *  each nested array.
 *
 *  For more control, use the Layout() widget (or the Row() and Column() shortcuts
 */

RegisterWidget<Widget>(undefined, ({ value, isRoot }) => {
    if (value instanceof Array) {
        const flexDirection = useContext(DirectionContext)
        return (
            <LayoutWidget
                value={{ type: "Layout", direction: flexDirection, children: value }}
                isRoot={isRoot}
            />
        )
    }
})

RegisterWidget<Layout>("Layout", LayoutWidget)
function LayoutWidget({ value, isRoot }: { value: Layout; isRoot?: boolean }) {
    const contextDirection = useContext(DirectionContext)
    const flexDirection = value.direction ?? contextDirection

    function isHero(w: Widget) {
        return w && typeof w === "object" && "isHero" in w && w.isHero
    }

    const anyHeroes = !!isRoot && value.children.some(isHero)

    return (
        <div
            className={value.className}
            style={{
                width: flexDirection === "row" ? "100%" : undefined,
                display: "flex",
                flexDirection,
                marginLeft: anyHeroes ? undefined : isRoot ? 8 : undefined,
                marginRight: anyHeroes ? undefined : isRoot ? 8 : undefined,
                ...value.style,
            }}
        >
            <DirectionContext.Provider value={flexDirection === "row" ? "column" : "row"}>
                {value.children.map((w) => {
                    // Skip `null`'s in the layout instead of displaying `null` (which is rarely
                    // the desired effect). This allows server side rendering to use logical
                    // operators to conditionaly render things inline in a list.
                    if (w === null) return undefined
                    const key =
                        typeof w === "object" && "widgetKey" in w
                            ? (w.widgetKey as string)
                            : undefined

                    const widget = <WidgetView key={key} value={w} isRoot={false} />

                    if (flexDirection === "row") {
                        const flex = IsWidgetBase(w) ? w.flex : undefined
                        return (
                            <div
                                key={key}
                                style={{
                                    flex: flex === null ? undefined : flex ?? 1,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {widget}
                            </div>
                        )
                    }

                    if (!anyHeroes || isHero(w)) return widget

                    return (
                        <div
                            key={key}
                            style={{
                                marginLeft: 8,
                                marginRight: 8,
                                display: "flex",
                                flexDirection,
                            }}
                        >
                            {widget}
                        </div>
                    )
                })}
            </DirectionContext.Provider>
        </div>
    )
}
