import { useState, useCallback, useEffect } from "react"
import qs from "query-string"
import { IsNode } from "../AssertNode"

export function pushQueryString(qsValue: string) {
    const newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        qsValue +
        window.location.hash
    window.history.pushState({ path: newUrl }, "", newUrl)
    listeners.forEach((x) => x())
}

export function pushPathname(path: string) {
    const newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        path +
        window.location.search +
        window.location.hash

    window.history.pushState({ path: newUrl }, "", newUrl)
    listeners.forEach((x) => x())
}

export function getQuery() {
    if (IsNode()) {
        return {}
    }
    return qs.parse(window.location.search)
}

function setQueryStringValue(key: string, value?: string, queryString = window.location.search) {
    const values = qs.parse(queryString)
    if (values[key] !== value) {
        const newQsValue = qs.stringify({ ...values, [key]: value })
        pushQueryString(`?${newQsValue}`)
    }
}

function getQueryStringValue(key: string, queryString?: string) {
    const values = qs.parse(queryString || IsNode() ? "0" : window.location.search)
    return values[key]
}

const listeners: (() => void)[] = []

export function useQueryString(
    key: string,
    initialValue?: string
): [string | undefined, (newValue: string | undefined) => void] {
    const [value, setValue] = useState((key && getQueryStringValue(key)) || initialValue)
    const onSetValue = useCallback(
        (newValue?: string, clearOthers = false) => {
            setValue(newValue)
            setQueryStringValue(key, newValue)

            listeners.forEach((x) => x())
        },
        [key]
    )

    useEffect(() => {
        function listen() {
            setValue(getQueryStringValue(key) || initialValue)
        }

        window.addEventListener("popstate", listen)
        listeners.push(listen)
        return () => {
            window.removeEventListener("popstate", listen)
            listeners.splice(listeners.indexOf(listen), 1)
        }
    }, [key])

    return [value instanceof Array ? "" + value : value, onSetValue]
}

export function useQueryNumber(
    key: string,
    initialValue?: number
): [number | undefined, (newValue: number | undefined) => void] {
    const [valueStr, setValueStr] = useQueryString(
        key,
        initialValue ? "" + initialValue : undefined
    )

    function setValue(newValue?: number) {
        setValueStr(newValue === undefined ? undefined : "" + newValue)
    }
    const value = valueStr ? parseInt(valueStr) : initialValue
    return [value === undefined || isNaN(value) ? initialValue : value, setValue]
}
