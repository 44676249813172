import React from "react"
import ReactMarkdown from "react-markdown"
import type { Markup } from "./Markup"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<Markup>("Markup", ({ value }) => <MarkupWidget {...value} />)

export function MarkupWidget({ markdown, style, flex }: Markup) {
    return (
        <div
            style={{
                marginLeft: 16,
                marginRight: 16,
                maxWidth: 600,
                color: ColorStyles.gray[600],
                flex: flex ?? undefined,
                ...style,
            }}
        >
            {markdown && <ReactMarkdown>{markdown.valueOf()}</ReactMarkdown>}
        </div>
    )
}
