import { useEffect, useState } from "react"

export function useDelay<T>(
    value: T,
    milliseconds: number,
    shouldDelay?: (newValue: T) => boolean
) {
    const [delayedValue, setDelayedValue] = useState(value)

    useEffect(() => {
        if (delayedValue !== value) {
            if (!shouldDelay || shouldDelay(value)) {
                setTimeout(() => setDelayedValue(value), milliseconds)
            } else {
                setDelayedValue(value)
            }
        }
    }, [value])

    return !shouldDelay || shouldDelay(value) ? delayedValue : value
}
