import React, { useContext, useEffect, useState } from "react"
import {
    useDocumentCollection,
    useDocumentOptions,
    useDocumentPrimaryKey,
    useDocumentRefresh,
} from "./DocumentContext"
import {
    IsOpaqueStringType,
    ValidateType,
    IsNumberType,
    GetTypeAlias,
    IsDateStringType,
    Property,
    MatchesType,
} from "../../reactor/Types/Type"
import { DiagnosticView } from "./DiagnosticView"
import { PropRow } from "./PropRow"
import { ToolButton } from "./ToolButton"
import { DateString, ParseDateTimePrecision, Uuid } from "../../reactor"
import { ErrorContext } from "./ErrorContext"
import { ReadonlyContext, useIsReadonlyField, useObjectPath } from "./ObjectContext"
import { StudioPropOptions } from "../API/DocumentPageAPI"
import { useBoundState } from "../../packages/editing/useBoundState"
import { getFileInfo } from "../client"
import { MarkdownEditor } from "../../packages/markdown-edit/MarkdownEdit"
import { UploadFile } from "../../packages/files/UploadFile"
import { ColorStyles } from "../../packages/ui"
import { useEditableContext } from "../../packages/editing/EditableContext"
import { ClientSideLocalize } from "../../packages/localization/client-side/Dictionary"

export function StringView({
    obj,
    property,
    label,
    buttons,
    isEmbedded,
}: {
    obj: any
    property: Property
    label?: string
    buttons: ToolButton[]
    isEmbedded?: boolean
}) {
    const type = property.type
    const isMarkdown = IsOpaqueStringType(type) && type.opaque === "Markdown"

    const isMultiline =
        isMarkdown || property.tags?.multiline || (typeof type === "object" && type.tags?.multiline)

    const [value, setValue, setValueLocally] = useBoundState(obj, property.name)
    const [markdownMode, setMarkdownMode] = useState<"preview" | "markdown">("preview")
    const [focused, setFocused] = useState(false)

    const path = useObjectPath()

    const docCollection = useDocumentCollection()
    const docPrimaryKey = useDocumentPrimaryKey()
    const docOptions = useDocumentOptions()
    const docRefresh = useDocumentRefresh()

    function findOptions() {
        let opts = docOptions
        for (const pathPart of path) {
            if (!opts) break
            opts = opts[pathPart] as StudioPropOptions | undefined
        }
        opts = opts && (opts[property.name] as StudioPropOptions | undefined)
        if (opts instanceof Array) return opts
    }
    const options: any[] | undefined = findOptions()

    const hasValidationError = MatchesType(value, property.type) !== true
    const [needsEditing] = useState(hasValidationError && !value)

    const rc = useContext(ReadonlyContext)

    const autoGenerated = property.tags?.serial && rc.mode === "create"

    const isReadonly = useIsReadonlyField(property, needsEditing) || autoGenerated

    const placeholder =
        property.placeholder ??
        (property.tags?.placeholder || undefined) ??
        (autoGenerated
            ? ClientSideLocalize({
                  en: "(auto generated)",
                  no: "(automatisk generert)",
                  sv: "(automatisk genererad)",
              })
            : undefined)

    const [textareaRef, setTextAreaRef] = useState<HTMLTextAreaElement | null>(null)

    const [textAreaHeight, setTextAreaHeight] = useState(100)

    useEffect(() => {
        if (textareaRef) {
            const height = Math.max(30, textareaRef.scrollHeight + 10)
            setTextAreaHeight(height)
        }
    }, [textareaRef])

    const ec = useEditableContext()

    const alias = GetTypeAlias(type)

    const isDate =
        alias === "DateString" ||
        alias === "Timestamp" ||
        alias === "PGTimestamp" ||
        alias === "LocalDateTime"
    const precisionStr = IsDateStringType(type) ? property.tags?.precision : undefined
    const prec = precisionStr ? ParseDateTimePrecision(precisionStr) : undefined

    const { error } = useContext(ErrorContext)

    const backgroundColor = error ? "#f004" : isReadonly ? ColorStyles.gray[50] : "white"

    function insertMedia({
        mediaId,
        name,
        mimeType,
    }: {
        mediaId: string
        name: string
        mimeType: string
    }) {
        const start = textareaRef?.selectionStart

        let media = `![${name}](/api/images/${mediaId}${
            name?.endsWith(".json") ? "_.json" : "_.png"
        })`
        if (name?.endsWith(".mp4")) {
            media = `![${name}](/api/files/${mediaId})`
        }
        const newValue = (obj[property.name] = `${value.slice(
            0,
            start
        )}\n\n${media}\n\n${value.slice(start)}`)
        setValue(newValue)
    }

    async function fileDrop(e: React.DragEvent): Promise<
        | {
              /** The file ID of the uploaded file */
              id: Uuid
              /** The name of the file */
              name: string
          }
        | undefined
    > {
        if (e.dataTransfer.files.length > 1) {
            alert("Only one file at a time please")
        }

        const folder = property.tags?.folder ?? "Media"

        const file = e.dataTransfer.files[0]
        const mimetype = file.type === "application/json" ? "application/lottie+json" : file.type
        const data = new FormData()
        data.append("file", file)

        const id = await UploadFile(folder, file, mimetype, false, "AnyoneWithLink", file.name)
        if (id)
            return {
                id,
                name: file.name,
            }
    }

    const markdownModeToggle = (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                fontSize: 10,
            }}
        >
            <div
                onClick={() => setMarkdownMode("preview")}
                style={{
                    border: "1px solid #eee",
                    backgroundColor: markdownMode === "preview" ? "#eee" : undefined,
                    borderTopLeftRadius: 2,
                    borderBottomLeftRadius: 2,
                    paddingLeft: 4,
                    paddingRight: 4,
                    cursor: "pointer",
                }}
            >
                Preview
            </div>
            <div
                onClick={() => setMarkdownMode("markdown")}
                style={{
                    marginRight: 8,
                    backgroundColor: markdownMode === "markdown" ? "#eee" : undefined,
                    border: "1px solid #eee",
                    borderTopRightRadius: 2,
                    borderBottomRightRadius: 2,
                    paddingLeft: 4,
                    paddingRight: 4,
                    cursor: "pointer",
                }}
            >
                Markdown
            </div>
        </div>
    )

    function renderContent() {
        if (isMultiline) {
            return (
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        {!isReadonly &&
                            (markdownMode === "preview" && isMarkdown ? (
                                <MarkdownEditor
                                    key="md-editor"
                                    style="studio"
                                    value={value}
                                    onDrop={fileDrop}
                                    onFocus={() => ec.setFocused(true)}
                                    onBlur={() => ec.setFocused(false)}
                                    onChanged={(text) => {
                                        if (text === value) return
                                        setValue(text)
                                    }}
                                    markdownSnippetId={
                                        // Make it hard to create a circular
                                        // reference by embedding the snippet in
                                        // a snippet
                                        docCollection === "MarkdownSnippets"
                                            ? (docPrimaryKey as any)
                                            : undefined
                                    }
                                />
                            ) : (
                                <textarea
                                    key="textarea"
                                    ref={setTextAreaRef}
                                    className="form-control"
                                    style={{
                                        width: "100%",
                                        height: textAreaHeight,
                                    }}
                                    placeholder={placeholder}
                                    value={value}
                                    onFocus={() => ec.setFocused(true)}
                                    onBlur={() => ec.setFocused(false)}
                                    onChange={(e) => {
                                        setValue(e.target.value)
                                    }}
                                    onDrop={async (e) => {
                                        const file = await fileDrop(e)

                                        if (file) {
                                            const fileInfo = await getFileInfo(file.id.valueOf())
                                            insertMedia({
                                                mediaId: file.toString(),
                                                name: file.name,
                                                mimeType: fileInfo.mimetype,
                                            })
                                        }
                                    }}
                                />
                            ))}
                    </div>
                </div>
            )
        }

        let valueString =
            value === undefined
                ? ""
                : value instanceof Date
                  ? value.toISOString().substring(0, value.toISOString().lastIndexOf("."))
                  : value

        if (alias === "Timestamp" && typeof value === "number") {
            try {
                valueString = DateString.toLocal(DateString(new Date(value)), "millisecond")
            } catch (e) {
                valueString = value.toString()
            }
        }

        let dateType = "datetime-local"

        if (IsDateStringType(type) || alias === "PGTimestamp") {
            let unit =
                typeof prec === "string"
                    ? prec
                    : typeof prec === "object"
                      ? prec.unit
                      : "millisecond"

            // TODO: translate units into HTML5 date input types + step

            // TODO: HTML's date input doesn't support picking years or months. For now mapping this
            // to Day to make it work. Should be replaced by special purpose pickers
            if (unit === "year") unit = "day"
            if (unit === "month") unit = "day"

            // enforce the type's declared precision
            if (valueString) valueString = DateString.toLocal(valueString, unit)
            if (unit === "day") {
                dateType = "date"
            }
        }

        if (options) {
            const valueIndex = options.indexOf(value)
            return (
                <select
                    className="form-select"
                    onChange={(x) => {
                        if (x.target.value === undefined) {
                            setValue(undefined)
                        } else {
                            setValue(options[parseInt(x.target.value)])
                        }
                        if (docRefresh) void docRefresh()
                    }}
                    value={valueIndex === -1 ? undefined : valueIndex}
                >
                    <option key="null" value={undefined}></option>
                    {options.map((x: any, i: number) => (
                        <option key={i} value={i}>
                            {x}
                        </option>
                    ))}
                </select>
            )
        }

        const textBox = (
            <input
                key="input"
                readOnly={isReadonly}
                className="form-control"
                style={{
                    backgroundColor,
                    width: "100%",
                    margin: isEmbedded ? 2 : undefined,
                }}
                type={isDate ? dateType : "text"}
                placeholder={placeholder}
                value={focused || autoGenerated ? undefined : valueString}
                onChange={(e) => {
                    if (isReadonly) return

                    let v: any = property.optional && !e.target.value ? undefined : e.target.value

                    if (IsDateStringType(type) || alias === "PGTimestamp") {
                        // Get it back in zulu format and declared precision before storing
                        try {
                            v = DateString(v)
                        } catch {
                            // If the date was unparsable, just leave it as is
                            return
                        }
                    }

                    setValueLocally(v)

                    if (IsNumberType(type)) {
                        // Don't try to update or parse numbers until we are done editing
                        return
                    }

                    if (v !== obj[property.name]) {
                        setValue(v)
                    }
                }}
                onClick={(e) => e.stopPropagation()}
                onFocus={() => {
                    ec.setFocused(true)
                    setFocused(true)
                }}
                onBlur={(e) => {
                    ec.setFocused(false)
                    e.stopPropagation()
                    if (isReadonly) return
                    setFocused(false)
                    let v = value
                    if (IsNumberType(type) && v !== undefined) {
                        v = parseFloat(v)
                        if (Number.isNaN(v)) {
                            if (property.optional) v = undefined
                            else v = 0
                        }
                        if (typeof type === "object") {
                            if (type.integer) v = Math.round(v)
                            if (type.minValue !== undefined) v = Math.max(type.minValue, v)
                            if (type.maxValue !== undefined) v = Math.min(type.maxValue, v)
                        }
                    }
                    if (IsDateStringType(type) || alias === "PGTimestamp") {
                        // Get it back in zulu format and declared precision before storing
                        try {
                            v = DateString(v)
                        } catch {
                            // If the date was unparsable, just leave it as is
                            return
                        }
                    }

                    const validated =
                        v === undefined && property.optional ? v : ValidateType(v, type)
                    setValueLocally(validated)
                    if (validated !== obj[property.name]) {
                        setValue(validated)
                        if (docRefresh) void docRefresh()
                    }
                }}
            />
        )

        if (property.tags?.slider) {
            let min = 0
            let max = 100
            let step = 1
            if (typeof type === "object" && IsNumberType(type)) {
                if (type.minValue) min = type.minValue
                if (type.maxValue) max = type.maxValue
                if (!type.integer) step = (max - min) / 100
            }

            return (
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <input
                        type="range"
                        min={min}
                        max={max}
                        value={value}
                        step={step}
                        onChange={(e) => {
                            const v = parseFloat(e.target.value)
                            setValue(v)
                        }}
                        style={{ flex: 2, marginRight: 8 }}
                    />
                    {textBox}
                </div>
            )
        }

        return textBox
    }

    return (
        <PropRow
            isReadonly={isReadonly}
            label={label}
            buttons={buttons}
            description={property.description}
            isEmbedded={isEmbedded}
            badge={
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {isMarkdown && markdownModeToggle}
                    <DiagnosticView
                        property={property}
                        value={
                            IsNumberType(type)
                                ? value !== undefined
                                    ? parseFloat(value)
                                    : undefined
                                : value
                        }
                    />
                </div>
            }
        >
            {renderContent()}
        </PropRow>
    )
}
