import { ChartConfiguration } from "chart.js"
import React, { useContext } from "react"
import { AccountContext } from "../AccountPage"
import { colors } from "../styles"
import { ChartBase, GetChartDataset } from "./ChartBase"
import { ChartCommon } from "./ChartCommon"
import { Section } from "../../../../packages/editing/Section"
import { DoughnutChartWiskerLabels } from "../../../../packages/charts/DoughnutChartWiskerLabels"

Section(DoughnutChart)
/**
 * @zoom 2.5
 * @icon chart-pie
 */
function DoughnutChart(section: ChartCommon) {
    const account = useContext(AccountContext)

    const { values, unit } = GetChartDataset(section, account)

    const config: ChartConfiguration<"doughnut"> = {
        type: "doughnut",
        data: {
            labels: values.map((v) => v.label),
            datasets: [
                {
                    data: values.map((v) => v.value),
                    backgroundColor: values.map((v) => v.customColor ?? colors[v.brandColor]),
                    hoverOffset: 4,
                    radius: 100,
                } as any,
            ],
        },
        options: {
            cutout: "70%",
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label(item) {
                            return `${item.label}: ${item.formattedValue} ${unit || ""}`
                        },
                    },
                },
            },
            aspectRatio: 1.5,
        },

        plugins: [DoughnutChartWiskerLabels(colors.foreground)],
    }

    return <ChartBase config={config} section={{ ...section, values }} />
}
