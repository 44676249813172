import React, { useEffect, useRef, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useHover } from "../hooks/useHover"
import type { Element, ElementProps } from "./Element"
import { DirectionContext } from "./LayoutWidget"
import { Widget } from "./Widget"
import { usePerformWidgetAction } from "./WidgetContext"
import { RegisterWidget, WidgetView } from "./WidgetView"

RegisterWidget<Element>("Element", ({ value }) => <ElementWidget {...value} />)

export function ElementWidget({
    hasAction,
    action,
    prompt,
    widgetKey,
    style,
    hoverStyle,
    content: originalContent,
    hasReorder,
    reorderStyle,
}: ElementProps) {
    const performAction = usePerformWidgetAction()
    const { hover, hoverProps } = useHover()

    const localContent = useRef<Widget>(null)
    const content = localContent.current ?? originalContent
    const [verison, setVersion] = useState({})

    let body =
        content instanceof Array ? (
            content.map((c) => <WidgetView value={c} />)
        ) : (
            <WidgetView value={content} />
        )

    const elms = body

    // Hack to get around margin calculation issues in react-beautiful-dnd
    useEffect(() => {
        if (!reorderStyle) return () => {}
        const styleSheet = document.createElement("style")
        styleSheet.innerText = `.reorderStyle-${widgetKey} { ${reorderStyle} }`
        document.head.appendChild(styleSheet)
        return () => document.head.removeChild(styleSheet)
    }, [reorderStyle])

    if (hasReorder && widgetKey && elms instanceof Array && content instanceof Array) {
        body = (
            <DragDropContext
                onDragEnd={async (result) => {
                    if (!result.destination) {
                        return
                    }
                    const src = result.source.index
                    const dst = result.destination.index

                    localContent.current = content.slice()
                    localContent.current.splice(dst, 0, localContent.current.splice(src, 1)[0])
                    setVersion({})

                    await performAction(widgetKey, "reorder", [src, dst])
                    localContent.current = null
                    setVersion({})
                }}
            >
                <Droppable droppableId="droppable">
                    {(dropProvided, dropSnap) => (
                        <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                            {elms.map((item, index) => {
                                const key =
                                    "widgetKey" in (content as any)[index]
                                        ? (content as any)[index].widgetKey
                                        : "draggable" + index

                                return (
                                    <Draggable key={key} draggableId={key} index={index}>
                                        {(dragProvided, dragSnap) => {
                                            return (
                                                <div
                                                    ref={dragProvided.innerRef}
                                                    className={
                                                        reorderStyle
                                                            ? `reorderStyle-${widgetKey}`
                                                            : undefined
                                                    }
                                                    {...dragProvided.draggableProps}
                                                    {...dragProvided.dragHandleProps}
                                                >
                                                    {item}
                                                </div>
                                            )
                                        }}
                                    </Draggable>
                                )
                            })}
                            {dropProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    return (
        <div
            key={widgetKey}
            style={hover ? { ...style, ...hoverStyle } : style}
            {...(hoverStyle ? hoverProps : undefined)}
            onClick={
                hasAction
                    ? async () => {
                          try {
                              if (prompt && !confirm(prompt)) {
                                  return
                              }
                              if (action instanceof Function) {
                                  action()
                              } else if (widgetKey) {
                                  await performAction(widgetKey)
                              } else {
                                  console.log("No action specified")
                              }
                          } catch (e: any) {
                              alert("detail" in e ? e.detail : JSON.stringify(e))
                          }
                      }
                    : undefined
            }
        >
            <DirectionContext.Provider value="column">{body}</DirectionContext.Provider>
        </div>
    )
}
