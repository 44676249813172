import React, { useContext } from "react"
import { WidgetAPIView } from "../../../../packages/widgets/WidgetAPIView"
import { AccountContext } from "../AccountPage"
import { Title } from "../styles"
import { Section } from "../../../../packages/editing/Section"

Section(EmissionsForecastingSection)
function EmissionsForecastingSection() {
    const solution = useContext(AccountContext)
    if (!solution) return <></>

    return (
        <div className="container">
            <Title title="Emissions forecast" />
            <WidgetAPIView name="EmissionsForecastChart" param={solution.id.valueOf()} />
        </div>
    )
}
