import React, { CSSProperties } from "react"
import { useEffect, useState } from "react"

export function Slide({
    children,
    style,
    delay,
    direction,
}: {
    children: React.ReactNode
    style?: CSSProperties
    delay?: number
    direction?: "fromLeft" | "fromRight" | "fromTop" | "fromBottom"
}) {
    const [appear, setAppear] = useState(false)

    useEffect(() => {
        setTimeout(() => setAppear(true), delay === undefined ? 100 : delay)
    }, [])

    return (
        <div
            style={{
                ...style,
                transition: `all 0.35s ease`,
                transform: appear
                    ? undefined
                    : direction === "fromLeft"
                      ? "translateX(-50px)"
                      : direction === "fromRight"
                        ? "translateX(50px)"
                        : direction === "fromTop"
                          ? "translateY(-50px)"
                          : direction === "fromBottom"
                            ? "translateY(50px)"
                            : "translateY(50px)",
                opacity: appear ? 1 : 0,
            }}
        >
            {children}
        </div>
    )
}
