import { useEffect, useState } from "react"
import { defaults } from "../../studio/client"

/**
 * Returns the base64 encoded image data for the given image URL, with the
 * authorization header set.
 *
 * This is useful for displaying images in the studio that require
 * authorization.
 *
 * If the image URL is undefined, it returns undefined and does not attempt to
 * fetch the image.
 */
export function useImageWithAuth(imageUrl?: string) {
    const [image, setImage] = useState<string | undefined>()
    useEffect(() => {
        async function fetchBlob() {
            try {
                if (imageUrl) {
                    const response = await fetch(imageUrl, { headers: defaults.headers })
                    const blob = await response.blob()
                    const base64 = (await blobToBase64(blob)) as string
                    setImage(base64)
                }
            } catch (e) {}
        }
        void fetchBlob()
    }, [imageUrl])

    return image
}

// Source: https://stackoverflow.com/a/18650249
function blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}
