import { createContext, useContext } from "react"

export const SettingsContext = createContext<{ namespace: string } | null>({
    namespace: "settings",
})

export function useSettingsNamespace() {
    const ctx = useContext(SettingsContext)
    if (!ctx) throw new Error("No active settings namespace")
    return ctx.namespace
}
