import { useState, MouseEvent } from "react"
import { RegisterWidget } from "./WidgetView"
import ReactMarkdown from "react-markdown"
import { IsStringLiteral, Markdown } from "../../reactor"
import { ColorStyles } from "../ui"
import { useHover } from "../hooks/useHover"
import { ReadMore } from "./ReadMore"

RegisterWidget<ReadMore>("ReadMore", ({ value }) => <ReadModeWidget {...value} />)

const defaultLimit = 50

function ReadModeWidget(props: ReadMore) {
    if (!("mode" in props))
        return <ExpandableTextCharLimit text={props.text.trim()} limit={props.limit} />

    switch (props.mode) {
        case "CharLimit":
            return <ExpandableTextCharLimit text={props.text.trim()} limit={props.limit} />
        case "ShortLongText":
            return (
                <ExpandableTextBothProvided
                    shortText={props.shortText.trim()}
                    longText={props.longText.trim()}
                />
            )
        default:
            return null
    }
}

function ExpandableTextCharLimit({ text, limit }: { text: string; limit?: number }) {
    // Return null if there is nothing
    if (!text) return null

    const shortVersion = hasNewLines(text, limit ?? defaultLimit)
        ? splitByFirstNewline(text)
        : getShortVersion(text, limit ?? defaultLimit)

    return <ExpandableText shortText={shortVersion} allText={text} />
}

function ExpandableTextBothProvided({
    shortText,
    longText,
}: {
    shortText: string
    longText: string
}) {
    // Return null if there is nothing
    if (!shortText || !longText) return null

    return <ExpandableText shortText={shortText} allText={`${shortText} ${longText}`} />
}

function ExpandableText({ shortText, allText }: { shortText: string; allText: string | Markdown }) {
    const [isShowingMore, setIsShowingMore] = useState(false)
    const { hover, hoverProps } = useHover() // Ensure useHover is imported and used correctly
    const shouldUseReadMore = shortText !== allText

    const toggleReadMore = (event: MouseEvent) => {
        if (!shouldUseReadMore) return

        event.stopPropagation()
        setIsShowingMore((prev) => !prev)
    }

    const toggleStyle: React.CSSProperties = {
        opacity: 1,
        borderRadius: "4px",
        borderStyle: "solid",
        borderWidth: "0px",
        userSelect: "none",
        padding: "0px 4px 1px",
        fontSize: "12px",
        color: ColorStyles.primary[500],
        cursor: "pointer",
        height: "20px",
        marginLeft: "4px",
        backgroundColor: hover ? ColorStyles.gray[100] : "transparent",
    }
    return (
        <div onClick={toggleReadMore}>
            {shouldUseReadMore && !isShowingMore && (
                <>
                    <span>{getText(shortText)}</span>
                    <span style={toggleStyle} {...hoverProps}>
                        More
                    </span>
                </>
            )}
            {(isShowingMore || !shouldUseReadMore) && (
                <>
                    <ReactMarkdown>{getText(allText)}</ReactMarkdown>
                    {shouldUseReadMore && (
                        <span style={toggleStyle} {...hoverProps}>
                            Less
                        </span>
                    )}
                </>
            )}
        </div>
    )
}

function getText(text: string | Markdown) {
    return typeof text === "string" ? text : text.valueOf().trim()
}

function hasNewLines(input: string | Markdown, limit: number) {
    return /[\r\n]/.test(getText(input.substring(0, limit)))
}

function splitByFirstNewline(input: string) {
    const newlinePattern = /\r\n|\r|\n/
    const text = removeMarkdown(getText(input))
    const splitResult = text.split(newlinePattern, 2)

    if (splitResult.length >= 1) return splitResult[0]

    return input
}

function removeMarkdown(text: string): string {
    return text
        .replace(/^\s*#+\s*/g, "") // Remove the leading Heading characters
        .replace(/^\s*-\s*/g, "") // Remove the leading List characters
        .replace(/__|\*/g, "") // Remove the bold
}

function getShortVersion(input: string, numChars: number) {
    // The image in markdown starts with the exclamation mark so there is no need for the read more functionality
    if (input[0] === "!") return input

    // Remove the markdown as it will not be shown in the short version
    const text = removeMarkdown(getText(input))

    // Separators to decide where the shortVersion should end
    const separators = [" ", ",", ".", ";", "\n"]

    // No need for the read more functionality if the string is too short
    if (text.length <= numChars) return text

    // End the short version at the last separator before the number of chars
    const endIndex = [...text.slice(0, numChars)]
        .reverse()
        .findIndex((char) => separators.includes(char))

    const splitIndex = endIndex === -1 ? numChars : numChars - endIndex - 1

    return text.substring(0, splitIndex)
}
