import { TypeValidator } from "../../reactor/Types/Primitives/TypeValidator"
import { GetTypeAlias, Type, VisitType } from "../../reactor/Types/Type"

/** Represents a value that has different values in different in different locales.
 *
 * For strings, the object might also include additional grammatical metadata, such as genus.
 * Grammatical metadata is encoded as `localekey:metakey` where e.g. `metakey` can be `genus` or
 * `case`.
 *
 * If genus is unspecified, the consumer will typically assume the most common genus for the locale.
 * For example, in Norwegian, the most common genus is `neuter`.
 *
 * ```ts
 * {
 *   "en": "Place",
 *   "no": "Sted",
 *   "no:genus": "neuter",
 * }
 * ```
 *
 * @shared
 *
 */
export type Localized<T> = { [locale: string]: T }

TypeValidator(Localized)
export function Localized(loc: Localized<any>) {
    if (typeof loc !== "object" || Object.keys(loc).length < 1) {
        throw new Error("Localized value must be defined for at least one locale")
    }
    return loc
}
/** Returns truthy if the provided type is a localized type, falsy otherwise.
 *
 *  Returns the type of the localized value if it is a localized type.
 */
export function IsLocalized(type: Type): Type | undefined {
    if (typeof type !== "object") return
    const alias = GetTypeAlias(type)
    if (alias !== "Localized") return
    const arg = type.typeArgs && type.typeArgs[0]
    if (arg === undefined) return
    return arg
}

export function LocalizedView(loc: Localized<any>) {
    return loc.en
}

const containsLocalizedCache = new Map<Type, boolean>()

export function ContainsLocalized(type: Type): boolean {
    const cached = containsLocalizedCache.get(type)
    if (cached !== undefined) return cached

    let res = false
    VisitType(type, (x, path) => {
        if (GetTypeAlias(x) === "Localized") {
            res = true
        }
    })
    containsLocalizedCache.set(type, res)
    return res
}

/**
 * Always get _some_ value from a Localized<T> prioritizing `desiredLocale`,
 * then English, then Norwegian, then the first value, then the string
 * "Missing localized text" cast to T.
 * @param localized The Localized<T> object
 * @param desiredLocale The locale to prioritize, defaults to "en"
 * @returns
 */
export function GetSomeLocalizedValue<T>(
    localized: Localized<T> | undefined,
    desiredLocale: string = "en"
): T {
    if (localized === undefined) return "Missing localized text" as T

    return (
        localized[desiredLocale] ??
        localized.en ??
        localized.no ??
        Object.values(localized)[0] ??
        ("Missing localized text" as T)
    )
}

/**
 * Tries to get a value from a Localized<T> prioritizing `desiredLocale`,
 * then English, then Norwegian, then the first value.
 * @param localized The Localized<T> object
 * @param desiredLocale The locale to prioritize, defaults to "en"
 * @returns
 */
export function GetLocalizedValue<T>(
    localized: Localized<T> | undefined,
    desiredLocale: string = "en"
): T | undefined {
    if (localized === undefined) return undefined

    return (
        localized[desiredLocale] ??
        localized.en ??
        localized.no ??
        Object.values(localized)[0] ??
        undefined
    )
}
